import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { controlliAssociazioneImpianto } from "../../functions/gestioneImpiantiFunctions";
import { verificaMeseAnno } from "../../functions/gestioneParametriFunctions";
import moment from "moment";
import { MESI } from "../../common/constants";
import Toast, { TOAST_DANGER, TOAST_SUCCESS } from "../comuni/Toast";

export default function ModaleAssociaImpianto(props) {
	const [associaImpianto, setAssociaImpianto] = useState({
		id: null,
		ragioneSociale: null,
		tipoImpianto: null,
		dataInizio: null,
		dataFine: null,
		gestoreContratto: null,
	});
	const [messageToast, setMessageToast] = useState({
		text: "",
		isVisible: false,
		type: "",
	});

	useEffect(() => {
		setAssociaImpianto({
			id: null,
			ragioneSociale: null,
			tipoImpianto: null,
			dataInizio: null,
			dataFine: null,
			gestoreContratto: null,
		});
	}, [props.isOpen]);

	const inserisci = () => {
		let data = { ...associaImpianto };
		let controllo = controlliAssociazioneImpianto(data);
		if (!controllo.verificato) {
			setMessageToast({
				text: controllo.descrizioneErrore,
				isVisible: true,
				type: TOAST_DANGER,
			});
			return;
		}
		let secondoControllo = verificaMeseAnno(data);
		if (!secondoControllo.verificato) {
			setMessageToast({
				text: secondoControllo.descrizioneErrore,
				isVisible: true,
				type: TOAST_DANGER,
			});
			return;
		}
		if (controllo.verificato && secondoControllo.verificato) {
			data.dataInizio = moment()
				.year(data.annoInizio)
				.month(data.meseInizio - 1)
				.format("YYYY-MM-01");
			data.dataFine =
				moment()
					.year(data.annoFine)
					.month(data.meseFine - 1)
					.format("YYYY-MM-") +
				moment()
					.month(data.meseFine - 1)
					.daysInMonth();
			let { meseInizio, meseFine, annoInizio, annoFine, ...dto } = data;
			props.conferma(dto);
		}
	};

	const aggiorna = (chiave, valore) => {
		let impianto = { ...associaImpianto };
		impianto[chiave] = valore;
		if (chiave == "id") {
			let tipoImp = props.listaTotaleImpianti.find((x) => x.id == valore);
			impianto.tipoImpianto = tipoImp.tipoImpianto;
			impianto.ragioneSociale = tipoImp.ragioneSociale;
		}
		setAssociaImpianto(impianto);
	};

	const aggiornaDate = (chiave, valore) => {
		let impianto = { ...associaImpianto };
		if (chiave == "meseInizio") {
			impianto[chiave] = valore;
		}
		if (chiave == "meseFine") {
			impianto[chiave] = valore;
		}
		if (chiave == "annoInizio") {
			impianto[chiave] = valore;
		}
		if (chiave == "annoFine") {
			impianto[chiave] = valore;
		}
		setAssociaImpianto(impianto);
	};

	const listaMesi = (chiave) => {
		return (
			<select
				className="form-control"
				onChange={(e) => {
					aggiornaDate(chiave, e.target.value);
				}}
				required>
				<option key={"mesi-empty"} value={""}>
					-- Seleziona mese --
				</option>
				{MESI.map((m) => {
					return (
						<option key={m.valore} value={m.valore}>
							{m.testo}
						</option>
					);
				})}
			</select>
		);
	};

	const listaAnni = (chiave) => {
		let annoCorrente = new Date().getFullYear();
		let anni = [];
		for (let k = -3; k < 2; k++) {
			anni.push(annoCorrente + k);
			anni.sort((a, b) => b - a);
		}
		return (
			<select
				className="form-control"
				onChange={(e) => {
					aggiornaDate(chiave, e.target.value);
				}}
				required>
				<option key={"anni-empty"} value={""}>
					-- Seleziona anno --
				</option>
				{anni.map((a) => {
					return (
						<option key={a} value={a}>
							{a}
						</option>
					);
				})}
			</select>
		);
	};

	const renderDate = (mese, anno) => {
		return (
			<>
				<div className="form-row">
					<div className="col-md-6">
						<div className="selection-panel" style={{ display: "block" }}>
							Mese:<div>{listaMesi(mese)}</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="selection-panel" style={{ display: "block" }}>
							Anno:<div>{listaAnni(anno)}</div>
						</div>
					</div>
				</div>
				<br />
			</>
		);
	};

	const renderListaImpianti = () => {
		if (props.listaTotaleImpianti != null && props.listaTotaleImpianti.length > 0) {
			{
				return (
					<select
						className="form-control"
						value={associaImpianto.id}
						onChange={(e) => {
							aggiorna("id", e.target.value);
						}}
						required>
						<option key={""} value={""}>
							-- Seleziona Impianto --
						</option>
						{props.listaTotaleImpianti.map((imp, _idx) => {
							return (
								<option key={_idx} value={imp.id}>
									{imp.ragioneSociale}
								</option>
							);
						})}
					</select>
				);
			}
		}
	};

	return (
		<>
			<Modal
				isOpen={props.isOpen}
				size="lg"
				role="dialog"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				backdrop="static"
				keyboard={true}>
				<ModalHeader>
					<h4>Associazione nuovo impianto</h4>
				</ModalHeader>
				<ModalBody>
					<div className="cor-card">
						<div style={{ padding: "15px" }}>
							<form>
								<div className="form-row">
									<div className="form-group col-md-6">
										<label for="inputragionesociale">Ragione Sociale</label>
										{renderListaImpianti()}
									</div>
									<div className="form-group col-md-6">
										<label for="inputTipologiaImpianto">Tipologia impianto</label>
										<input
											type="text"
											className="form-control"
											id="inputTipologiaImpianto"
											value={associaImpianto.tipoImpianto}
											readOnly
										/>
									</div>
								</div>
								<div className="form-row">
									<div className="col-md-6">
										<strong>
											<label>Data inizio</label>
										</strong>
										{renderDate("meseInizio", "annoInizio")}
									</div>
									<div className="col-md-6">
										<strong>
											<label>Data fine</label>
										</strong>
										{renderDate("meseFine", "annoFine")}
									</div>
								</div>
								<div className="form-row">
									<div className="form-group col-md-12">
										<label for="inputGestoreContratto">Gestore contratto</label>
										<select
											className="form-control"
											value={associaImpianto.gestoreContratto}
											onChange={(e) => {
												aggiorna("gestoreContratto", e.target.value);
											}}
											required>
											<option key={""} value={""}>
												-- Seleziona gestore contratto --
											</option>
											<option key={"1"} value={"1"}>
												CDR
											</option>
											<option key={"2"} value={"2"}>
												Impianto
											</option>
										</select>
									</div>
								</div>
							</form>
							<div className="cor-card-footer">
								<div className="btn-panel">
									<button
										className="btn btn-primary"
										onClick={() => {
											inserisci();
										}}>
										Associa
									</button>
									<button
										className="btn btn-secondary"
										onClick={() => {
											props.onExit();
										}}>
										Annulla
									</button>
								</div>
							</div>
						</div>
						<div className="riga-interruzione" />
					</div>
				</ModalBody>
				<Toast
					isVisible={messageToast.isVisible}
					type={messageToast.type}
					text={messageToast.text}
					onClose={() => {
						setMessageToast({
							text: "",
							isVisible: false,
							type: "",
						});
					}}
				/>
			</Modal>
		</>
	);
}
