import React, {useState, useEffect} from 'react'
import { useGlobalStateContext } from "../context/GlobalContext";
import moment from "moment";
import { caricaListaCdrAssociati, caricaSingoloCdr, salvaCdr, eliminaSingoloCdr, userCdrInit } from "../actions/portalApi";
import { caricaListaCdr } from "../actions/portalApi";
import ModaleAssociaCdr from "../components/gestioneCentroDiRecupero/ModaleAssociaCdr";
import ModaleModificaAssociaCdr from "../components/gestioneCentroDiRecupero/ModaleModificaAssociaCdr";
import ModaleConfermaEliminazione from "../components/comuni/ModaleConfermaEliminazione";
import Toast, {TOAST_DANGER, TOAST_SUCCESS} from "../components/comuni/Toast";

export default function GestioneCentroDiRecupero() {
  const user = useGlobalStateContext();
  const [listaTotaleCdr, setListaTotaleCdr] = useState(null);
  const [cloneListaTotaleCdr, setCloneListaTotaleCdr] = useState(null);
  const [listaCdrPerCdr, setListaCdrPerCdr] = useState(null);
  const [mostra, setMostra] = useState(false);
  const [mostraModaleAssociaCdr, setMostraModaleAssociaCdr] = useState(false);
  const [mostraModaleModificaAssociaCdr, setMostraModaleModificaAssociaCdr] = useState(false);
  const [mostraModaleConfermaEliminazione, setMostraModaleConfermaEliminazione] = useState(false);
  const [cdr_Id_Nome, setCdr_Id_Nome] = useState({idCdr:null, nomeCdr:""});
  const [indice, setIndice] = useState(null);
  const [centroDiRecupero, setCentroDiRecupero] = useState(null);
  const [messageToast, setMessageToast ] = useState({
    text: "",
    isVisible: false,
    type: ""
  });

  useEffect(() => {
      window.onbeforeunload = null;
      window.history.replaceState({}, '', '/#/gestione-cdr');
      ruoloUtenteRec();
    }, []);

  const ruoloUtenteRec = () => {
    user.user.ruoli.map((e)=> {
      if (e.codiceRuolo != 'REC_CDR_SUPERVISOR' && e.codiceRuolo != 'REC_CDR_USER') {
        userCdrInit().then((res)=> {
          if (res != null){
            caricaListaCdr().then(async(res)=> {
              if (res != null){
                let lista = await res.data.data;
                setListaTotaleCdr(lista);
              }
            })
          }
        })
      }
    })
  }

  const aggiornaCdrId = (chiave, valore) => {
    let cdr = {...cdr_Id_Nome};
    cdr.idCdr = valore;
    if (chiave == "idCdr") {
      let nomeCdr = listaTotaleCdr.find((x) => x.organizationId == valore);
      cdr.nomeCdr = nomeCdr.nome;
    }
    setCdr_Id_Nome(cdr);
  }

  const caricaCdrAssociati = () => {
    let cdr = {...cdr_Id_Nome};
    let listaCdr = [...listaTotaleCdr];
    let indiceCdrRidondante = null;
    if (cdr.idCdr != null) {
      caricaListaCdrAssociati(cdr.idCdr).then((res) => {
      if (res != null) {
        setMostra(true);
        setListaCdrPerCdr(res.data.data);
        indiceCdrRidondante = listaTotaleCdr.findIndex((x) => x.organizationId == cdr.idCdr);
        if (indiceCdrRidondante != null) {
          listaCdr.splice(indiceCdrRidondante, 1);
          setCloneListaTotaleCdr(listaCdr);
        }
      }
    });
    } else {
        setMessageToast({
        text: "Selezionare il Cdr per la ricerca",
        isVisible: true,
        type: TOAST_DANGER
      })
      return;
    }
  }

  const listaCentriDiRecupero = () => {
    if (listaTotaleCdr != null && listaTotaleCdr.length > 0){
      return (
        <select className="form-control" value={cdr_Id_Nome.idCdr} onChange={ (e) => {
        aggiornaCdrId("idCdr",e.target.value);} } required>
          <option key={ "" } value={ "" }>-- Seleziona CDR --</option>
          {listaTotaleCdr.map((cdr) => {
            return <option key={ cdr.organizationId } value={cdr.organizationId}>{ cdr.nome }</option>
          })}
        </select>
      )
    } else {
      return null
    }
  }

  const renderListaCdrFiltrata = () => {
    if (listaCdrPerCdr != null && listaCdrPerCdr.length > 0) {
      return listaCdrPerCdr.map((cdr, _idx) => {
        return (
        <tr key={_idx}>
          <td>{cdr.nomeCdr}</td>
          <td>{moment(cdr.dataInizioValidita).format("DD-MM-YYYY")}</td>
          <td>{moment(cdr.dataFineValidita).format("DD-MM-YYYY")}</td>
          <td><button className="btn btn-primary" onClick={() => {modificaAssociazioneCdr(cdr.id);}}>Modifica</button></td>
          <td><button className="btn btn-primary" onClick={() => {apriEliminaAssociazioneCdr(cdr.id)}}>Elimina</button></td>
        </tr>
        )
      })
    } else {
      return (
        <tr>
          <td colSpan={5}>Non sono presenti impianti per il CDR selezionato</td>
        </tr>
      )
    }
  }

  const associaCdr = (datiCdr) => {
    let cdr = {...cdr_Id_Nome};
    let dto = {
      id: null,
      idCdr: cdr.idCdr,
      nomeCdr: cdr.nomeCdr,
      idCdrDest: datiCdr.idCdr,
      nomeCdrDest: datiCdr.nomeCdr,
      dataInizioValidita: datiCdr.dataInizio,
      dataFineValidita: datiCdr.dataFine,
    }
    salvaCdr(dto).then((res)=> {
      if (res) {
        setMessageToast({
          text: "Cdr associato con successo",
          isVisible: true,
          type: TOAST_SUCCESS
        })
        caricaListaCdrAssociati(cdr.idCdr).then(async(res) => {
          if (res != null) {
            let risposta = await res;
            setListaCdrPerCdr(risposta.data.data);
          }
        })        
      }
    }).catch((err) => {
      if (err){
        setMessageToast({
          text: "Errore nel salvataggio del Cdr",
          isVisible: true,
          type: TOAST_DANGER
        })}
      })     
  }

  const confermaModificaAssociaCdr = (datiCdr) => {
    let cdr = {...cdr_Id_Nome};
    let dto = {
      id: datiCdr.id,
      idCdr: cdr.idCdr,
      nomeCdr: datiCdr.nomeCdr,
      idCdrDest: datiCdr.idCdrDest,
      nomeCdrDest: null,
      dataInizioValidita: datiCdr.dataInizioValidita,
      dataFineValidita: datiCdr.dataFineValidita,
    }
    salvaCdr(dto).then((res)=> {
      if (res.status == 200) {
        setMessageToast({
          text: "Cdr associato con successo",
          isVisible: true,
          type: TOAST_SUCCESS
        })
        caricaListaCdrAssociati(cdr.idCdr).then(async(res) => {
          if (res != null) {
            let risposta = await res;
            setListaCdrPerCdr(risposta.data.data);
          }
        })        
        return;
      } else {
        setMessageToast({
          text: "Errore nel salvataggio del Cdr",
          isVisible: true,
          type: TOAST_DANGER
        })
        return;
      }
    })
  }
  
  const modificaAssociazioneCdr = (idAssociazioneCdr) => {
    caricaSingoloCdr(idAssociazioneCdr).then((res) => {
      if (res != null) {
        setCentroDiRecupero(res.data.data);
        setMostraModaleModificaAssociaCdr(true);
      }
      if (res == null) {
        setMessageToast({
          text: "Errore nel caricamento del Cdr",
          isVisible: true,
          type: TOAST_DANGER
        })
      }
    }).catch((err) => {
      if (err) {
        setMessageToast({
          text: "Errore nel caricamento del Cdr",
          isVisible: true,
          type: TOAST_DANGER
        })
      }
    })
  }

  const apriEliminaAssociazioneCdr = (idAssociazioneCdr) =>{
    setIndice(idAssociazioneCdr);
    setMostraModaleConfermaEliminazione(true);
  }

  const eliminaAssocImpianto = () => {
    let id = indice;
    eliminaSingoloCdr(id).then((res) => {
      if (res) {
        setMessageToast({
          text: "Cdr eliminato con successo",
          isVisible: true,
          type: TOAST_SUCCESS
        })
        caricaListaCdrAssociati(cdr_Id_Nome.idCdr).then(async(lista) => {
          setMostraModaleConfermaEliminazione(false);
          if (lista != null) {
            let risposta = await lista.data.data;
            setListaCdrPerCdr(risposta);
          }
        })
      }
    })
  }

  return (
  <>
    <div key="associazioneCdr" className="container-fluid">
    <div className="cor-card">
      <div className="cor-card-header">
        <h4>Associazione CDR</h4>
      </div>
      <div className="cor-card-body">
        <div className="fixed-header">
          <div className="row form-group gestione-cdr-filter">
            <div className="col-md-4">
              <div className="selection-panel" style={{ display: "block"}}>
                <strong>CDR</strong>: <div>{ listaCentriDiRecupero() }</div>
              </div>
            </div>
            <div className="col-md-4" style={{ "textAlign": "right"}}>
              <div className="btn-panel">
                <button className="btn btn-primary" onClick={() => {caricaCdrAssociati()}}> Carica </button>
                {mostra ? <button className="btn btn-primary" onClick={() => {setMostraModaleAssociaCdr(true)}}> Associa nuovo cdr </button> : null}
              </div>
            </div>
          </div>
        </div>
        {mostra ? 
          <div className="tabella-impianti-wrapper">
            <table className="tabella-impianti">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Data Inizio</th>
                  <th>Data Fine</th>
                  <th colSpan={2}>Azioni</th>
                </tr>
              </thead>
              <tbody>{renderListaCdrFiltrata()}</tbody>
              </table>
          </div>
        :
        null}
        </div>
      </div>
      <ModaleAssociaCdr
        isOpen={mostraModaleAssociaCdr}
        listaTotaleCdr={cloneListaTotaleCdr}
        conferma={async(dati) => {
          associaCdr(dati);
          setMostraModaleAssociaCdr(false)}}
        onExit={() => {
          setMostraModaleAssociaCdr(false);
      }}/>
      <ModaleModificaAssociaCdr
        isOpen={mostraModaleModificaAssociaCdr}
        listaTotaleCdr={cloneListaTotaleCdr}
        oggettoCdr={centroDiRecupero}
        conferma={async(dati) => {
          confermaModificaAssociaCdr(dati);
          setMostraModaleModificaAssociaCdr(false)}}
        onExit={() => {
        setMostraModaleModificaAssociaCdr(false);}} 
      />
      <ModaleConfermaEliminazione 
        isOpen={mostraModaleConfermaEliminazione}
        elimina={()=>{eliminaAssocImpianto()}}
        onExit={() => {
          setIndice(null);
          setMostraModaleConfermaEliminazione(false);
        }}
      />
      <Toast isVisible={ messageToast.isVisible } type={messageToast.type} text={ messageToast.text } onClose={ () => {
        setMessageToast({
          text: "",
          isVisible: false,
          type: ""
        });
        } }
      />
    </div>
  </>
  )
}

