import { useState } from "react";
import { Modal, ModalFooter, ModalHeader, ModalBody, Button } from "reactstrap";
import Toast from "../comuni/Toast";

export default function ModaleApprovaCdr(props) {
	const [messageToast, setMessageToast] = useState({
		text: "",
		isVisible: false,
		type: "",
	});

	return (
		<>
			<Modal
				isOpen={props.isOpen}
				size="lg"
				role="dialog"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<ModalHeader>
					<h4>Approvazione Finale Dichiarazione</h4>
				</ModalHeader>
				<ModalBody>
					<h6>E' possibile aggiungere una nota opzionale</h6>
					<textarea
						className="form-control"
						value={props.testo}
						onChange={(e) => {
							props.scritturaNota(e.target.value);
						}}></textarea>
				</ModalBody>
				<ModalFooter>
					<button
						className="btn btn-primary"
						onClick={() => {
							props.onApprova();
						}}>
						{" "}
						Approva
					</button>
					<Button onClick={props.onExit}>Annulla</Button>
				</ModalFooter>
			</Modal>
			<Toast
				isVisible={messageToast.isVisible}
				type={messageToast.type}
				text={messageToast.text}
				onClose={() => {
					setMessageToast({
						text: "",
						isVisible: false,
						type: "",
					});
				}}></Toast>
		</>
	);
}
