export const controlliInserimentoImpianto = (oggettoX) => {
	let risultato = {
		verificato: true,
		descrizioneErrore: "",
	};
	if (
		oggettoX.ragioneSociale == "" ||
		oggettoX.ragioneSociale == null ||
		oggettoX.tipoImpianto == "" ||
		oggettoX.tipoImpianto == null
	) {
		risultato.verificato = false;
		risultato.descrizioneErrore = "E' necessario compilare tutti i campi";
	}

	return risultato;
};

export const controlliAssociazioneImpianto = (impianto) => {
	let risultato = {
		verificato: true,
		descrizioneErrore: "",
	};

	if (
		impianto.id == "" ||
		impianto.id == null ||
		impianto.ragioneSociale == "" ||
		impianto.ragioneSociale == null
	) {
		risultato.verificato = false;
		risultato.descrizioneErrore = "Per favore compilare tutti i campi";
		return risultato;
	}
	if (
		impianto.gestoreContratto === undefined ||
		impianto.gestoreContratto == "" ||
		impianto.gestoreContratto == 0 ||
		impianto.gestoreContratto > 2 ||
		impianto.gestoreContratto == null ||
		impianto.gestoreContratto.length > 1
	) {
		risultato.verificato = false;
		risultato.descrizioneErrore = "In gestore contratto selezionare 1 per CDR / 2 per Impianto";
	}
	return risultato;
};
