import React, { useState, useEffect } from 'react';
import { InfoCircle, JournalText, PlusCircle, Trash, Printer, ArrowLeft } from 'react-bootstrap-icons';
import {UncontrolledTooltip} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from 'moment';
import 'moment/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import { RECUPERO_API } from "../common/env";
import { getAccessTokenCookie } from "../common/cookie";
import { getStatusDesc, getStatusValidityDesc } from '../common/util';
import ModaleAllegato from '../components/dichiarazione/ModaleAllegato';
import ModaleModificaInCorso from '../components/dichiarazione/ModaleModificaInCorso';
import ModaleValidaRec from "../components/dichiarazione/ModaleValidaRec";
import ModaleValidaForzatamenteRec from "../components/dichiarazione/ModaleValidaForzatamenteRec";
import ModaleStoricoNote from "../components/dichiarazione/ModaleStoricoNote";
import ModaleApprovaCdr from "../components/dichiarazione/ModaleApprovaCdr";
import ModaleRiapriRec from "../components/dichiarazione/ModaleRiapriRec";
import ModaleMaterialeNonConformeCdr from "../components/dichiarazione/ModaleMaterialeNonConformeCdr";
import ModaleMaterialeNonConformeRec from "../components/dichiarazione/ModaleMaterialeNonConformeRec";
import * as controlli from "../functions/controlliDichiarazione";
import * as funzioni from "../functions/dichiarazioneFunctions";
import { useGlobalStateContext } from "../context/GlobalContext";
import { MESI, INFO_CONTROLLI, CHECK_NOK, TIPO_IMPIANTI, PARAMETRI } from "../common/constants";
import { parametri } from "../common/mocks";
import { caricaListaCdr, caricaDichiarazione, riepilogoMesi, userCdrInit, caricaValoriCebis, caricaListaImpianti, caricaListaCentriDiRecupero, salvaDichiarazione, caricaDichiarazionePrecedente, salvaDichiarazioneNc, caricaParametriDichiarazione } from "../actions/portalApi";
import Toast, {TOAST_DANGER, TOAST_SUCCESS} from "../components/comuni/Toast";
import ModaleRigettaCdr from "../components/dichiarazione/ModaleRigettaCdr";
import ModaleInviaCdrDaRec from "../components/dichiarazione/ModaleInviaCdrDaRec";

export default function Dichiarazione() {
  const user = useGlobalStateContext();
  const [isAmministratore, setAmministratore] = useState(false);
  const [isCaricaDichiarazioneBottone, setIsCaricaDichiarazioneBottone] = useState(false);
  const [modificaInCorso, setModificaInCorso] = useState(false);
  const [meseSelezionatoModale, setMeseSelezionatoModale] = useState(null);
  const [mostraModale, setMostraModale] = useState(false);
  const [mostraModaleModificaInCorso, setMostraModaleModificaInCorso] = useState(false);
  const [mostraModaleMaterialeNonConformeCdr, setMostraModaleMaterialeNonConformeCdr] = useState(false);
  const [mostraModaleMaterialeNonConformeRec, setMostraModaleMaterialeNonConformeRec] = useState(false);
  const [mostraModaleValidaRec, setMostraModaleValidaRec] = useState(false);
  const [mostraModaleValidaForzatamenteRec, setMostraModaleValidaForzatamenteRec] = useState(false);
  const [mostraModaleInviaCdrDaRec, setMostraModaleInviaCdrDaRec] = useState(false);
  const [mostraModaleStoricoNote, setMostraModaleStoricoNote] = useState(false);
  const [mostraModaleApprovaCdr, setMostraModaleApprovaCdr] = useState(false);
  const [mostraModaleRigettaCdr, setMostraModaleRigettaCdr] = useState(false);
  const [mostraModaleRiapriRec, setMostraModaleRiapriRec] = useState(false);
  const [annoCorrente, setAnnoCorrente] = useState(moment().year());
  const [meseCorrente, setMeseCorrente] = useState(moment().month() + 1);
  const [valoriCebis, setValoriCebis ] = useState(null);
  const [messageToast, setMessageToast ] = useState({
    text: "",
    isVisible: false,
    type: ""
  });
  const [listaCdr, setListaCdr] = useState(null);
  const [listaCdrPerTrasferimenti, setListaCdrPerTrasferimenti] = useState(null);
  const [listaImpianti, setListaImpianti] = useState(null);
  const [listaNote, setListaNote] = useState();
  const [solaLettura, setSolaLettura] = useState(false);
  const [parametroAbilitato, setParametroAbilitato] = useState(controlli.resetParametri());
  const [percentualePerditaProcesso, setPercentualePerditaProcesso] = useState(parametri);
  const [listaRiepilogoMesi, setListaRiepilogoMesi] = useState(null);
  const [dichiarazione, setDichiarazione ] = useState({
    id: null,
    cdrId: null,
    cdrNome: null,
    mese: null,
    anno: null,
    stato: "NUOVA",
    statoValidita: "NONE",
    dataDichiarazione: null,
    idNota: null,
    testoNota: "",
    testoNotaGiustificativa: "",
		nc: null,
    allegatiNc: [],
    allegati: [],
    statoControlli: null,
    trasferimentiMateriaPrima : [],
    ingressiMateriaCss: null,
    ingressiMateriaCdr: null,
    ingressiMateriaCssCdr: null,
    ingressiMateriaCssC: null,
    scartiProcessoNoLav: null,
    matNonConforme: null,
    prodottoCssCdr: null,
    prodottoCssC: null,
    metalliSeparati: null,
    scartiProcesso: null,
    perditeProcesso: null,
    usciteConDestino : [],
    usciteNoDestMetalli: null,
    ingressiExtraCorepla: null,
    usciteExtraCorepla: null,
    avvioRiciclo: null,
    autoRiciclo: null,
    usciteAvvioRiciclo: null,
    usciteAutoRiciclo: null
  });
  const [dichiarazionePrecedente, setDichiarazionePrecedente ] = useState(null);
  const [coloriControlliDiProcesso, setColori] = useState(controlli.resetControlli());

  useEffect(() => {
      window.onbeforeunload = null;
      window.history.replaceState({}, '', '/#/dichiarazione');
      ruoloUtente();
    }, []);

  useEffect(() => {
    if (dichiarazione.id!=null) {
      let dich = {...dichiarazione};
      controlli.valuesToNumber(dich);
      setDichiarazione(dich);
    }
  }, [valoriCebis]);

  useEffect(() => {
    if (valoriCebis!=null) {
      startControlli();
    }
  }, [dichiarazione]);

  const ruoloUtente = () => {
    let isCdr = false;
    user.user.ruoli.map((e)=> {
      if (e.codiceRuolo == 'REC_CDR_SUPERVISOR'|| e.codiceRuolo == 'REC_CDR_USER') {
        isCdr = true
        setAmministratore(false);
        userCdrInit().then((res)=> {
          if (res !== null){
            let listaCdrParsata = res.data.data.map((e)=> JSON.parse(e.profilo));
            setListaCdr(...listaCdrParsata);
          }
        })
      }
    });
    if (!isCdr) {
      setAmministratore(true);
      setSolaLettura(true);
      if (dichiarazione.stato == "DA_VERIFICARE") {
        setSolaLettura(false);
      }
      caricaListaCdr().then((res)=> {
        if (res !== null){
          setListaCdr(res.data.data);
        }
      })
    }
  }

  const listaCentriDiRecupero = () => {
    return (
      <select className="form-control" value={dichiarazione.cdrId} onChange={ (e) => {
      aggiornamentoGenericoInNumero("cdrId", e.target.value);} } required>
        <option key={ "" } value={ "" }>-- Seleziona CDR --</option>
        {listaCdr.map((cdr) => {
          return <option key={ cdr.organizationId } value={cdr.organizationId}>{ cdr.nome }</option>
        })}
      </select>)
  }

  const listaCentriDiRecuperoTrasferimentoACdr = (i) => {
    if (listaCdrPerTrasferimenti.length > 0){
      return (
        <select className="form-control" value={dichiarazione.trasferimentiMateriaPrima[i].idCdrDestinazione } disabled={solaLettura} onChange={ (e) => {
          aggiornaTrasferimentoACdr("idCdrDestinazione", e.target.value, i);} } required>
            <option key={ "" } value={ "" }>-- Seleziona CDR --</option>
            {listaCdrPerTrasferimenti.map((cdr) => {
              return <option key={ cdr.organizationId } value={cdr.organizationId}>{ cdr.nome }</option>
            })}
        </select>
      )
    }
  }

  const listaImpiantiDiDestinazione = (i) => {
    if (listaImpianti.length > 0){
      return (
        <select className="form-control" value={ dichiarazione.usciteConDestino[i].idImpiantoDest } disabled={solaLettura} onChange={ (e) => {
          aggiornamentoUsciteConDestino("idImpiantoDest", e.target.value, i);} } required>
            <option key={ "" } value={ "" }>-- Seleziona Impianto --</option>
            { listaImpianti.map((imp) => {
              return (
                <option key={ imp.id } value={imp.id}>{ imp.ragioneSociale }</option>
              );
            }) }
        </select>
      )
    }
  }

  const listaMesi = () => {
    return (
      <select className="form-control" onChange={ (e) => {
        aggiornamentoGenericoInNumero("mese", e.target.value);} } required>
        <option key={ "mesi-empty" } value={ "" }>-- Seleziona mese --</option>
        {MESI.map((m) => {
          return <option key={ m.valore } value={ m.valore }>{ m.testo }</option>;
        })}
      </select>
    )
  }

  const listaAnni = () => {
    let annoCorrente = new Date().getFullYear();
    let anni = []
    for (let k = -2; k < 1; k++) {
      anni.push(annoCorrente + k)
      anni.sort((a, b) => b - a)
    }
    return (
      <select className="form-control" onChange={ (e) => {
        aggiornamentoGenericoInNumero("anno", e.target.value);} } required>
        <option key={ "anni-empty" } value={ "" }>-- Seleziona anno --</option>
        {anni.map((a) => {
          return <option key={ a } value={ a }>{ a }</option>;
        })}
      </select>
    )
  }

	window.onbeforeunload = function () {
		return " ";
	};

  const vaiAChiamataCaricaDichiarazione = () => {
      if (modificaInCorso) {
        chiamataCaricaDichiarazione();
        setModificaInCorso(false);
        setMostraModaleModificaInCorso(false);
      }
    return
  }

  const chiamataCaricaDichiarazionePrecedente = (cdrId, mese, anno, meseSelezionato=null) => {
    let infoHeaderDichiarazionePrecedente = funzioni.infoHeaderDichiarazionePrecedente(cdrId, mese, anno, meseSelezionato);
    caricaDichiarazionePrecedente(infoHeaderDichiarazionePrecedente).then((res)=>{
      if (res != "") {
        if (res.data.stato == "APPROVATA") {
          setDichiarazionePrecedente(res.data);
        } else {
          setDichiarazionePrecedente(null);
        }
      }
      if (res == "") {
        setDichiarazionePrecedente(null);
      }
    }); 
  }

  const chiamataCaricaDichiarazione = (meseSelezionato = null) => {
    let infoDichiarazioneHeader = {
      idCdr: dichiarazione.cdrId,
      mese: dichiarazione.mese,
      anno: dichiarazione.anno
    }
    if (meseSelezionato!=null) {
      infoDichiarazioneHeader.mese = meseSelezionato;
    }
    if (meseSelezionatoModale != null && modificaInCorso) {
      infoDichiarazioneHeader.mese = meseSelezionatoModale;
    }
    let isSolaLettura = funzioni.isSolaLettura(isAmministratore, dichiarazione.stato);
    setSolaLettura(isSolaLettura);
    caricaDichiarazione(infoDichiarazioneHeader).then((res)=>{
      let tmpDich = {...dichiarazione};
      setMeseSelezionatoModale(null);
      setColori(controlli.resetControlli());
      setIsCaricaDichiarazioneBottone(true);
      if (meseSelezionato == null) {
        funzioni.nomeCdrInHeader(tmpDich, listaCdr);
      }
      isSolaLettura = funzioni.isSolaLettura(isAmministratore, tmpDich.stato);
      setSolaLettura(isSolaLettura);
      if (res !== "" ) {
        funzioni.buildDichiarazioneObject(tmpDich, res.data);
        chiamataCaricaDichiarazionePrecedente(res.data.idCdr, res.data.mese, res.data.anno, meseSelezionato);
        let noteSalvate = res.data.note;
        setListaNote(noteSalvate);
      } else {
        tmpDich = funzioni.buildEmptyDichiarazione(tmpDich.cdrId, tmpDich.cdrNome, infoDichiarazioneHeader.mese, tmpDich.anno);
        let noteSalvate = ""
        setListaNote(noteSalvate);
        setDichiarazionePrecedente(null);
      }
      caricaParametriDichiarazione(infoDichiarazioneHeader).then((res) => {
          if (res.data != null || res.data.length > 0) {
            let risultati = funzioni.listaParametriAbilitati(res.data, parametroAbilitato, PARAMETRI);
            if (risultati.percentuale != null) {
              setPercentualePerditaProcesso(risultati.percentuale);
            }
            if (risultati.parametriAbilitati != null) {
              setParametroAbilitato(risultati.parametriAbilitati);
            }
          } 
          if (res.data == null || res.data.length == 0) {
            setPercentualePerditaProcesso(parametri);
            setParametroAbilitato(controlli.resetParametri());
          }
      })
      isSolaLettura = funzioni.isSolaLettura(isAmministratore, tmpDich.stato);
      setSolaLettura(isSolaLettura);
      caricaValoriCebis({
        idCdr: infoDichiarazioneHeader.idCdr,
        mese: infoDichiarazioneHeader.anno+infoDichiarazioneHeader.mese.toString().padStart(2, '0'),
      }).then((cebisData)=>{
        if (cebisData !== null) {
          funzioni.buildCebisValues(tmpDich, cebisData.data);
          setDichiarazione(tmpDich);
          setValoriCebis(cebisData.data);
        }
      });
    });
  }

  const chiamataRiepilogoMesi = () => {
    let riepilMesi = {
    idCdr: dichiarazione.cdrId,
    anno: dichiarazione.anno
    }
    riepilogoMesi(riepilMesi).then((res)=>{
      if (res !== null){
        setListaRiepilogoMesi(res.data.data);
      }
    })
  }

  const chiamataCaricaListaImpianti = (meseSelezionato=null) => {
    let infoDichiarazioneHeader = {
      idCdr: dichiarazione.cdrId,
      mese: dichiarazione.mese,
      anno: dichiarazione.anno
    };
    if (meseSelezionato!=null) {
      infoDichiarazioneHeader.mese = meseSelezionato;
    }
    caricaListaImpianti(infoDichiarazioneHeader).then((res)=>{
        if (res != "") {
          setListaImpianti(res.data.data);
        }
    });
  }

  const chiamataListaCompletaCdr = (meseSelezionato = null) => {
    let infoDichiarazioneHeader = {
      idCdr: dichiarazione.cdrId,
      mese: dichiarazione.mese,
      anno: dichiarazione.anno
    };
    if (meseSelezionato!=null) {
      infoDichiarazioneHeader.mese = meseSelezionato;
    }
    caricaListaCentriDiRecupero(infoDichiarazioneHeader).then((res) => {
      if (res != "") {
        setListaCdrPerTrasferimenti(res.data.data);
      }
    })
  }

  const caricaDichiarazioneBottone = () => {
    setModificaInCorso(false);
    if (dichiarazione.cdrId == null || dichiarazione.mese == null || dichiarazione.anno == null) {
      setMessageToast({
        isVisible: true,
        text: "Tutti i campi sono obbligatori",
        type: TOAST_DANGER
      })
      return ;
    }
    if (dichiarazione.mese > meseCorrente && dichiarazione.anno == annoCorrente) {
      setMessageToast({
        isVisible: true,
        text: "Mese selezionato non valido",
        type: TOAST_DANGER
      })
      return ;
    }
    Promise.allSettled([chiamataRiepilogoMesi(), chiamataCaricaListaImpianti(), chiamataCaricaDichiarazione(), chiamataListaCompletaCdr()])
  }

  const dichiarazioneLoad = (meseSelezionato) => {
    if(dichiarazione.anno == annoCorrente && meseCorrente<meseSelezionato) {
      return false;
    }
    if (modificaInCorso) {
      setMostraModaleModificaInCorso(true);
      setMeseSelezionatoModale(meseSelezionato);
    }else{
    setMostraModaleModificaInCorso(false);
    chiamataCaricaDichiarazione(meseSelezionato);
    chiamataRiepilogoMesi();
    chiamataCaricaListaImpianti(meseSelezionato);
    }
  }

  const getNomeTipoImpianto = (idImpiantoDest) => {
    return funzioni.nomeTipologiaImpianto(idImpiantoDest, listaImpianti, TIPO_IMPIANTI);
  }

  const addUscitaConDestino = () => {
    let dich = { ...dichiarazione };
    let usc = {
      id: null,
      idImpiantoDest: null,
      usciteCssCdr: null,
      usciteCssC: null,
      usciteScarti: null,
      usciteNc: null
    }
    dich.usciteConDestino.push(usc);
    setDichiarazione(dich);
    setModificaInCorso(true);
  }

  const addTrasferimentoMateriaPrima = () => {
    let dich = {...dichiarazione}
    let trasf = {
      id: null,
      idCdrDestinazione: null,
      matAvviatoCdr: null,
      usciteCssCdr: null,
      usciteCssC: null,
      usciteScarti: null,
      usciteNc: null
    };
    dich.trasferimentiMateriaPrima.push(trasf);
    setDichiarazione(dich);
    setModificaInCorso(true);
  };

  const rimuoviTrasferimentoMateriaPrima = (elementoCorrente, indiceElementoCorrente) => {
    let tmp = {...dichiarazione};
    tmp.trasferimentiMateriaPrima.splice(indiceElementoCorrente, 1);
    setModificaInCorso(true);
    setDichiarazione(tmp);
  };

  const rimuoviUsciteConDestino = (elementoCorrente, indiceElementoCorrente) => {
    let tmp = {...dichiarazione};
    tmp.usciteConDestino.splice(indiceElementoCorrente, 1);
    setDichiarazione(tmp);
  };

  const aggiungiNota = (value) => {
    let txtNota = {...dichiarazione}
    txtNota.testoNota = value;
    setDichiarazione(txtNota);
    setModificaInCorso(true);
  }

    const aggiungiNotaGiustificativa = (value) => {
    let txtNota = {...dichiarazione}
    txtNota.testoNotaGiustificativa = value;
    setDichiarazione(txtNota);
    setModificaInCorso(true);
  }

  const storicoNote = () => {
    if (listaNote != null && listaNote.length > 0) {
			return listaNote.map((n) => {
				return (
					<tr key={n.id}>
						<td>{n.statoDichiarazione}</td>
						<td>{n.testo}</td>
            <td>{n.nomeAutore}</td>
            <td>{funzioni.nomeTipologiaNote(n.tipologiaNota)}</td>
					</tr>
				);
			});
		} else {
			return (
				<tr>
					<td>Non ci sono note per questa dichiarazione</td>
				</tr>
			);
		}
  }

  const storicoAllegati = () => {
    let dich = {...dichiarazione};
    if (dichiarazione.allegati != null && dichiarazione.allegati.length > 0) {
        return (dichiarazione.allegati.map((al, indice)=> {
          return(
          <table className="table dich-controlli-table">
            <tbody>
              <tr>
                <td><a target="_blank" href={`${RECUPERO_API}/dichiarazione/allegato/download/${al.idAsset}?namespace=data/dichiarazione/CDR-${dich.cdrId}&_a=${getAccessTokenCookie()}`} rel="noreferrer">{al.tipoAllegato !== 2 ? al.nomeFile:null}</a></td>
                {!solaLettura && al.tipoAllegato !== 2 ? <td style={{ "width": "70px" }}><button className="btn btn-sm btn-primary" type="button" onClick={() => {rimuoviAllegato(indice);}}>Elimina</button></td>:null}
              </tr>
            </tbody>
          </table>
          )
        }))
      }                
  }

  const calendarioDataDellaDichiarazione = () => {
    let data = {...dichiarazione};
    return(
      <DatePicker
        className="date-form-control"
        closeCalendar={true}
        dateFormat="dd/MM/yyyy"
        placeholderText="gg/mm/aaaa"
        todayButton="Oggi"
        popperPlacement="bottom-start"
        showYearDropdown
        dateFormatCalendar="MMMM"
        yearDropdownItemNumber={3}
        scrollableYearDropdown
        readOnly={solaLettura}
        selected={data.dataDichiarazione != null ? moment(data.dataDichiarazione).toDate(): null}
        onChange={(date) => {
          let data = {...dichiarazione};
          if (!solaLettura) {
          data.dataDichiarazione = moment(date).format("YYYY-MM-DD");
          setDichiarazione(data);
          setModificaInCorso(true);
          }
        }}
      />
    )
  }

	const aggiornaTrasferimentoACdr = (property, value, i) => {
    let tacdr = [...dichiarazione.trasferimentiMateriaPrima];
    tacdr[i][property] = value.length > 0 ? Number(value): null;
    setDichiarazione((prevState) => ({
      ...prevState,
      trasferimentiMateriaPrima: tacdr
    }));
    setModificaInCorso(true);
  };

	const aggiornamentoUsciteConDestino = (property, value, i) => {
    let ucd = [...dichiarazione.usciteConDestino];
    ucd[i][property] = value.length > 0 ? Number(value): null;
    setDichiarazione((prevState) => ({
      ...prevState,
      usciteConDestino: ucd
    }));
    setModificaInCorso(true);
  };
  
	const aggiornamentoGenericoInNumero = (property, value) => {
		let dich = { ...dichiarazione };
    dich[property] = value.length > 0 ? Number(value): null;
		setDichiarazione(dich);
    setModificaInCorso(true);
    };

  const reset = () => {
    let dichiarazioneReset = funzioni.buildEmptyDichiarazione();
    setIsCaricaDichiarazioneBottone(!isCaricaDichiarazioneBottone);
    setModificaInCorso(false);
    ruoloUtente();
    setDichiarazione(dichiarazioneReset);
  }

  const startControlli = () => {
    let dich = {...dichiarazione};
    let colore = {...coloriControlliDiProcesso};
    colore.colore_PerditaDiProcesso = controlli.perditaDiProcesso(dich.perditeProcesso, dich.prodottoCssCdr, dich.prodottoCssC, dich.metalliSeparati, dich.scartiProcesso);
    colore.colore_ScartiDiProcesso = controlli.scartiDiProcesso(percentualePerditaProcesso, dich.scartiProcesso, dich.prodottoCssCdr, dich.prodottoCssC, dich.metalliSeparati)
    colore.colore_GiacenzaRicezione = controlli.giacenzaRicezione(valoriCebis.giacenza_magazzino_ricezione_a, dich.matNonConforme, dich.prodottoCssCdr, dich.prodottoCssC, dich.metalliSeparati, dich.scartiProcesso, dich.perditeProcesso, dich.avvioRiciclo, dich.autoRiciclo, dich.trasferimentiMateriaPrima);
    colore.colore_GiacenzaMatNonConforme = controlli.giacenzaMatNonConforme(valoriCebis.giacenza_magazzino_output, dich.matNonConforme, dich.usciteConDestino, dich.trasferimentiMateriaPrima);
    colore.colore_GiacenzaCssCdr = controlli.giacenzaCssCdr(valoriCebis.giacenza_css_cdr_combustibile_magazzino_output, dich.prodottoCssCdr, dich.usciteConDestino, dich.trasferimentiMateriaPrima);
    colore.colore_GiacenzaCssC = controlli.giacenzaCssC(valoriCebis.giacenza_css_c_combustibile_non_ass_magazzino_output, dich.prodottoCssC, dich.usciteConDestino, dich.trasferimentiMateriaPrima);
    colore.colore_GiacenzaMetalliSeparati = controlli.giacenzaMetalliSeparati(valoriCebis.giacenza_metalli_separati, dich.metalliSeparati, dich.usciteNoDestMetalli);
    colore.colore_GiacenzaScartiDiProcesso = controlli.giacenzaScartiDiProcesso(valoriCebis.giacenza_scarti_processo, dich.scartiProcesso, dich.usciteConDestino, dich.trasferimentiMateriaPrima);
    colore.colore_GiacenzaAvvioRiciclo = controlli.giacenzaAvvioRiciclo(valoriCebis.giacenza_mat_avvio_riciclo, dich.avvioRiciclo,  dich.usciteAvvioRiciclo);
    colore.colore_GiacenzaAutoRiciclo = controlli.giacenzaAutoRiciclo(valoriCebis.giacenza_mat_autoriciclo, dich.autoRiciclo, dich.usciteAutoRiciclo);
    setColori(colore);
    if (dich.statoValidita !== "VALIDO_FORZATAMENTE") {
      controlli.calcolaStatoValidita(colore);
    }
  };

  const salva = () => {
    let dich = {...dichiarazione};
    let indiciPresentiTrasfUscite = false;
    dich.statoValidita = coloriControlliDiProcesso.__stato_validita;
    controlli.valuesToNumber(dich);
    indiciPresentiTrasfUscite = funzioni.controlloIndiciPresenza(dich);
    if (!indiciPresentiTrasfUscite) {
      setMessageToast({
        text: "CDR/Impianti di destinazione obbligatori",
        isVisible: true,
        type: TOAST_DANGER
      });
      return;
    }
    let data = funzioni.buildDichiarazioneServer(dich);
    if (data.stato == "REVISIONE_CDR") {
      data.stato = "NUOVA";
    }
    salvaDichiarazione(data).then(()=>{
    setModificaInCorso(false);
    Promise.all([chiamataCaricaDichiarazione(), chiamataRiepilogoMesi()]);
    }).catch((err) => {
      if (err) {
        setMessageToast({
          text: "Errore nel salvataggio della dichiarazione",
          isVisible: true,
          type: TOAST_DANGER
        });
      }
      });
    setDichiarazione(dich);
  }

    const nonConformita = (allegatoNc) => {
    let dich = {...dichiarazione};
    let colore = {...coloriControlliDiProcesso};
    let data = funzioni.buildDichiarazioneServer(dich);
    if (dich.nc == null || dich.nc.statoNc == "NONE") {
      data.nc = {
        id: null,
        materialeNcOriginale: dich.matNonConforme,
        materialeNcApprovato: dich.matNonConforme,
        noteCdr: dich.testoNotaGiustificativa,
        noteCorepla: null,
        allegati: allegatoNc,
        statoNc: "NONE",
        dataChiusura: null
      };
    }
    if (dich.nc?.statoNc == "NON_APPROVATA") {
      data.nc.materialeNcOriginale = dich.matNonConforme;
      data.nc.noteCdr = dich.testoNotaGiustificativa;
      data.nc.allegati = allegatoNc;
      data.nc.statoNc = "NONE";
    }
    data.stato = "DA_VERIFICARE";
    let mappaColori = {
      "CHK001": controlli.convertiColoreInCodice(colore.colore_PerditaDiProcesso),
      "CHK002": controlli.convertiColoreInCodice(colore.colore_ScartiDiProcesso),
      "CHK003": controlli.convertiColoreInCodice(colore.colore_GiacenzaRicezione),
      "CHK004": controlli.convertiColoreInCodice(colore.colore_GiacenzaMatNonConforme),
      "CHK005": controlli.convertiColoreInCodice(colore.colore_GiacenzaCssCdr),
      "CHK006": controlli.convertiColoreInCodice(colore.colore_GiacenzaCssC),
      "CHK007": controlli.convertiColoreInCodice(colore.colore_GiacenzaMetalliSeparati),
      "CHK008": controlli.convertiColoreInCodice(colore.colore_GiacenzaScartiDiProcesso),
      "CHK009": controlli.convertiColoreInCodice(colore.colore_GiacenzaAvvioRiciclo),
      "CHK0010": controlli.convertiColoreInCodice(colore.colore_GiacenzaAutoRiciclo),
    }
    data.statoControlli = JSON.stringify(mappaColori);
    salvaDichiarazione(data).then(()=>{
    setModificaInCorso(false);
    Promise.all([chiamataCaricaDichiarazione(), chiamataRiepilogoMesi()]);
    setMessageToast({
      text: "Successo nella conferma della dichiarazione",
      isVisible: true,
      type: TOAST_SUCCESS
    });
    }).catch((err) => {
      if (err) {
        setMessageToast({
          text: "Errore nella conferma della dichiarazione",
          isVisible: true,
          type: TOAST_DANGER
        });
      }
    });
  }

  const conferma = () => {
    let dich = {...dichiarazione};
    let colore = {...coloriControlliDiProcesso};
    let controlliFinali = {};
    controlliFinali = funzioni.controlliFinali(dichiarazione, coloriControlliDiProcesso);
    if (!controlliFinali.verificato) {
      setMessageToast({
        text: controlliFinali.descrizioneErrore,
        isVisible: true,
        type: TOAST_DANGER
      });
      return;
    }
    dich.statoValidita = coloriControlliDiProcesso.__stato_validita;
    controlli.valuesToNumber(dich);
    dich.stato = "DA_VERIFICARE";
    let data = funzioni.buildDichiarazioneServer(dich);
    let mappaColori = {
      "CHK001": controlli.convertiColoreInCodice(colore.colore_PerditaDiProcesso),
      "CHK002": controlli.convertiColoreInCodice(colore.colore_ScartiDiProcesso),
      "CHK003": controlli.convertiColoreInCodice(colore.colore_GiacenzaRicezione),
      "CHK004": controlli.convertiColoreInCodice(colore.colore_GiacenzaMatNonConforme),
      "CHK005": controlli.convertiColoreInCodice(colore.colore_GiacenzaCssCdr),
      "CHK006": controlli.convertiColoreInCodice(colore.colore_GiacenzaCssC),
      "CHK007": controlli.convertiColoreInCodice(colore.colore_GiacenzaMetalliSeparati),
      "CHK008": controlli.convertiColoreInCodice(colore.colore_GiacenzaScartiDiProcesso),
      "CHK009": controlli.convertiColoreInCodice(colore.colore_GiacenzaAvvioRiciclo),
      "CHK0010": controlli.convertiColoreInCodice(colore.colore_GiacenzaAutoRiciclo),
    }
    data.statoControlli = JSON.stringify(mappaColori);
    if (data.matNonConforme > 0 && data.nc == null ) {
      setMostraModaleMaterialeNonConformeCdr(true);
    } else {
      salvaDichiarazione(data).then(()=>{
      setModificaInCorso(false);
      Promise.all([chiamataCaricaDichiarazione(), chiamataRiepilogoMesi()]);
      }).catch((err) => {
      if (err) {
        setMessageToast({
          text: "Errore nella conferma della dichiarazione",
          isVisible: true,
          type: TOAST_DANGER
        });
      }
      });
    }
  }

  const validaOppureValidaForzatamente = () => {
    let coloriProcessiDiControllo = Object.values(coloriControlliDiProcesso);
    for (let i = 0; i < coloriProcessiDiControllo.length; i++) {
      if (coloriProcessiDiControllo[i] == CHECK_NOK) {
        setMostraModaleValidaForzatamenteRec(true);
        return;
      }
    }
    setMostraModaleValidaRec(true);
  }

  const valida = () => {
    let dich = {...dichiarazione};
    let colore = {...coloriControlliDiProcesso};
    let controlliFinali = {};
    controlliFinali = funzioni.controlliFinali(dich);
    if (!controlliFinali.verificato) {
      setMessageToast({
        text: controlliFinali.descrizioneErrore,
        isVisible: true,
        type: TOAST_DANGER
      });
      return;
    }
    controlli.valuesToNumber(dich);
    if (dich.nc != null && dich.nc != "" && dich.nc.statoNc == "NONE") {
        setMessageToast({
        text: "Approvare o Rifiutare il materiale non conforme presente nella dichiarazione",
        isVisible: true,
        type: TOAST_DANGER
      });
      return;  
    }
    dich.stato = "VERIFICATA";
    dich.statoValidita = "VALIDO";
    let data = funzioni.buildDichiarazioneServer(dich);
    data.note.push({
      id: null, 
      testo: dichiarazione.testoNotaGiustificativa,
      tipologiaNota : 0
    })
    let mappaColori = {
      "CHK001": controlli.convertiColoreInCodice(colore.colore_PerditaDiProcesso),
      "CHK002": controlli.convertiColoreInCodice(colore.colore_ScartiDiProcesso),
      "CHK003": controlli.convertiColoreInCodice(colore.colore_GiacenzaRicezione),
      "CHK004": controlli.convertiColoreInCodice(colore.colore_GiacenzaMatNonConforme),
      "CHK005": controlli.convertiColoreInCodice(colore.colore_GiacenzaCssCdr),
      "CHK006": controlli.convertiColoreInCodice(colore.colore_GiacenzaCssC),
      "CHK007": controlli.convertiColoreInCodice(colore.colore_GiacenzaMetalliSeparati),
      "CHK008": controlli.convertiColoreInCodice(colore.colore_GiacenzaScartiDiProcesso),
      "CHK009": controlli.convertiColoreInCodice(colore.colore_GiacenzaAvvioRiciclo),
      "CHK0010": controlli.convertiColoreInCodice(colore.colore_GiacenzaAutoRiciclo),
    }
    data.statoControlli = JSON.stringify(mappaColori);
    salvaDichiarazione(data).then(()=>{
    setModificaInCorso(false);
    setMostraModaleValidaRec(false);
    Promise.all([chiamataCaricaDichiarazione(), chiamataRiepilogoMesi()]);
    setMessageToast({
      text: "Validazione dichiarazione avvenuta con successo!",
      isVisible: true,
      type: TOAST_SUCCESS,
    });
    }).catch((err) => {
      if (err) {
        setMessageToast({
          text: "Errore nella conferma della dichiarazione",
          isVisible: true,
          type: TOAST_DANGER
        });
      }
    });
  }

  const validaForzatamente = () => {
    let dich = {...dichiarazione};
    let colore = {...coloriControlliDiProcesso};
    if (dich.testoNotaGiustificativa == "") {
      setMessageToast({
        text: "La nota giustificativa è obbligatoria",
        isVisible: true,
        type: TOAST_DANGER
      });
      return;
    }
    let indiciPresentiTrasfUscite = funzioni.controlloIndiciPresenza(dich);
    if (!indiciPresentiTrasfUscite) {
      setMessageToast({
        text: "CDR/Impianti di destinazione obbligatori",
        isVisible: true,
        type: TOAST_DANGER
      });
      return;
    }
    dich.stato = "VERIFICATA";
    dich.statoValidita = "VALIDO_FORZATAMENTE";
    let data = funzioni.buildDichiarazioneServer(dich);
    data.note.push({
      id: null, 
      testo: dichiarazione.testoNotaGiustificativa,
      tipologiaNota : 1
    })
    let mappaColori = {
      "CHK001": controlli.convertiColoreInCodice(colore.colore_PerditaDiProcesso),
      "CHK002": controlli.convertiColoreInCodice(colore.colore_ScartiDiProcesso),
      "CHK003": controlli.convertiColoreInCodice(colore.colore_GiacenzaRicezione),
      "CHK004": controlli.convertiColoreInCodice(colore.colore_GiacenzaMatNonConforme),
      "CHK005": controlli.convertiColoreInCodice(colore.colore_GiacenzaCssCdr),
      "CHK006": controlli.convertiColoreInCodice(colore.colore_GiacenzaCssC),
      "CHK007": controlli.convertiColoreInCodice(colore.colore_GiacenzaMetalliSeparati),
      "CHK008": controlli.convertiColoreInCodice(colore.colore_GiacenzaScartiDiProcesso),
      "CHK009": controlli.convertiColoreInCodice(colore.colore_GiacenzaAvvioRiciclo),
      "CHK0010": controlli.convertiColoreInCodice(colore.colore_GiacenzaAutoRiciclo),
    }
    data.statoControlli = JSON.stringify(mappaColori);
    salvaDichiarazione(data).then(()=>{
    setModificaInCorso(false);
    setMostraModaleValidaForzatamenteRec(false);
    Promise.all([chiamataCaricaDichiarazione(), chiamataRiepilogoMesi()]);
    setMessageToast({
      text: "Validazione dichiarazione avvenuta con successo!",
      isVisible: true,
      type: TOAST_SUCCESS,
    });
    }).catch((err) => {
      if (err) {
        setMessageToast({
          text: "Errore nella conferma della dichiarazione",
          isVisible: true,
          type: TOAST_DANGER
        });
      }
    });
  }

  const approvaMatNoConfRec = () => {
    let dich = {...dichiarazione};
    if (dich.nc.noteCorepla == "") {
      setMessageToast({
        text: "La nota riguardante la non conformità è obbligatoria",
        isVisible: true,
        type: TOAST_DANGER
      });
      return;
    }
    if (dich.nc.materialeNcApprovato == null || dich.nc.materialeNcApprovato == "") {
      setMessageToast({
        text: "Specificare la quantità del materiale non conforme da approvare",
        isVisible: true,
        type: TOAST_DANGER
      });
      return; 
    }
    dich.nc.statoNc = "APPROVATA";
    dich.nc.dataChiusura = moment(new Date()).format("YYYY-MM-DD");
    salvaDichiarazioneNc(dich.nc).then(() => {
    setMostraModaleMaterialeNonConformeRec(false);
    setModificaInCorso(false);
    Promise.all([chiamataCaricaDichiarazione(), chiamataRiepilogoMesi()]);
    setMessageToast({
      text: "Approvazione non conformità avvenuto con successo!",
      isVisible: true,
      type: TOAST_SUCCESS,
    });
    }).catch((err) => {
      if (err) {
        setMessageToast({
          text: "Errore nell'approvazione della non conformità",
          isVisible: true,
          type: TOAST_DANGER
        });
      }
    });
  }

    const rifiutaMatNoConfRec = () => {
    let dich = {...dichiarazione};
    if (dich.nc.noteCorepla == "") {
      setMessageToast({
        text: "La nota riguardante la non conformità è obbligatoria",
        isVisible: true,
        type: TOAST_DANGER
      });
      return;
    }
    dich.nc.statoNc = "NON_APPROVATA";
    dich.nc.materialeNcApprovato = 0;
    salvaDichiarazioneNc(dich.nc).then(() => {
    dich.matNonConforme = 0;
    setDichiarazione(dich);
    setMostraModaleMaterialeNonConformeRec(false);
    setModificaInCorso(false);
    Promise.all([chiamataCaricaDichiarazione(), chiamataRiepilogoMesi()]);
    setMessageToast({
      text: "Rifiuto non conformità avvenuto con successo!",
      isVisible: true,
      type: TOAST_SUCCESS,
    });
    }).catch((err) => {
      if (err) {
        setMessageToast({
          text: "Errore nel rifiuto della non conformità",
          isVisible: true,
          type: TOAST_DANGER
        });
      }
    });
  }

  const approva = () => {
    let dich = {...dichiarazione};
    if (dich.nc != null && dich.nc != "" && dich.nc.statoNc == "NON_APPROVATA") {
        setMessageToast({
        text: "Approvare o Rifiutare il materiale non conforme presente nella dichiarazione",
        isVisible: true,
        type: TOAST_DANGER
      });
      return;  
    }
    let data = funzioni.buildDichiarazioneServer(dich);
    if (dich.testoNotaGiustificativa != "") {
    data.note.push({
      id: null, 
      testo: dichiarazione.testoNotaGiustificativa,
      tipologiaNota : 2
    });
    }
    data.stato = "APPROVATA";
    salvaDichiarazione(data).then(()=>{
    setMostraModaleApprovaCdr(false);
    setModificaInCorso(false);
    Promise.all([chiamataCaricaDichiarazione(), chiamataRiepilogoMesi()]);
    setMessageToast({
      text: "Approvazione dichiarazione avvenuta con successo!",
      isVisible: true,
      type: TOAST_SUCCESS,
    });
    }).catch((err) => {
      if (err) {
        setMessageToast({
          text: "Errore nell'approvazione della dichiarazione",
          isVisible: true,
          type: TOAST_DANGER
        });
      }
    });
  }

  const rigetta = () => {
    let dich = {...dichiarazione};
    if (dich.nc != null && dich.nc != "" && dich.nc.statoNc == "NON_APPROVATA") {
        setMessageToast({
        text: "Approvare o Rifiutare il materiale non conforme presente nella dichiarazione",
        isVisible: true,
        type: TOAST_DANGER
      });
      return;  
    }
    dich.stato = "DA_VERIFICARE";
    let data = funzioni.buildDichiarazioneServer(dich);
    if (dich.testoNotaGiustificativa != "") {
    data.note.push({
      id: null, 
      testo: dichiarazione.testoNotaGiustificativa,
      tipologiaNota : 3
    });
    }
    salvaDichiarazione(data).then(()=>{
    setMostraModaleRigettaCdr(false);
    setModificaInCorso(false);
    Promise.all([chiamataCaricaDichiarazione(), chiamataRiepilogoMesi()]);
    setMessageToast({
      text: "Dichiarazione rigettata con successo!",
      isVisible: true,
      type: TOAST_SUCCESS,
    });
    }).catch((err) => {
      if (err) {
        setMessageToast({
          text: "Errore nel rigettare la dichiarazione",
          isVisible: true,
          type: TOAST_DANGER
        });
      }
    });
  }

  const inviaCdr = () => {
    let dich = {...dichiarazione};
    dich.stato = "REVISIONE_CDR";
    let data = funzioni.buildDichiarazioneServer(dich);
    if (dich.testoNotaGiustificativa != "") {
    data.note.push({
      id: null, 
      testo: dichiarazione.testoNotaGiustificativa,
      tipologiaNota : 4
    });
    }
    salvaDichiarazione(data).then(()=>{
    setMostraModaleRigettaCdr(false);
    setModificaInCorso(false);
    Promise.all([chiamataCaricaDichiarazione(), chiamataRiepilogoMesi()]);
    setMessageToast({
      text: "Dichiarazione reinviata al CDR con successo!",
      isVisible: true,
      type: TOAST_SUCCESS,
    });
    }).catch((err) => {
      if (err) {
        setMessageToast({
          text: "Errore nel reinvio della dichiarazione",
          isVisible: true,
          type: TOAST_DANGER
        });
      }
    });
  }

  const riapri = () => {
    let dich = {...dichiarazione};
    dich.stato = "DA_VERIFICARE";
    let data = funzioni.buildDichiarazioneServer(dich);
    if (dich.testoNotaGiustificativa != "") {
    data.note.push({
      id: null, 
      testo: dichiarazione.testoNotaGiustificativa,
      tipologiaNota : 5
    });
    }
    salvaDichiarazione(data).then(()=>{
    setModificaInCorso(false);
    Promise.all([chiamataCaricaDichiarazione(), chiamataRiepilogoMesi()]);
    }).catch((err) => {
      if (err) {
        setMessageToast({
          text: "Errore nella riapertura della dichiarazione",
          isVisible: true,
          type: TOAST_DANGER
        });
      }
    });
  }

  const rimuoviAllegato = (indiceAllegato) => {
    let dich = {...dichiarazione};
    dich.allegati.splice(indiceAllegato, 1);
    setDichiarazione(dich);
    setModificaInCorso(true);
  }

 const renderTrasfMateriaPrima = () => {
    if (dichiarazione.trasferimentiMateriaPrima!=null && dichiarazione.trasferimentiMateriaPrima.length>0) {
      return dichiarazione.trasferimentiMateriaPrima.map((el, i) => {
        return (
          <div className="col-md-6" key={ "trasf" + i }>
            <div className="internal-box">
              <div className="row">
                <div className="col-sm-12"><div className="numero-card trasferimenti">{i+1}</div></div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>CDR</label>
                    {listaCdrPerTrasferimenti !== null ? listaCentriDiRecuperoTrasferimentoACdr(i) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Mat. Corepla non lavorato avviato ad altro CDR (3)</label>
                    <input type="number" className="form-control" style={{textAlign: "right"}} placeholder="" min="0" value={ funzioni.checkInputNull(el.matAvviatoCdr)  } readOnly={solaLettura} onChange={(e) => {
                      aggiornaTrasferimentoACdr("matAvviatoCdr", e.target.value, i);}} />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Uscite di CSS/CDR (10)</label>
                    <input type="number" className="form-control" style={{textAlign: "right"}} placeholder="" min="0" value={ funzioni.checkInputNull(el.usciteCssCdr)  } readOnly={solaLettura} onChange={(e) => {
                      aggiornaTrasferimentoACdr("usciteCssCdr", e.target.value, i);}} />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Uscite di CSS-C (11)</label>
                    <input type="number" className="form-control" style={{textAlign: "right"}} placeholder="" min="0" value={ funzioni.checkInputNull(el.usciteCssC)  } readOnly={solaLettura} onChange={(e) => {
                      aggiornaTrasferimentoACdr("usciteCssC", e.target.value, i);}} />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Uscite di scarti di processo (13)</label>
                    <input type="number" className="form-control" style={{textAlign: "right"}} placeholder="" min="0" value={ funzioni.checkInputNull(el.usciteScarti)  } readOnly={solaLettura} onChange={(e) => {
                      aggiornaTrasferimentoACdr("usciteScarti", e.target.value, i);}}/>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Uscite di materiale "non conforme" (14)</label>
                    <input type="number" className="form-control" style={{textAlign: "right"}} placeholder="" min="0" value={ funzioni.checkInputNull(el.usciteNc)  } readOnly={solaLettura} onChange={(e) => {
                      aggiornaTrasferimentoACdr("usciteNc", e.target.value, i);}} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="box-footer col-md-12">
                  {!solaLettura ? <button className="btn" onClick={ () => rimuoviTrasferimentoMateriaPrima(el, i)}><Trash /></button>:null}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    return (
      <div className="col-md-12">
        {listaCdrPerTrasferimenti!=null &&listaCdrPerTrasferimenti.length > 0 ?
          <><p>{dichiarazione.trasferimentiMateriaPrima != undefined ? dichiarazione.trasferimentiMateriaPrima.length == 0 ?
              "Nessun trasferimento materia prima al momento presente" : dichiarazione.trasferimentiMateriaPrima : null}</p></>
        :"Nessun centro di recupero a cui poter inviare materia prima"}
      </div>
    );
  }

const renderUsciteConDestino = () => {
    if (dichiarazione.usciteConDestino!=null && dichiarazione.usciteConDestino.length>0) {
      return dichiarazione.usciteConDestino.map((el, i) => {
        return (
          <div className="col-md-6" key={ "trasf" + i }>
            <div className="internal-box">
              <div className="row">
                <div className="col-sm-12"><div className="numero-card uscite">{i+1}</div></div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Impianto</label>
                    {listaImpianti !== null ? listaImpiantiDiDestinazione(i) : "Impianti non trovati"}
                  </div>
                </div>
                <div className="col-sm-6">
                <div className="form-group">
                  <label>Tipo impianto</label>
                  <p> {listaImpianti != null && el.idImpiantoDest!=null? getNomeTipoImpianto(el.idImpiantoDest):"N.A."} </p>
                </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Uscite di CSS/CDR (10)</label>
                    <input type="number" className="form-control" style={{textAlign: "right"}} placeholder="" min="0" value={ funzioni.checkInputNull(el.usciteCssCdr)  } readOnly={solaLettura} onChange={(e) => {
                      aggiornamentoUsciteConDestino("usciteCssCdr", e.target.value, i);}} />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Uscite di CSS-C (11)</label>
                    <input type="number" className="form-control" style={{textAlign: "right"}} placeholder="" min="0" value={ funzioni.checkInputNull(el.usciteCssC)  } readOnly={solaLettura} onChange={(e) => {
                      aggiornamentoUsciteConDestino("usciteCssC", e.target.value, i);}}  />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Uscite di scarti di processo (13)</label>
                    <input type="number" className="form-control" style={{textAlign: "right"}} placeholder="" min="0" value={ funzioni.checkInputNull(el.usciteScarti)  } readOnly={solaLettura} onChange={(e) => {
                      aggiornamentoUsciteConDestino("usciteScarti", e.target.value, i);}} />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Uscite di materiale "non conforme" (14)</label>
                    <input type="number" className="form-control" style={{textAlign: "right"}} placeholder="" min="0" value={ funzioni.checkInputNull(el.usciteNc)  } readOnly={solaLettura} onChange={(e) => {
                      aggiornamentoUsciteConDestino("usciteNc", e.target.value, i);}} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="box-footer col-md-12">
                  {!solaLettura ? <button className="btn" onClick={ () => rimuoviUsciteConDestino(el, i)} disabled={solaLettura}><Trash /></button>:null}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    return (
      <div className="col-md-12">
        {listaImpianti != null && listaImpianti.length > 0 ? <><p>{dichiarazione.usciteConDestino != undefined ? dichiarazione.usciteConDestino.length == 0 ? "Nessuna uscita con destino presente" : dichiarazione.usciteConDestino:null}</p></>: "Nessun impianto a cui poter inviare materia prima"}
          
      </div>
    );
  };

  const cdrFilter = () => {
    if (listaCdr!=null) {
      if (!isCaricaDichiarazioneBottone) {
        return listaCentriDiRecupero();
      } else {
        return dichiarazione.cdrNome;
      }
    }
    return "";
  }

  if (dichiarazione!=null) {
    return (
      <>
        <div key="dichiarazione" className="container-fluid">
          <div className="cor-card">
            <div className="cor-card-header">
              <h4>Gestione dichiarazioni Recupero Energetico</h4>
            </div>
            <div className="cor-card-body">
              <div className="fixed-header sticky-top">
                <div className="row dich-summary">
                  <div className="col-md-3">
                    < div className="selection-panel" style={{ display: "block" }}>
                      <strong>CDR</strong>: &nbsp;{isCaricaDichiarazioneBottone ? <button className="btn freccia" onClick={()=>{reset();}}><ArrowLeft/></button>:null}
                      <div>{ cdrFilter() }</div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="selection-panel" style={{ display: "block"}}>
                      <strong>Mese</strong>:
                      <div>
                        {
                       !isCaricaDichiarazioneBottone ?
                       listaMesi() :
                       moment().month(dichiarazione.mese-1).format("MMMM").toUpperCase()
                        }
                       </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="selection-panel" style={{ display: "block" }}>
                      <strong>Anno</strong>: &nbsp;{isCaricaDichiarazioneBottone ? <button className="btn freccia" onClick={()=>{reset();}}><ArrowLeft/></button>:null}
                      <div>{
                       !isCaricaDichiarazioneBottone ?
                       listaAnni() :
                       moment().year(dichiarazione.anno).format("YYYY").toUpperCase()}</div>
                    </div>
                  </div>
                  {!isCaricaDichiarazioneBottone ?
                  <div className="col-md-4" style={{ "textAlign": "right"}}>
                   <button className="btn btn-primary" onClick={()=>{caricaDichiarazioneBottone();}}>CARICA DICHIARAZIONE</button>&nbsp;
                  </div>
                  :
                  null}
                  {isCaricaDichiarazioneBottone ?
                  <div className="col-md-4">
                    <strong>Stato di lavorazione</strong>:
                    <div>{ dichiarazione.stato == null ? "Nuova Dichiarazione" : getStatusDesc(dichiarazione.stato) }</div>
                  </div>
                  : null}
                </div>
                <div className="row">
                  {isCaricaDichiarazioneBottone ?
                  <>
                    <div className="col-md-8">
                      <div className="dichiarazioneNavigator">
                      {listaRiepilogoMesi !== null ? listaRiepilogoMesi.map((lm) => {
                        const gestioneClassName = () => {
                          if (meseCorrente < lm.mese && dichiarazione.anno == annoCorrente){
                            return "bloccati"
                          }
                        }
                        return (
                          <div key={ lm.mese } className={ (lm.mese==dichiarazione.mese?"sel-button":"") + " " + (lm.stato!=null?"tab-mese-" + lm.stato:dichiarazione.stato)+" "+gestioneClassName() }>
                          <button className="btn" onClick={ () => dichiarazioneLoad(lm.mese)}>
                          { moment().month(lm.mese-1).format("MMM").toUpperCase() }
                          </button>
                          </div>
                        );
                      }) : null}
                    </div>
                    </div></>:null}
                    <div className="col-md-4" style={{ "textAlign": "right", "paddingTop": "15px" }}>
                      {isCaricaDichiarazioneBottone && !isAmministratore && dichiarazione.stato == "VERIFICATA" ? <div className="print btn btn-primary"><a target="_blank" href={`${RECUPERO_API}/dichiarazione/print/${dichiarazione.cdrId}/${dichiarazione.mese}/${dichiarazione.anno}?_a=${getAccessTokenCookie()}`} rel="noreferrer"><Printer/></a></div>:null}&nbsp;
                    {isCaricaDichiarazioneBottone ?
                      !isAmministratore ?
                        dichiarazione.stato == "NUOVA" || dichiarazione.stato == "REVISIONE_CDR" || dichiarazione.stato == null ?
                          <>
                            <button className="btn btn-primary" onClick={()=>{salva();}}>SALVA</button>&nbsp;
                            <button className="btn btn-primary" onClick={()=>{conferma();}}>CONFERMA</button>
                          </>
                          :dichiarazione.stato == "VERIFICATA" ?
                          <>
                            <button className="btn btn-primary" onClick={()=>{setMostraModaleApprovaCdr(true);}}>APPROVA</button>&nbsp;
                            <button className="btn btn-primary" onClick={()=>{setMostraModaleRigettaCdr(true);}}>RIGETTA</button>
                          </>                          
                          :null
                      :dichiarazione.stato == "DA_VERIFICARE" ?
                        <>
                          <button className="btn btn-primary" onClick={()=>{setMostraModaleInviaCdrDaRec(true);}}>INVIA A CDR</button>&nbsp;
                          <button className="btn btn-primary" onClick={()=>{salva();}}>SALVA</button>&nbsp;
                          <button className="btn btn-primary" disabled={dichiarazione.nc !== null && dichiarazione.nc.statoNc == "NONE" && dichiarazione.matNonConforme > 0 ? true:false} onClick={()=>{validaOppureValidaForzatamente();}}>CONFERMA</button>
                        </>
                        :dichiarazione.stato == "APPROVATA" && dichiarazione.hasRecout == false ?
                          <>
                            <button className="btn btn-primary" onClick={()=>{setMostraModaleRiapriRec(true);}}>RIAPRI</button>&nbsp;
                          </>
                        :null
                    :null}
                    </div>
                </div>
              </div>
              {isCaricaDichiarazioneBottone ? <div className="corpo-scheda">
              <div className="row dich-section">
                <div className="col-md-6">
                  <h6>Dati generali</h6>
                  <div className="form-group">
                    <label>Data della dichiarazione</label>
                    {calendarioDataDellaDichiarazione()} </div>
                  <div className="form-group">
                    <button style={{float: "right"}} type="button" className="btn btn-primary" onClick={() => {
                      storicoNote();
                      setMostraModaleStoricoNote(true);}}>Storico Note <JournalText style={{margin:"0 0 0 2px"}} /></button>
                    <h6>Note </h6>
                    {!solaLettura ? <textarea className="form-control" value={dichiarazione.testoNota != null ? dichiarazione.testoNota:""} onChange={(e)=> aggiungiNota(e.target.value)}></textarea>:null}
                  </div>
                  <h6>Allegati
                  {!solaLettura ?<button type="button" className="btn" onClick={() => setMostraModale(true)}>
                    <PlusCircle />
                  </button>:null}
                  </h6>
                  <ModaleAllegato
                  isOpen={mostraModale}
                  hasprogressbar={true}
                  onAllegatoAdd={(all) => {
                    let dich = {...dichiarazione};
                    if (dich.allegati == null) {
                      dich.allegati = [];}
                    dich.allegati.push(all);
                    setDichiarazione(dich);
                    setMostraModale(false);
                    setModificaInCorso(true);
                  }}
                  onExit={() => setMostraModale(false)}/>
                { storicoAllegati() }
                </div>
                <div className="col-md-6">
                {dichiarazione.nc !== null ? 
                  !isAmministratore ? 
                      <>
                        <h6> {dichiarazione.nc?.statoNc == "NON_APPROVATA" ? "C'è un quantitativo di materia non conforme da gestire": "Visualizza la non conformità"}  </h6>
                        <button className="btn btn-primary" onClick={ () => {
                          setMostraModaleMaterialeNonConformeCdr(true);
                          }}>Vedi i dettagli
                        </button>
                      </>
                  :
                    <>
                      <h6> {dichiarazione.nc?.statoNc == "NON_APPROVATA" ? "Visualizza la non conformità": "C'è un quantitativo di materia non conforme da gestire"} </h6>
                      <button className="btn btn-primary" onClick={ () => {
                        setMostraModaleMaterialeNonConformeRec(true);
                        }}>Vedi i dettagli
                      </button>
                    </>
                :null}
                  <h6>Controlli di processo</h6>
                  <table className="table dich-controlli-table">
                    <tbody className="tbody-controlli-processo">
                      <tr>
                        <td>Stato Validità: </td>
                        <td><b>{dichiarazione.statoValidita == "NONE" ? getStatusValidityDesc(coloriControlliDiProcesso.__stato_validita): getStatusValidityDesc(dichiarazione.statoValidita)}</b></td>
                      </tr>
                        <tr>
                          <td>Perdita di processo <InfoCircle id="idPerditaDiProcesso"/><UncontrolledTooltip
                          target="idPerditaDiProcesso"
                          placement="right"
                          fade={true}>{INFO_CONTROLLI["perditaDiProcesso"]}</UncontrolledTooltip></td>
                          {controlli.renderColorePallino(dichiarazione.stato, dichiarazione.statoValidita, dichiarazione.statoControlli, coloriControlliDiProcesso.colore_PerditaDiProcesso, 0)}
                        </tr>
                          <tr>
                            <td>Scarti di processo <InfoCircle id="idScartiDiProcesso"/><UncontrolledTooltip
                            target="idScartiDiProcesso"
                            placement="right"
                            fade={true}>{`Lo scarto di processo non deve essere superiore al ${percentualePerditaProcesso*100}% del materiale entrato in produzione [8 ≤ ${percentualePerditaProcesso*100}% * (5+6+7+8)]`}</UncontrolledTooltip></td>
                            {controlli.renderColorePallino(dichiarazione.stato, dichiarazione.statoValidita, dichiarazione.statoControlli, coloriControlliDiProcesso.colore_ScartiDiProcesso, 1)}
                          </tr>
                      <tr>
                        <td>Giacenza ricezione <InfoCircle id="idGiacenzaRicezione"/><UncontrolledTooltip
                        target="idGiacenzaRicezione"
                        placement="right"
                        fade={true}>{INFO_CONTROLLI["giacenzaRicezione"]}</UncontrolledTooltip></td>
                        {controlli.renderColorePallino(dichiarazione.stato, dichiarazione.statoValidita, dichiarazione.statoControlli, coloriControlliDiProcesso.colore_GiacenzaRicezione, 2)}
                      </tr>
                      <tr>
                        <td>Giacenza “Materiale non conforme” <InfoCircle id="idMaterialeNonConforme"/><UncontrolledTooltip
                        target="idMaterialeNonConforme"
                        placement="right"
                        fade={true}>{INFO_CONTROLLI["giacenzaMaterialeNonConforme"]}</UncontrolledTooltip></td>
                        {controlli.renderColorePallino(dichiarazione.stato, dichiarazione.statoValidita, dichiarazione.statoControlli, coloriControlliDiProcesso.colore_GiacenzaMatNonConforme, 3)}
                      </tr>
                      <tr>
                        <td>Giacenza “CSS/CDR” <InfoCircle id="idGiacenzaCssCdr"/><UncontrolledTooltip
                        target="idGiacenzaCssCdr"
                        placement="right"
                        fade={true}>{INFO_CONTROLLI["giacenzaCssCdr"]}</UncontrolledTooltip></td>
                        {controlli.renderColorePallino(dichiarazione.stato, dichiarazione.statoValidita, dichiarazione.statoControlli, coloriControlliDiProcesso.colore_GiacenzaCssCdr, 4)}
                      </tr>
                      <tr>
                        <td>Giacenza “CSS-C” <InfoCircle id="idGiacenzaCssC"/><UncontrolledTooltip
                        target="idGiacenzaCssC"
                        placement="right"
                        fade={true}>{INFO_CONTROLLI["giacenzaCssC"]}</UncontrolledTooltip></td>
                        {controlli.renderColorePallino(dichiarazione.stato, dichiarazione.statoValidita, dichiarazione.statoControlli, coloriControlliDiProcesso.colore_GiacenzaCssC, 5)}
                      </tr>
                      <tr>
                        <td>Giacenza “Metalli separati” <InfoCircle id="idMetalliSeparati"/><UncontrolledTooltip
                        target="idMetalliSeparati"
                        placement="right"
                        fade={true}>{INFO_CONTROLLI["giacenzaMetalliSeparati"]}</UncontrolledTooltip></td>
                        {controlli.renderColorePallino(dichiarazione.stato, dichiarazione.statoValidita, dichiarazione.statoControlli, coloriControlliDiProcesso.colore_GiacenzaMetalliSeparati, 6)}
                      </tr>
                      <tr>
                        <td>Giacenza “Scarti di processo” <InfoCircle id="idGiacenzaScartiDiProcesso"/><UncontrolledTooltip
                        target="idGiacenzaScartiDiProcesso"
                        placement="right"
                        fade={true}>{INFO_CONTROLLI["giacenzaScartiProcesso"]}</UncontrolledTooltip></td>
                        {controlli.renderColorePallino(dichiarazione.stato, dichiarazione.statoValidita, dichiarazione.statoControlli, coloriControlliDiProcesso.colore_GiacenzaScartiDiProcesso, 7)}
                      </tr>
                      <tr>
                        <td>Giacenza “Avvio a Riciclo” <InfoCircle id="idGiacenzaAvvioRiciclo"/><UncontrolledTooltip
                        target="idGiacenzaAvvioRiciclo"
                        placement="right"
                        fade={true}>{INFO_CONTROLLI["giacenzaAvvioRiciclo"]}</UncontrolledTooltip></td>
                        {controlli.renderColorePallino(dichiarazione.stato, dichiarazione.statoValidita, dichiarazione.statoControlli, coloriControlliDiProcesso.colore_GiacenzaAvvioRiciclo, 8)}
                      </tr>
                      <tr>
                        <td>Giacenza “Auto Riciclo” <InfoCircle id="idGiacenzaAutoRiciclo"/><UncontrolledTooltip
                        target="idGiacenzaAutoRiciclo"
                        placement="right"
                        fade={true}>{INFO_CONTROLLI["giacenzaAutoRicilo"]}</UncontrolledTooltip></td>
                        {controlli.renderColorePallino(dichiarazione.stato, dichiarazione.statoValidita, dichiarazione.statoControlli, coloriControlliDiProcesso.colore_GiacenzaAutoRiciclo, 9)}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row dich-section">
                <div className="col-md-6">
                  <h6>Ingressi Materia Prima (Kg)</h6>
                  <table className="table dich-controlli-table">
                    <tbody>
                      <tr>
                        <td>Ingressi Corepla da Centri di Selezione Corepla <label>(1)</label></td>
                        <td><input type="text" className="form-control table-input" value={ funzioni.checkInputNull(dichiarazione.ingressiMateriaCss) } readOnly /></td>
                        <td style={{ "width": "200px" }}>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.ingressiMateriaCss != null ? dichiarazionePrecedente.ingressiMateriaCss:"N.A."}</td>
                      </tr>
                      <tr>
                        <td>Ingressi Corepla da altri CDR <label>(2)</label></td>
                        <td><input type="text" className="form-control table-input" value={ funzioni.checkInputNull(dichiarazione.ingressiMateriaCdr) } readOnly /></td>
                        <td>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.ingressiMateriaCdr != null ? dichiarazionePrecedente.ingressiMateriaCdr:"N.A."}</td>
                      </tr>
                      <tr>
                        <td>CSS/CDR già preparato entrato da altri CDR e non messo in lavorazione <label>(2a)</label></td>
                        <td><input type="text" className="form-control table-input" value={ funzioni.checkInputNull(dichiarazione.ingressiMateriaCssCdr) } readOnly /></td>
                        <td>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.ingressiMateriaCssCdr != null ? dichiarazionePrecedente.ingressiMateriaCssCdr:"N.A."}</td>
                      </tr>
                      <tr>
                        <td>CSS-C già preparato entrato da altri CDR e non messo in lavorazione <label>(2b)</label></td>
                        <td><input type="text" className="form-control table-input" value={ funzioni.checkInputNull(dichiarazione.ingressiMateriaCssC) } readOnly /></td>
                        <td>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.ingressiMateriaCssC != null ? dichiarazionePrecedente.ingressiMateriaCssC:"N.A."}</td>
                      </tr>
                      <tr>
                        <td>Scarti di processo entrati da altri CDR e non messi in lavorazione <label>(2c)</label></td>
                        <td><input type="text" className="form-control table-input" value={ funzioni.checkInputNull(dichiarazione.scartiProcessoNoLav) } readOnly /></td>
                        <td>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.scartiProcessoNoLav != null ? dichiarazionePrecedente.scartiProcessoNoLav:"N.A."}</td>
                      </tr>
                      {parametroAbilitato["15_INGRESSI_EXTRA_COREPLA"] ? 
                        <>
                          <tr>
                            <td>Ingressi extra-Corepla, soli CER di pertinenza Corepla <label>(15)</label></td>
                            <td><input type="number" className="form-control table-input" min="0" value={ funzioni.checkInputNull(dichiarazione.ingressiExtraCorepla)  } readOnly={solaLettura} onChange={(e) => {
                            aggiornamentoGenericoInNumero("ingressiExtraCorepla", e.target.value);}} /></td>
                            <td>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.ingressiExtraCorepla != null ? dichiarazionePrecedente.ingressiExtraCorepla:"N.A."}</td>
                          </tr>
                        </>
                      :null}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-6">
                  <h6>Lavorazione (Kg)</h6>
                  <table className="table dich-controlli-table">
                    <tbody>
                      {parametroAbilitato["04_MATERIALE_NON_CONFORME"] ? 
                        <>
                          <tr>
                            <td>Materiale non conforme <label>(4)</label></td>
                            <td>
                              <input type="number" className="form-control table-input" min="0" value={funzioni.checkInputNull(dichiarazione.matNonConforme)} readOnly={funzioni.isSolaLettura(isAmministratore, dichiarazione.stato, dichiarazione.matNonConforme, dichiarazione.nc?.statoNc)} onChange={(e) => {
                            aggiornamentoGenericoInNumero("matNonConforme", e.target.value);}} />
                            </td>
                            <td style={{ "width": "200px" }}>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.matNonConforme != null ? dichiarazionePrecedente.matNonConforme:"N.A."}</td>
                          </tr>
                        </>
                      :null}
                      {parametroAbilitato["05_CSS_CDR_PRODOTTO"] ? 
                        <>
                          <tr>
                            <td>CSS/CDR prodotto <label>(5)</label></td>
                            <td><input type="number" className="form-control table-input" min="0" value={ funzioni.checkInputNull(dichiarazione.prodottoCssCdr)  } readOnly={solaLettura} onChange={(e) => {
                            aggiornamentoGenericoInNumero("prodottoCssCdr", e.target.value);}} /></td>
                            <td>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.prodottoCssCdr != null ? dichiarazionePrecedente.prodottoCssCdr:"N.A."}</td>
                          </tr>
                        </>
                      :null}
                      {parametroAbilitato["06_CSS_C_PRODOTTO"] ? 
                        <>
                          <tr>
                            <td>CSS-C prodotto <label>(6)</label></td>
                            <td><input type="number" className="form-control table-input" min="0" value={ funzioni.checkInputNull(dichiarazione.prodottoCssC)  } readOnly={solaLettura} onChange={(e) => {
                            aggiornamentoGenericoInNumero("prodottoCssC", e.target.value);}} /></td>
                            <td>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.prodottoCssC != null ? dichiarazionePrecedente.prodottoCssC:"N.A."}</td>
                          </tr>
                        </>
                      :null}
                      {parametroAbilitato["07_METALLI_SEPARATI"] ? 
                        <>
                          <tr>
                            <td>Metalli separati <label>(7)</label></td>
                            <td><input type="number" className="form-control table-input" min="0" value={ funzioni.checkInputNull(dichiarazione.metalliSeparati)  } readOnly={solaLettura} onChange={(e) => {
                            aggiornamentoGenericoInNumero("metalliSeparati", e.target.value);}} /></td>
                            <td>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.metalliSeparati != null ? dichiarazionePrecedente.metalliSeparati:"N.A."}</td>
                          </tr>
                        </>
                      :null}
                      {parametroAbilitato["08_SCARTI_DI_PROCESSO"] ?
                        <>
                          <tr>
                            <td>Scarti di processo <label>(8)</label></td>
                            <td><input type="number" className="form-control table-input" min="0" value={ funzioni.checkInputNull(dichiarazione.scartiProcesso)  } readOnly={solaLettura} onChange={(e) => {
                            aggiornamentoGenericoInNumero("scartiProcesso", e.target.value);}} /></td>
                            <td>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.scartiProcesso != null ? dichiarazionePrecedente.scartiProcesso:"N.A."}</td>
                          </tr>
                        </>
                      :null}
                      {parametroAbilitato["09_PERDITE_DI_PROCESSO"] ? 
                        <>
                          <tr>
                            <td>Perdite di processo <label>(9)</label></td>
                            <td><input type="number" className="form-control table-input" min="0" value={ funzioni.checkInputNull(dichiarazione.perditeProcesso) } readOnly={solaLettura} onChange={(e) => {
                            aggiornamentoGenericoInNumero("perditeProcesso", e.target.value);}} /></td>
                            <td>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.perditeProcesso != null ? dichiarazionePrecedente.perditeProcesso:"N.A."}</td>
                          </tr>
                        </>
                      :null}
                      {parametroAbilitato["17_AVVIO_A_RICICLO"] ? 
                        <>
                          <tr>
                            <td>Avvio a riciclo <label>(17)</label></td>
                            <td><input type="number" className="form-control table-input" min="0" value={ funzioni.checkInputNull(dichiarazione.avvioRiciclo)  } readOnly={solaLettura} onChange={(e) => {
                            aggiornamentoGenericoInNumero("avvioRiciclo", e.target.value);}} /></td>
                            <td>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.avvioRiciclo != null ? dichiarazionePrecedente.avvioRiciclo:"N.A."}</td>
                          </tr>
                        </>
                      :null}
                      {parametroAbilitato["18_AUTO_RICICLO"] ?
                        <>
                          <tr>
                            <td>Auto riciclo <label>(18)</label></td>
                            <td><input type="number" className="form-control table-input" min="0" value={ funzioni.checkInputNull(dichiarazione.autoRiciclo)  } readOnly={solaLettura} onChange={(e) => {
                            aggiornamentoGenericoInNumero("autoRiciclo", e.target.value);}} /></td>
                            <td>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.autoRiciclo != null ? dichiarazionePrecedente.autoRiciclo:"N.A."}</td>
                          </tr>
                        </>
                      :null}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row dich-section">
                <div className="col-md-12">
                  <h6>Trasferimento a CDR (Kg)
                    {listaCdrPerTrasferimenti!=null &&listaCdrPerTrasferimenti.length > 0 ? <>{!solaLettura ? <button className="btn" onClick={ () => {
                    addTrasferimentoMateriaPrima()
                    }}><PlusCircle />
                    </button>:null}</>:null}
                    </h6>
                </div>
                  { renderTrasfMateriaPrima() }
              </div>
              <div className="row dich-section">
                <div className="col-md-6">
                  <h6>Uscite senza destino (Kg)</h6>
                  <table className="table dich-controlli-table">
                    <tbody>
                      {parametroAbilitato["12_USCITE_METALLI_COREPLA"] ? 
                        <>
                          <tr>
                            <td>Uscite di metalli da lavorazioni flussi Corepla <label>(12)</label></td>
                            <td><input type="number" className="form-control table-input" min="0" value={ funzioni.checkInputNull(dichiarazione.usciteNoDestMetalli)  } readOnly={solaLettura} onChange={(e) => {
                            aggiornamentoGenericoInNumero("usciteNoDestMetalli", e.target.value);}} /></td>
                            <td style={{ "width": "200px" }}>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.usciteNoDestMetalli != null ? dichiarazionePrecedente.usciteNoDestMetalli:"N.A."}</td>
                          </tr>
                        </>
                      :null}
                      {parametroAbilitato["20_USCITE_AUTO_RICICLO"] ?
                        <>
                          <tr>
                            <td>Uscite auto-riciclo <label>(20)</label></td>
                            <td><input type="number" className="form-control table-input" min="0" value={ funzioni.checkInputNull(dichiarazione.usciteAutoRiciclo)  } readOnly={solaLettura} onChange={(e) => {
                            aggiornamentoGenericoInNumero("usciteAutoRiciclo", e.target.value);}} /></td>
                            <td>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.usciteAutoRiciclo != null ? dichiarazionePrecedente.usciteAutoRiciclo:"N.A."}</td>
                          </tr>
                        </>
                      :null}
                      {parametroAbilitato["19_USCITE_AVVIO_A_RICICLO"] ? 
                        <>
                          <tr>
                            <td>Uscite avvio a riciclo <label>(19)</label></td>
                            <td><input type="number" className="form-control table-input" min="0" value={ funzioni.checkInputNull(dichiarazione.usciteAvvioRiciclo)  } readOnly={solaLettura} onChange={(e) => {
                            aggiornamentoGenericoInNumero("usciteAvvioRiciclo", e.target.value);}} /></td>
                            <td>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.usciteAvvioRiciclo != null ? dichiarazionePrecedente.usciteAvvioRiciclo:"N.A."}</td>
                          </tr>
                        </>
                      :null}
                      {parametroAbilitato["16_USCITE_EXTRA_COREPLA"] ? 
                        <>
                          <tr>
                            <td>Uscite extra Corepla, solo CER di pertinenza Corepla <label>(16)</label></td>
                            <td><input type="number" className="form-control table-input" min="0" value={ funzioni.checkInputNull(dichiarazione.usciteExtraCorepla)  } readOnly={solaLettura} onChange={(e) => {
                            aggiornamentoGenericoInNumero("usciteExtraCorepla", e.target.value);}} /></td>
                            <td>Valore precedente: {dichiarazionePrecedente && dichiarazionePrecedente.usciteExtraCorepla != null ? dichiarazionePrecedente.usciteExtraCorepla:"N.A."}</td>
                          </tr>
                        </>
                      :null}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row dich-section">
                <div className="col-md-12">
                  <h6>Uscite con destino (Kg)
                    {listaImpianti != null && listaImpianti.length > 0 ?<>{!solaLettura ? <button className="btn" onClick={ () => {
                    addUscitaConDestino();
                  }}><PlusCircle /></button>:null}</>:null}
                  </h6>
                </div>
                { renderUsciteConDestino() }
              </div>
            </div>: null}
          </div>
          </div>
          <Toast isVisible={ messageToast.isVisible } type={messageToast.type} text={ messageToast.text } onClose={ () => {
            setMessageToast({
              text: "",
              isVisible: false,
              type: ""
            });
          } }></Toast>
          <ModaleModificaInCorso
          isOpen={mostraModaleModificaInCorso}
          dichiarazione={() => vaiAChiamataCaricaDichiarazione()}
          onExit={() => setMostraModaleModificaInCorso(false)}/>
          <ModaleValidaRec
          isOpen={mostraModaleValidaRec}
          testo= {dichiarazione.testoNotaGiustificativa}
          scritturaNota = {(testoNota) => {
            aggiungiNotaGiustificativa(testoNota)}}
          conferma = {()=>{
            valida();
            setMostraModaleValidaRec(false);}}
          onExit={() => setMostraModaleValidaRec(false)}/>
          <ModaleValidaForzatamenteRec
          isOpen={mostraModaleValidaForzatamenteRec}
          testo= {dichiarazione.testoNotaGiustificativa}
          scritturaNota = {(testoNota) => {
            aggiungiNotaGiustificativa(testoNota)}}
          conferma = {()=>{
            validaForzatamente();
            setMostraModaleValidaForzatamenteRec(false);}}
          onExit={() => setMostraModaleValidaForzatamenteRec(false)}/>
          <ModaleStoricoNote
          isOpen={mostraModaleStoricoNote}
          storico = {()=>storicoNote()}
          onExit={() => setMostraModaleStoricoNote(false)}/>
          <ModaleApprovaCdr
          isOpen={mostraModaleApprovaCdr}
          hasprogressbar={true}
          testo={dichiarazione.testoNotaGiustificativa}
          scritturaNota = {(testoNota) => {
            aggiungiNotaGiustificativa(testoNota)}}
          onApprova={() => {
            approva();
            setMostraModaleApprovaCdr(false);
          }}
          onExit={() => setMostraModaleApprovaCdr(false)}/>
          <ModaleRigettaCdr
          isOpen={mostraModaleRigettaCdr}
          testo= {dichiarazione.testoNotaGiustificativa}
          scritturaNota = {(testoNota) => {
            aggiungiNotaGiustificativa(testoNota)}}
          conferma = {()=>{
            rigetta();
            setMostraModaleRigettaCdr(false);}}
          onExit={() => setMostraModaleRigettaCdr(false)}/>
          <ModaleInviaCdrDaRec
          isOpen={mostraModaleInviaCdrDaRec}
          testo= {dichiarazione.testoNotaGiustificativa}
          scritturaNota = {(testoNota) => {
            aggiungiNotaGiustificativa(testoNota)}}
          conferma = {()=>{
            inviaCdr();
            setMostraModaleInviaCdrDaRec(false);}}
          onExit={() => setMostraModaleInviaCdrDaRec(false)}/>
        <ModaleRiapriRec
          isOpen={mostraModaleRiapriRec}
          testo= {dichiarazione.testoNotaGiustificativa}
          scritturaNota = {(testoNota) => {
            aggiungiNotaGiustificativa(testoNota)}}
          conferma = {()=>{
            riapri();
            setMostraModaleRiapriRec(false);}}
          onExit={() => setMostraModaleRiapriRec(false)}/>
          <ModaleMaterialeNonConformeCdr
          isOpen={mostraModaleMaterialeNonConformeCdr}
          hasprogressbar={true}
          api = {RECUPERO_API}
          cdrId = {dichiarazione.cdrId}
          token = {getAccessTokenCookie()}
          testo= {dichiarazione.testoNotaGiustificativa || dichiarazione.nc?.noteCdr}
          scritturaNota = {(testoNota) => {
            aggiungiNotaGiustificativa(testoNota)}}
          solaLettura = {dichiarazione.nc != null && (dichiarazione.nc.statoNc == "APPROVATA" || dichiarazione.nc.statoNc == "NONE")? true:false }
          dettagliNc = {dichiarazione.nc != null ? dichiarazione.nc:null }
          testoRifiutoRec = {dichiarazione.nc?.noteCorepla}
          qtaMateriale = {dichiarazione.nc?.materialeNcApprovato || dichiarazione.matNonConforme}
          modificaQtaMateriale = { (qtaNc) => {
            let dich = {...dichiarazione};
            if (dich.nc != null){
            dich.nc.materialeNcApprovato = Number(qtaNc);}else{
              dich.matNonConforme = Number(qtaNc);
            }
            setDichiarazione(dich);}}
          storicoAllegati = {dichiarazione.allegati}
          onAllegatoAdd={async (all) => {
            let dich = {...dichiarazione};
            if (dich.nc !== null && dich.nc.allegati !== null) {
            dich.nc.allegati.push(all);
            setDichiarazione(dich);
            nonConformita(dich.allegatiNc);
            setMostraModaleMaterialeNonConformeCdr(false);
            }else {
              dich.allegatiNc = [];
              dich.allegatiNc.push(all);
              setDichiarazione(dich);
              nonConformita(dich.allegatiNc);
              setMostraModaleMaterialeNonConformeCdr(false);
            }
          }}
          onExit={() => setMostraModaleMaterialeNonConformeCdr(false)}/>
          <ModaleMaterialeNonConformeRec
          isOpen={mostraModaleMaterialeNonConformeRec}
          api = {RECUPERO_API}
          cdrId = {dichiarazione.cdrId}
          token = {getAccessTokenCookie()}
          testo= {dichiarazione.nc?.noteCorepla}
          scritturaNota = {(testoNota) => {
            let dich = {...dichiarazione};
            dich.nc.noteCorepla = testoNota;
            setDichiarazione(dich);
            }}
          solaLettura = {dichiarazione.nc != null && dichiarazione.nc.statoNc !== "NONE"? true:false }
          dettagliNc = {dichiarazione.nc != null ? dichiarazione.nc:null }
          qtaOriginale = {dichiarazione.nc?.materialeNcOriginale}
          qtaMateriale = {dichiarazione.nc?.materialeNcApprovato || 0}
          modificaQtaMateriale = { (qtaNc) => {
            let dich = {...dichiarazione};
            dich.nc.materialeNcApprovato = Number(qtaNc);
            setDichiarazione(dich);}}
          storicoAllegati = {dichiarazione.allegati}
          approva = {()=>{
            approvaMatNoConfRec();
            setMostraModaleMaterialeNonConformeRec(false);}}
          rifiuta={() => {
            rifiutaMatNoConfRec();
            setMostraModaleMaterialeNonConformeRec(false);}}
          onExit={() => setMostraModaleMaterialeNonConformeRec(false)}/>
        </div>
      </>
    );
  }
}
