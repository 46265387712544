import { useState, useEffect } from "react";
import {
	X,
	HouseDoorFill,
	GearFill,
	TrashFill,
	PieChartFill,
	ConeStriped,
	PeopleFill,
	EnvelopeOpenFill,
} from "react-bootstrap-icons";
import { getAccessTokenCookie } from "../../../common/cookie";
import { PORTAL_URL } from "../../../common/env";
import "./SidebarStyle.scss";

export default function Sidebar({ open, items, roles = null }) {
	const [aperto, setAperto] = useState(open);
	const [sottoMenu, setSottoMenu] = useState(false);
	const [selezionato, setSelezionato] = useState(null);

	useEffect(() => {
		window.history.replaceState(null, null, "/");
	}, [open]);

	const mostraSottoMenu = (_idx = null) => {
		if (_idx != null) {
			setSelezionato(_idx);
		}
		setSottoMenu(!sottoMenu);
	};

	const renderIcone = (icona) => {
		switch (icona) {
			case "home":
				return <HouseDoorFill size={15} />;
			case "settings":
				return <GearFill size={15} />;
			case "drink":
				return <TrashFill size={15} />;
			case "blur-circular":
				return <PieChartFill size={15} />;
			case "truck":
				return <ConeStriped size={15} />;
			case "accounts-alt":
				return <PeopleFill size={15} />;
			case "email-open":
				return <EnvelopeOpenFill size={15} />;
			default:
				<X size={15} />;
				break;
		}
	};

	const controlloRuoli = (m) => {
		if (m.roles == null || m.roles == "") {
			return "";
		}

		if (roles != null && roles.ruoli?.length > 0 && (m.roles != null || m.roles != "")) {
			roles.ruoli.map((e) => {
				if (m.roles.indexOf(e.codiceRuolo)) {
					return "";
				} else {
					return "hidden";
				}
			});
		}
	};

	const indirizzoUrl = (url) => {
		if (url.startsWith("ext::")) {
			return `${url.split("ext::")[1]}?_a=${getAccessTokenCookie()}`;
		} else {
			return `${PORTAL_URL}/#/${url}`;
		}
	};

	const getChildren = (idParent) => {
		return items != null && items.length > 0 ? (
			<ul className={selezionato != null && selezionato == idParent ? "" : "hidden"}>
				{items.map((m, _idx) => {
					if (m.idParent == idParent) {
						return (
							<li key={_idx} className={"riga " + controlloRuoli(m)}>
								<a href={indirizzoUrl(m.dest)} className="voce-menu-sub">
									{m.nome}
								</a>
							</li>
						);
					}
				})}
			</ul>
		) : (
			""
		);
	};

	const renderMenu = (elemento, indice) => {
		return (
			<li
				key={indice}
				className={"riga " + (elemento.idParent != 0 ? "hidden" : "")}
				onClick={() => mostraSottoMenu(elemento.idMenu)}>
				<span>
					<div className="icona" />
					<a href={elemento.nome == "Home" ? PORTAL_URL : elemento.dest} className="voce-menu">
						{elemento.icona != null || elemento.icona != "" ? renderIcone(elemento.icona) : <X />}{" "}
						{elemento.nome}
					</a>
				</span>
				{getChildren(elemento.idMenu)}
			</li>
		);
	};

	return (
		<nav className={aperto ? "menu-laterale" : "hidden"}>
			{items != null && items.length > 0 ? (
				<>
					<ul className="menu">
						{items.map((m, _idx) => {
							if (m.idParent == 0) {
								return renderMenu(m, _idx);
							}
						})}
					</ul>
				</>
			) : (
				"Non ci sono voci menu"
			)}
		</nav>
	);
}
