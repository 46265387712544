import { Modal, ModalFooter, ModalHeader, ModalBody, Button } from "reactstrap";

export default function ModaleStoricoNote(props) {
	return (
		<Modal
			isOpen={props.isOpen}
			size="lg"
			role="dialog"
			aria-labelledby="contained-modal-title-vcenter"
			centered>
			<ModalHeader>
				<h3>Storico Note</h3>
			</ModalHeader>
			<ModalBody>
				<div className="note-tabella-wrapper">
					<table className="note-tabella">
						<thead>
							<tr>
								<th>Stato dichiarazione</th>
								<th>Testo</th>
								<th>Nome autore</th>
								<th>Tipo nota</th>
							</tr>
						</thead>
						<tbody>{props.storico()}</tbody>
					</table>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button onClick={props.onExit}>Chiudi</Button>
			</ModalFooter>
		</Modal>
	);
}
