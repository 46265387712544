export const controlloSceltaParametri = (oggetto) => {
	let risultato = {
		verificato: true,
		descrizioneErrore: "",
	};

	if (oggetto.idCdr == null || oggetto.idCdr == "") {
		risultato.verificato = false;
		risultato.descrizioneErrore = "Selezionare il CDR";
	}
	if (oggetto.tipoParametro == null || oggetto.tipoParametro == "") {
		risultato.verificato = false;
		risultato.descrizioneErrore = "Selezionare il parametro";
	}
	if (
		(oggetto.idCdr == null || oggetto.idCdr == "") &&
		(oggetto.tipoParametro == null || oggetto.tipoParametro == "")
	) {
		risultato.verificato = false;
		risultato.descrizioneErrore = "Selezionare CDR e parametro";
	}
	return risultato;
};

export const verificaMeseAnno = (oggetto) => {
	let risultato = {
		verificato: true,
		descrizioneErrore: "",
	};

	if (oggetto.meseInizio == null || oggetto.annoInizio == null) {
		risultato.verificato = false;
		risultato.descrizioneErrore = "Selezionare mese/anno di inizio validità";
	}

	if (oggetto.meseFine == null || oggetto.annoFine == null) {
		risultato.verificato = false;
		risultato.descrizioneErrore = "Selezionare mese/anno di fine validità";
	}

	if (
		Number(oggetto.annoInizio) > Number(oggetto.annoFine) &&
		Number(oggetto.annoInizio) != Number(oggetto.annoFine)
	) {
		risultato.verificato = false;
		risultato.descrizioneErrore =
			"L'anno di inizio validità non può essere maggiore dell'anno di fine validità";
	}

	if (
		Number(oggetto.annoInizio) == Number(oggetto.annoFine) &&
		Number(oggetto.meseInizio) > Number(oggetto.meseFine)
	) {
		risultato.verificato = false;
		risultato.descrizioneErrore =
			"Il mese di inizio validità non può essere maggiore del mese di fine validità";
	}

	return risultato;
};
