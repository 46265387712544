import React, { useEffect } from "react";

export const TOAST_SUCCESS = "success";
export const TOAST_DANGER = "danger";

export default function Toast(props) {
	useEffect(() => {
		if (props.isVisible) {
			setTimeout(() => {
				props.onClose();
			}, 2900);
		}
	}, [props]);

	return (
		<div
			id="toast-component"
			className={"toast-bar " + (props.isVisible ? "show " : "") + props.type}>
			{props.text}
		</div>
	);
}
