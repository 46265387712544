import React, { useContext, createContext, useState } from "react";

const Context = createContext();
let state;
let setState;

export const useCreateGlobalStateContext = (initialState) => {
  [state, setState] = useState(initialState);
  return GlobalStateContext;
};

const GlobalStateContext = ({ children }) => {
  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export const useGlobalStateContext = () => {
  return useContext(Context);
};

export const setGlobalState = (newState) => {
  setState((prevState) => {
    return { ...prevState, ...newState };
  });
};
