const STATI_DICHIARAZIONE = {
	"null": "Nuova dichiarazione",
	"NUOVA": "Nuova dichiarazione",
	"REVISIONE_CDR": "Revisione CDR",
	"CONFERMATA": "Confermata",
	"APPROVATA": "Approvata",
	"VERIFICATA": "Verificata",
	"DA_VERIFICARE": "Da verificare",
};

const STATI_VALIDITA = {
	"NON_APPLICABILE": "Non applicabile",
	"NON_VALIDO": "Non valida",
	"VALIDO": "Valida",
	"VALIDO_FORZATAMENTE": "Validata forzatamente",
};

const getStatusValidityDesc = (stato) => {
	return STATI_VALIDITA[stato];
};

const getStatusDesc = (stato) => {
	return STATI_DICHIARAZIONE[stato];
};

export { getStatusDesc, getStatusValidityDesc };
