import React, { useState, useEffect } from "react";
import { useGlobalStateContext } from "../context/GlobalContext";
import { userCdrInit } from "../actions/portalApi";
import { caricaListaImpiantiPerTipologia, caricaListaCdrPerImpianto, salvaImpianto, eliminaImpianto, caricaImpianto } from "../actions/portalApi";
import { NAZIONI, TIPOLOGIA_IMPIANTO } from "../common/constants";
import ModaleImpianto from "../components/gestioneImpianti/ModaleImpianto";
import ModaleModificaImpianto from "../components/gestioneImpianti/ModaleModificaImpianto";
import ModaleListaCdr from "../components/gestioneImpianti/ModaleListaCdr";
import ModaleConfermaEliminazione from "../components/comuni/ModaleConfermaEliminazione";
import Toast, {TOAST_DANGER, TOAST_SUCCESS} from "../components/comuni/Toast";

export default function GestioneImpianti() {
  const user = useGlobalStateContext();
  const [listaImp, setListaImp] = useState(null);
  const [tipologiaImpianto, setTipologiaImpianto] = useState("");
  const [mostraModaleImpianto, setMostraModaleImpianto] = useState(false);
  const [mostraModaleModificaImpianto, setMostraModaleModificaImpianto] = useState(false);
  const [mostraModaleListaCdr, setMostraModaleListaCdr] = useState(false);
  const [mostraModaleConfermaEliminazione, setMostraModaleConfermaEliminazione] = useState(false);
  const [impiantoId, setImpiantoId] = useState(null);
  const [listaCdrPerImpianto, setListaCdrPerImpianto] = useState(null);
  const [impiantoClone, setImpiantoClone] = useState(null)
  const [impianto, setImpianto] = useState({
		id: null,
		ragioneSociale: "",
		tipoImpianto: "",
		cebisVendorId: null,
		cebisVendorSiteId: null,
		indirizzo: "",
		nazione: "",
		cap: "",
		comuneCodIstat: "",
		comuneNome: "",
		provincia: "",
    gprovincia: "",
    nazione: "",
		telefono: "",
		email: "",
		website: null,
		attivo: true,
	});
  const [messageToast, setMessageToast ] = useState({
    text: "",
    isVisible: false,
    type: ""
  });

    useEffect(() => {
      window.onbeforeunload = null;
      window.history.replaceState({}, '', '/#/gestione-impianti');
      ruoloUtenteRec();
    }, []);

    const ruoloUtenteRec = () => {
      user.user.ruoli.map((e)=> {
        if (e.codiceRuolo != 'REC_CDR_SUPERVISOR' && e.codiceRuolo != 'REC_CDR_USER') {
          userCdrInit().then((res)=> {
            if (res != null){
              caricaListaImpiantiPerTipologia("").then((lista) => {
                if (lista != null) {
                  setListaImp(lista.data.data);
                }
              })
          }})
          }
      })
    }
    
  const aggiornamentoTipologiaImpianto = (value) => {
		setTipologiaImpianto(value);
    };

  const listaGestioneImpianti = () => {
    return (
    <select className="form-control" value={ tipologiaImpianto } onChange={ (e)=> {
      aggiornamentoTipologiaImpianto(e.target.value);}} required>
        {TIPOLOGIA_IMPIANTO.map((imp,_idx) => {
          return <option key={_idx} value={imp.value}> {imp.label}</option>
        })}
    </select>
  )}

  const filtraGestioneImpianti = () => {
    let id = tipologiaImpianto;
      caricaListaImpiantiPerTipologia(id).then((res) => {
        if (res !== null) {
          setListaImp(res.data.data);
        } else {
            setMessageToast({
              text: "Non è presente alcun impianto",
              isVisible: true,
              type: TOAST_DANGER
            })
        }
      })
  }

   const apriCdrAssociati = (idImpianto) => {
    caricaListaCdrPerImpianto(idImpianto).then((res)=>{
      if (res.data.data != null){
        setListaCdrPerImpianto(res.data.data);
        setMostraModaleListaCdr(true);
      } else {
        setMessageToast({
        text: "Errore nell'apertura del CDR associato per il seguente impianto",
        isVisible: true,
        type: TOAST_DANGER
      })
      }
    })
  }

  const apriModificaImpianto = (idImpianto) => {
    let imp = {...impianto};
    setImpiantoClone(imp);
    caricaImpianto(idImpianto).then((res)=> {
        if (res.data.data != null) {
        setImpiantoClone(res.data.data)
        setMostraModaleModificaImpianto(true);
    } else {
      setMessageToast({
        text: "Errore nell'apertura della modifica impianto",
        isVisible: true,
        type: TOAST_DANGER
      })
    }
    })
  }

  const apriEliminaImpianto = (idImpianto) => {
    setImpiantoId(idImpianto);
    setMostraModaleConfermaEliminazione(true);
  }

  const eliminaImp = () => {
    let tipoImpiantoSelezionato = tipologiaImpianto;
    let id = impiantoId;
    eliminaImpianto(id).then((res)=> {
      if (res) {
        setMessageToast({
          text: "Impianto eliminato con successo",
          isVisible: true,
          type: TOAST_SUCCESS
        })
        caricaListaImpiantiPerTipologia(tipoImpiantoSelezionato).then((lista) => {
          setMostraModaleConfermaEliminazione(false);
          if (lista != null) {
            setListaImp(lista.data.data);
          }
        })
      }
    })
  }  

  const modificaImpianto = (dto) => {
    let {targa, gprovincia, ...dtoServer} = dto;
    salvaImpianto(dtoServer).then((res)=> {
        if (res) {
          setMessageToast({
            text: "Impianto modificato con successo",
            isVisible: true,
            type: TOAST_SUCCESS
          })
          let tipoImpiantoSelezionato = tipologiaImpianto;
          caricaListaImpiantiPerTipologia(tipoImpiantoSelezionato).then((lista) => {
          if (lista != null) {
            setListaImp(lista.data.data);
          }
        })
        }
    })
  }

  const inserisciImpianto = (dto) => {
    let {targa, gprovincia, ...dtoServer} = dto;
    salvaImpianto(dtoServer).then((res)=> {
      if (res) {
        setMessageToast({
          text: "Impianto salvato con successo",
          isVisible: true,
          type: TOAST_SUCCESS
        })
        caricaListaImpiantiPerTipologia("").then((lista) => {
          if (lista != null) {
            setListaImp(lista.data.data);
          }
        })
      }
    })
  }

  const renderImpianti = () => {
    if (listaImp != null && listaImp.length > 0) {
      return listaImp.map((imp, _idx) => {
        return (
        <tr key={_idx}>
          <td>{imp.ragioneSociale}</td>
          <td>{imp.tipoImpianto}</td>
          <td>{imp.indirizzo != ""? <>{imp.indirizzo}</>:null}{imp.cap != ""? <>{", "}{imp.cap}</>:null} {imp.comuneNome != ""? <>{"- "}{imp.comuneNome}</>:null} {imp.provincia != ""?<>{"- "}({imp.provincia})</>:null}</td>
          <td>{imp.nazione}</td>
          <td>{imp.email}</td>
          <td><button className="btn btn-primary" onClick={() => {apriCdrAssociati(imp.id);}}>CDR associati</button></td>
          <td><button className="btn btn-primary" onClick={() => {apriModificaImpianto(imp.id);}}>Modifica</button></td>
          <td><button className="btn btn-primary" onClick={() => {apriEliminaImpianto(imp.id);}}>Elimina</button></td>
        </tr>
        )
      })
    } else {
      return (
        <tr>
          <td colSpan={8}>Non sono presenti impianti per la tipologia selezionata</td>
        </tr>
      )
    }
  }

    return (
      <>
        <div key="gestioneImpianti" className="container-fluid">
          <div className="cor-card">
            <div className="cor-card-header">
              <h4>Gestione impianti</h4>
            </div>
            <div className="cor-card-body">
              <div className="fixed-header">
                  <div className="row form-group gestione-impianti-filter">
                  <div className="form-group col-md-3">
                    <div className="selection-panel" style={{ display: "block"}}>
                      <strong>Tipologia impianto</strong>: <div>{ listaGestioneImpianti() }</div>
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <div className="btn-panel">
                      <button className="btn btn-primary" onClick={() => {filtraGestioneImpianti();}}> Cerca </button>
                      <button className="btn btn-light" onClick={() => {setMostraModaleImpianto(true);}}> Nuovo impianto</button>
                    </div>
                  </div>
                  </div>
                </div>
                <div className="tabella-impianti-wrapper">
                  <table className="tabella-impianti">
                    <thead>
                      <tr>
                        <th>Ragione sociale</th>
                        <th>Tipo impianto</th>
                        <th>Indirizzo</th>
                        <th>Nazione</th>
                        <th>Email</th>
                        <th colSpan={3}>Azioni</th>
                      </tr>
                    </thead>
                    <tbody>{renderImpianti()}</tbody>
                  </table>
                </div>
              </div>
            </div>
            <ModaleImpianto 
            isOpen={mostraModaleImpianto}
            oggettoImpianto={impianto}
            listaTipologiaImpianti={TIPOLOGIA_IMPIANTO}
            listaNazioni={NAZIONI}
            conferma = {async(attivita) => {
              inserisciImpianto(attivita);
              setMostraModaleImpianto(false);
            }}
            onExit={() => {
              setMostraModaleImpianto(false);}} 
            />
            <ModaleModificaImpianto 
            isOpen={mostraModaleModificaImpianto}
            oggettoImpianto={impiantoClone}
            listaTipologiaImpianti={TIPOLOGIA_IMPIANTO}
            listaNazioni={NAZIONI}
            conferma={async(attivita)=>{
            modificaImpianto(attivita);
            setMostraModaleModificaImpianto(false);}}
            onExit={() => {
              setMostraModaleModificaImpianto(false);}} 
            />
            <ModaleListaCdr 
            isOpen={mostraModaleListaCdr}
            lista={listaCdrPerImpianto}
            onExit={()=>{setMostraModaleListaCdr(false)}}
            />
            <ModaleConfermaEliminazione 
            isOpen={mostraModaleConfermaEliminazione}
            elimina={()=>{eliminaImp()}}
            onExit={() => {
              setImpiantoId(null);
              setMostraModaleConfermaEliminazione(false);
            }}/>
            <Toast isVisible={ messageToast.isVisible } type={messageToast.type} text={ messageToast.text } onClose={ () => {
              setMessageToast({
                text: "",
                isVisible: false,
                type: ""
              });
              } }
            />
          </div>
      </>
    )
}