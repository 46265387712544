import { parametri } from "../common/mocks";
export const CHECK_NONE = "#e5e5e5";
export const CHECK_NOK = "#990f0f";
export const CHECK_OK = "#17993a";

export const INFO_CONTROLLI = {
	"perditaDiProcesso":
		"La perdita di processo non deve superare il 5% del materiale entrato in produzione. [9 ≤ 5% * (5+6+7+8)]",
	"scartiProcesso": `Lo scarto di processo non deve essere superiore al ${
		parametri["percMaxPerditaProcesso"] * 100
	}% del materiale entrato in produzione [8 ≤ ${
		parametri["percMaxPerditaProcesso"] * 100
	}% * (5+6+7+8)]`,
	"giacenzaRicezione":
		"Il materiale entrato in produzione deve essere inferiore alla giacenza del magazzino ricezione A [(3+4+5+6+7+8+9+17+18) <= Giacenza magazzino “Ricezione A”]",
	"giacenzaMaterialeNonConforme":
		"Il materiale non conforme che esce dal CDR deve essere inferiore alla giacenza dello stesso nel magazzino Output considerando nel bilancio anche il materiale non conforme che entrerà nel magazzino constestualmente a questa stessa dichiarazione [14 <= Giacenza magazzino “Output” + 4]",
	"giacenzaCssCdr":
		"Il materiale CSS/CDR che esce dal CDR deve essere inferiore alla giacenza dello stesso nel magazzino Output considerando nel bilancio anche il CSS/CDR che entrerà nel magazzino contestualmente a questa stessa dichiarazione [10 <= Giacenza magazzino “Output” + 5]",
	"giacenzaCssC":
		"Il materiale CSS-C che esce dal CDR deve essere inferiore alla giacenza dello stesso nel magazzino Output considerando nel bilancio anche il CSS-C che entrerà nel magazzino contestualmente a questa stessa dichiarazione [11 <= Giacenza magazzino “Output” + 6]",
	"giacenzaMetalliSeparati":
		"Il materiale metalli separati che esce dal CDR deve essere inferiore alla giacenza dello stesso nel magazzino Output considerando nel bilancio anche il metalli separati che entrerà nel magazzino contestualmente a questa stessa dichiarazione [12 <= Giacenza magazzino “Output” + 7]",
	"giacenzaScartiProcesso":
		"Il materiale scarti di processo che esce dal CDR deve essere inferiore alla giacenza dello stesso nel magazzino Output considerando nel bilancio anche il scarti di processo che entrerà nel magazzino contestualmente a questa stessa dichiarazione [13 <= Giacenza magazzino “Output” + 8]",
	"giacenzaAvvioRiciclo":
		"Il materiale avvio a riciclo che esce dal CDR deve essere inferiore alla giacenza dello stesso nel magazzino Output considerando nel bilancio anche il materiale avvio a riciclo che entrerà nel magazzino contestualmente a questa stessa dichiarazione [19 <= Giacenza magazzino “Output” + 17]",
	"giacenzaAutoRicilo":
		"Il materiale auto riciclo che esce dal CDR deve essere inferiore alla giacenza dello stesso nel magazzino Output considerando nel bilancio anche il materiale auto riciclo che entrerà nel magazzino contestualmente a questa stessa dichiarazione [20 <= Giacenza magazzino “Output” + 18]",
};

export const MESI = [
	{ "testo": "Gennaio", "valore": 1 },
	{ "testo": "Febbraio", "valore": 2 },
	{ "testo": "Marzo", "valore": 3 },
	{ "testo": "Aprile", "valore": 4 },
	{ "testo": "Maggio", "valore": 5 },
	{ "testo": "Giugno", "valore": 6 },
	{ "testo": "Luglio", "valore": 7 },
	{ "testo": "Agosto", "valore": 8 },
	{ "testo": "Settembre", "valore": 9 },
	{ "testo": "Ottobre", "valore": 10 },
	{ "testo": "Novembre", "valore": 11 },
	{ "testo": "Dicembre", "valore": 12 },
];

export const TIPO_IMPIANTI = {
	"TERMOVALORIZZATORE_ITA": "Termovalorizzatore ita",
	"CEMENTIFICIO_ITA": "Cementificio ita",
	"SMALTIMENTO": "Smaltimento",
};

export const TIPOLOGIA_IMPIANTO = [
	{ "label": "-- Tutte le tipologie --", "value": "" },
	{ "label": "Cementificio ita", "value": "CEMENTIFICIO_ITA" },
	{ "label": "Cementificio est", "value": "CEMENTIFICIO_EST" },
	{ "label": "Termovalorizzatore ita", "value": "TERMOVALORIZZATORE_ITA" },
	{ "label": "Termovalorizzatore est", "value": "TERMOVALORIZZATORE_EST" },
	{ "label": "Smaltimento", "value": "SMALTIMENTO" },
];

export const ITALIA_O_ESTERO = [
	{ "testo": "-- Seleziona ita/estero --", "valore": "" },
	{ "testo": "Italia", "valore": "Italia" },
	{ "testo": "Estero", "valore": "estero" },
];

export const PARAMETRI = [
	{ "testo": "-- Seleziona parametro --", "valore": "" },
	{ "testo": "Valori dichiarazione", "valore": "CONTROLLI_VALIDITA" },
	{ "testo": "Scarti di processo", "valore": "PERDITA_PROCESSO" },
];

export const NAZIONI = [
	{ "testo": "-- Seleziona --", "valore": "" },
	{ "testo": "Albania", "valore": "AL" },
	{ "testo": "Armenia", "valore": "AM" },
	{ "testo": "Austria", "valore": "AT" },
	{ "testo": "Azerbaigian", "valore": "AZ" },
	{ "testo": "Belgio", "valore": "BE" },
	{ "testo": "Bielorussia", "valore": "BY" },
	{ "testo": "Bosnia", "valore": "BH" },
	{ "testo": "Bulgaria", "valore": "BG" },
	{ "testo": "Cipro", "valore": "CY" },
	{ "testo": "Croazia", "valore": "HR" },
	{ "testo": "Danimarca", "valore": "DK" },
	{ "testo": "Francia", "valore": "FR" },
	{ "testo": "Georgia", "valore": "GE" },
	{ "testo": "Germania", "valore": "DE" },
	{ "testo": "Grecia", "valore": "EL" },
	{ "testo": "Irlanda", "valore": "IE" },
	{ "testo": "Kazakistan", "valore": "KZ" },
	{ "testo": "Lettonia", "valore": "LV" },
	{ "testo": "Macedonia del Nord", "valore": "MK" },
	{ "testo": "Moldavia", "valore": "MD" },
	{ "testo": "Montenegro", "valore": "ME" },
	{ "testo": "Olanda", "valore": "NL" },
	{ "testo": "Polonia", "valore": "PL" },
	{ "testo": "Portogallo", "valore": "PT" },
	{ "testo": "Rep. Ceca", "valore": "CZ" },
	{ "testo": "Romania", "valore": "RO" },
	{ "testo": "Russia", "valore": "RU" },
	{ "testo": "Serbia", "valore": "RS" },
	{ "testo": "Slovacchia", "valore": "SK" },
	{ "testo": "Slovenia", "valore": "SL" },
	{ "testo": "Spagna", "valore": "ES" },
	{ "testo": "Svizzera", "valore": "CH" },
	{ "testo": "Spagna", "valore": "ES" },
	{ "testo": "Turchia", "valore": "TR" },
	{ "testo": "Ucraina", "valore": "UA" },
	{ "testo": "Ungheria", "valore": "HU" },
];
