import { useEffect, useState } from "react";
import { Modal, ModalFooter, ModalHeader, ModalBody, Button, Progress } from "reactstrap";
import axios from "axios";
import { RECUPERO_API } from "../../common/env";
import { getAccessTokenCookie } from "../../common/cookie";
import Toast, { TOAST_DANGER, TOAST_SUCCESS } from "../comuni/Toast";

export default function ModaleAllegato(props) {
	const [file, setFile] = useState(null);
	const [isUploading, setIsUploading] = useState(false);
	const [caricamentoPercentuale, setCaricamentoPercentuale] = useState(0);
	const [messageToast, setMessageToast] = useState({
		text: "",
		isVisible: false,
		type: "",
	});

	useEffect(() => {
		setFile(null);
		setCaricamentoPercentuale(0);
	}, [props.isOpen]);

	function caricaPdf(file) {
		setIsUploading(true);
		const formData = new FormData();
		formData.append("file", file);
		const url = `${RECUPERO_API}/dichiarazione/allegato/upload`;
		const options = {
			onUploadProgress: (ProgressEvent) => {
				const { loaded, total } = ProgressEvent;
				let percentuale = Math.floor((loaded * 100) / total);
				if (percentuale < 100) {
					setCaricamentoPercentuale(percentuale);
				}
			},
		};
		return new Promise((resolve, reject) => {
			return axios
				.post(
					url,
					formData,
					{
						headers: { Authorization: `Bearer ${getAccessTokenCookie()}` },
						"Content-Type": "multipart/form-data",
					},
					options
				)
				.then((res) => {
					let nomeEasset = res.data.data.split(";");
					let nomeDellAsset = nomeEasset[0];
					let nomeDelFile = nomeEasset[1];
					let dettagliAllegato = {
						id: null,
						nomeFile: nomeDelFile,
						idAsset: nomeDellAsset,
						tipoAllegato: 0,
					};
					props.onAllegatoAdd(dettagliAllegato);
					setCaricamentoPercentuale(100);
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	function caricaAllegatoPdf() {
		caricaPdf(file).then((res) => {
			props.onExit();
			if (res.data.data.length > 0) {
				setIsUploading(false);
				setMessageToast({
					text: "Caricamento avvenuto con successo!",
					isVisible: true,
					type: TOAST_SUCCESS,
				});
			} else {
				setIsUploading(false);
				setMessageToast({
					text: "Errore nel caricamento del file!",
					isVisible: true,
					type: TOAST_DANGER,
				});
			}
		});
	}

	const aggiornamentoFile = (e) => {
		setFile(e.target.files[0]);
	};

	return (
		<>
			<Modal
				isOpen={props.isOpen}
				size="lg"
				role="dialog"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<ModalHeader>
					<h3>Caricamento allegato pdf</h3>
				</ModalHeader>
				<ModalBody>
					<input
						type="file"
						id="file-import"
						name="file-import"
						accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .odt, .tiff"
						onChange={(e) => {
							aggiornamentoFile(e);
						}}
					/>
					{props.hasprogressbar ? (
						<>
							<Progress
								style={{ margin: "10px" }}
								value={caricamentoPercentuale}
								animated
								color="success"
							/>
							+{caricamentoPercentuale}%
						</>
					) : null}
				</ModalBody>
				<ModalFooter>
					<button
						disabled={isUploading}
						className="btn btn-primary"
						onClick={() => {
							caricaAllegatoPdf();
						}}>
						Conferma
					</button>
					<Button onClick={props.onExit}>Annulla</Button>
				</ModalFooter>
			</Modal>
			<Toast
				isVisible={messageToast.isVisible}
				type={messageToast.type}
				text={messageToast.text}
				onClose={() => {
					setMessageToast({
						text: "",
						isVisible: false,
						type: "",
					});
				}}></Toast>
		</>
	);
}
