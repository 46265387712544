import React, { useState, useEffect } from "react";
import { Modal, ModalFooter, ModalHeader, ModalBody, Button } from "reactstrap";
import { autoSuggerimentoRicercaComune } from "../../actions/portalApi";
import { controlliInserimentoImpianto } from "../../functions/gestioneImpiantiFunctions";
import Downshift from "downshift";
import Toast, { TOAST_DANGER } from "../comuni/Toast";
import { ITALIA_O_ESTERO } from "../../common/constants";

export default function ModaleImpianto(props) {
	const [tmpImp, setTmpImp] = useState(props.oggettoImpianto);
	const [suggerimenti, setSuggerimenti] = useState([]);
	const [nazioneSelezionata, setNazioneSelezionata] = useState(null);
	const [messageToast, setMessageToast] = useState({
		text: "",
		isVisible: false,
		type: "",
	});

	useEffect(() => {
		setTmpImp(props.oggettoImpianto);
	}, [props.isOpen]);

	const inserisci = () => {
		let data = { ...tmpImp };
		let nazioneScelta = nazioneSelezionata;
		let controllo = controlliInserimentoImpianto(data);
		if (!controllo.verificato) {
			setMessageToast({
				text: controllo.descrizioneErrore,
				isVisible: true,
				type: TOAST_DANGER,
			});
		} else {
			if (nazioneScelta != ITALIA_O_ESTERO[1].valore) {
				data.cap = "";
				data.comuneCodIstat = "";
				data.provincia = "";
			} else {
				data.nazione = "Italia";
			}
			props.conferma(data);
		}
	};

	const aggiorna = (chiave, valore) => {
		let i = { ...tmpImp };
		if (chiave == "datiGeneraliComune") {
			i.comuneNome = valore.comune;
			i.provincia = valore.gprovincia;
			i.comuneCodIstat = valore.codiceIstat;
		} else if (chiave == "cebisVendorId" || chiave == "cebisVendorSiteId") {
			if (valore > 0) {
				i[chiave] = Number(valore);
			} else {
				valore = null;
				i[chiave] = valore;
			}
		} else {
			i[chiave] = valore;
		}
		setTmpImp(i);
	};

	const aggiornaNazione = (chiave, valore) => {
		let oggetto = { ...tmpImp };
		oggetto.comuneNome = "";
		if (chiave == "estero") {
			oggetto.nazione = valore;
		} else {
			oggetto.nazione = "";
		}
		if (chiave == "selezionaNazione") {
			setNazioneSelezionata(valore);
		}
		setTmpImp(oggetto);
	};

	const suggerisci = (valore) => {
		if (valore != "") {
			autoSuggerimentoRicercaComune(valore).then((res) => {
				if (res.data.length > 0) {
					setSuggerimenti(res.data);
				}
			});
		}
	};

	// downshift
	const itemToString = (item) => (item ? item.comune : "");
	const stateReducer = (state, changes) => {
		if (changes.type === Downshift.stateChangeTypes.blurButton) {
			return { ...changes, isOpen: false };
		}
		return changes;
	};

	return (
		<>
			<Modal
				isOpen={props.isOpen}
				size="lg"
				role="dialog"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				backdrop="static"
				keyboard={true}>
				<ModalHeader>
					<h4>Inserimento Nuovo Impianto</h4>
				</ModalHeader>
				<ModalBody>
					<form>
						<div className="form-row">
							<div className="form-group col-md-6">
								<label for="inputragionesociale">Ragione Sociale</label>
								<input
									type="text"
									className="form-control"
									id="inputragionesociale"
									placeholder="Ragione sociale"
									value={tmpImp.ragioneSociale}
									onChange={(e) => aggiorna("ragioneSociale", e.target.value)}
								/>
							</div>
							<div className="form-group col-md-6">
								<label for="inputTipoImpianto">Tipo impianto</label>
								<select
									id="inputTipoImpianto"
									className="form-control"
									onChange={(e) => aggiorna("tipoImpianto", e.target.value)}>
									{props.listaTipologiaImpianti.map((imp, _idx) => {
										return (
											<option key={_idx} value={imp.value}>
												{" "}
												{imp.label}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<div className="form-row">
							<div className="form-group col-md-4">
								<label for="inputNazione">Seleziona Nazione</label>
								<select
									className="form-control"
									value={nazioneSelezionata}
									onChange={(e) => {
										aggiornaNazione("selezionaNazione", e.target.value);
									}}
									required>
									{ITALIA_O_ESTERO.map((n, _idx) => {
										return (
											<option key={_idx} value={n.valore}>
												{n.testo}
											</option>
										);
									})}
								</select>
							</div>
							{nazioneSelezionata == "" || nazioneSelezionata == null ? null : nazioneSelezionata !=
							  ITALIA_O_ESTERO[1].valore ? (
								<>
									<div className="form-group col-md-4">
										<label for="inputNazione">Nazione</label>
										<select
											className="form-control"
											value={tmpImp.nazione}
											onChange={(e) => {
												aggiornaNazione("estero", e.target.value);
											}}
											required>
											{props.listaNazioni.map((n, _idx) => {
												return (
													<option key={_idx} value={n.valore}>
														{n.testo}
													</option>
												);
											})}
										</select>
									</div>
									<div className="form-group col-md-4">
										<label for="inputCitta">Città</label>
										<input
											type="text"
											id="inputCitta"
											className="form-control"
											value={tmpImp.comuneNome}
											onChange={(e) => aggiorna("comuneNome", e.target.value)}
										/>
									</div>
								</>
							) : null}
						</div>
						{nazioneSelezionata == "" || nazioneSelezionata == null ? null : nazioneSelezionata ==
						  ITALIA_O_ESTERO[1].valore ? (
							<>
								<div className="form-row">
									<div className="form-group col-md-7">
										<Downshift
											stateReducer={stateReducer}
											itemToString={itemToString}
											onSelect={(selectedItem) => aggiorna("datiGeneraliComune", selectedItem)}>
											{({
												getInputProps,
												getMenuProps,
												getItemProps,
												highlightedIndex,
												isOpen,
											}) => (
												<div>
													<label for="inputComune">Comune</label>
													<input
														className="form-control col-md-12"
														{...getInputProps({
															value: tmpImp.comuneNome,
															onChange: (e) => {
																aggiorna("datiGeneraliComune", e.target.value);
																suggerisci(e.target.value);
															},
														})}
													/>
													<ul
														{...getMenuProps({
															style: {
																maxHeight: 150,
																width: "98%",
																overflowY: "scroll",
																overflowX: "hidden",
																backgroundColor: "#ffffff",
																padding: 0,
																marginTop: 0,
																listStyle: "none",
																outline: "0",
																transition: "opacity .1s ease",
																boxShadow: "0 6px 4px 0 rgba(34,36,38,.15)",
																position: "absolute",
																zIndex: 999,
															},
														})}>
														{isOpen
															? suggerimenti.map((item, _idx) => (
																	<li
																		className="lista-suggerimenti"
																		{...getItemProps({
																			item,
																			key: item.comuneId,
																			style: {
																				backgroundColor:
																					_idx === highlightedIndex ? "#efefef" : null,
																				listStyleType: "none",
																				padding: "4px",
																				position: "relative",
																				cursor: "pointer",
																				display: "block",
																				border: "none",
																				height: "auto",
																				textAlign: "left",
																				borderTop: "none",
																				lineHeight: "1em",
																				color: "rgba(0,0,0,.87)",
																				fontSize: "1rem",
																				textTransform: "none",
																				fontWeight: "400",
																				boxShadow: "none",
																				whiteSpace: "normal",
																				wordWrap: "normal",
																			},
																		})}>
																		{item.comune} - ({item.gprovincia})
																	</li>
															  ))
															: null}
													</ul>
												</div>
											)}
										</Downshift>
									</div>
									<div className="form-group col-md-2">
										<label for="inputCap">CAP</label>
										<input
											type="tel"
											className="form-control"
											id="inputCap"
											maxLength={5}
											value={tmpImp.cap}
											onChange={(e) => aggiorna("cap", e.target.value)}
										/>
									</div>
									<div className="form-group col-md-3">
										<label for="inputCitta">Nazione</label>
										<input
											type="text"
											id="inputCitta"
											className="form-control"
											value={nazioneSelezionata}
											readOnly
										/>
									</div>
								</div>
							</>
						) : null}
						<div className="form-row">
							<div className="form-group col-md-8">
								<label for="inputIndirizzo">Indirizzo</label>
								<input
									type="text"
									className="form-control"
									id="inputIndirizzo"
									value={tmpImp.indirizzo}
									onChange={(e) => aggiorna("indirizzo", e.target.value)}
								/>
							</div>
							<div className="form-group col-md-4">
								<label for="inputTelefono">Telefono</label>
								<input
									type="tel"
									className="form-control"
									id="inputTelefono"
									maxLength={10}
									value={tmpImp.telefono}
									onChange={(e) => aggiorna("telefono", e.target.value)}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="form-group col-md-6">
								<label for="inputCebis">Cebis ID</label>
								<input
									type="number"
									className="form-control"
									id="inputCebis"
									value={tmpImp.cebisVendorId}
									onChange={(e) => aggiorna("cebisVendorId", e.target.value)}
								/>
							</div>
							<div className="form-group col-md-6">
								<label for="inputCebisSito">Cebis Site ID</label>
								<input
									type="number"
									id="inputCebisSito"
									className="form-control"
									value={tmpImp.cebisVendorSiteId}
									onChange={(e) => aggiorna("cebisVendorSiteId", e.target.value)}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-12">
								<label for="inputSitoWeb">Sito web</label>
								<input
									type="text"
									className="form-control"
									id="inputSitoWeb"
									value={tmpImp.website}
									onChange={(e) => aggiorna("website", e.target.value)}
								/>
							</div>
						</div>
					</form>
				</ModalBody>
				<ModalFooter>
					<button
						className="btn btn-primary"
						onClick={() => {
							inserisci();
						}}>
						Conferma
					</button>
					<Button onClick={props.onExit}>Annulla</Button>
				</ModalFooter>
				<Toast
					isVisible={messageToast.isVisible}
					type={messageToast.type}
					text={messageToast.text}
					onClose={() => {
						setMessageToast({
							text: "",
							isVisible: false,
							type: "",
						});
					}}
				/>
			</Modal>
		</>
	);
}
