import { Modal, ModalFooter, ModalHeader, ModalBody, Button } from "reactstrap";

export default function ModaleMaterialeNonConformeRec(props) {
	const titoloModale = (statoNc) => {
		if (statoNc == "NONE") {
			return (
				<>
					<h4>E' presente una quantità di materiale non conforme.</h4>
					<br />
					<h5>Inserisci una nota per motivare la quantità approvata/rifiutata</h5>
				</>
			);
		}
		if (statoNc == "NON_APPROVATA") {
			return <h4>Hai rifiutato la non conformità proveniente dal Centro di Recupero</h4>;
		}
		if (statoNc == "APPROVATA") {
			return <h4>Dettagli materiale non conforme</h4>;
		}
	};

	return (
		<Modal
			isOpen={props.isOpen}
			size="lg"
			role="dialog"
			aria-labelledby="contained-modal-title-vcenter"
			centered>
			<ModalHeader>{titoloModale(props.dettagliNc?.statoNc)}</ModalHeader>
			<ModalBody>
				{!props.solaLettura ? (
					<>
						<div className="form-group">
							<label>
								<b>Stato non conformità: IN_CORSO</b>
							</label>
							<br />
							<label>
								Allegati:
								<br />
								{props.storicoAllegati.map((e) => {
									if (e.tipoAllegato == 2) {
										return (
											<span>
												&nbsp;
												<a
													target="_blank"
													href={`${props.api}/dichiarazione/allegato/download/${e.idAsset}?namespace=data/dichiarazione/CDR-${props.cdrId}&_a=${props.token}`}>
													{e.nomeFile}
												</a>
												<br />
											</span>
										);
									}
								})}
							</label>
							<br />
							<label>Quantità proposta materiale non conforme</label>
							<input type="number" className="form-control" value={props.qtaOriginale} readOnly />
							<label>Quantità approvata</label>
							<input
								type="number"
								className="form-control"
								min="0"
								value={props.qtaMateriale}
								onChange={(e) => {
									props.modificaQtaMateriale(e.target.value);
								}}
							/>
							<label>Nota Corepla</label>
							<textarea
								className="form-control"
								value={props.testo}
								onChange={(e) => props.scritturaNota(e.target.value)}></textarea>
						</div>
					</>
				) : (
					<div className="form-group">
						<label>
							<b>Stato non conformità: {props.dettagliNc?.statoNc}</b>
						</label>
						<br />
						<label>
							Allegati:
							<br />
							{props.storicoAllegati.map((e) => {
								if (e.tipoAllegato == 2) {
									return (
										<span>
											&nbsp;
											<a
												target="_blank"
												href={`${props.api}/dichiarazione/allegato/download/${e.idAsset}?namespace=data/dichiarazione/CDR-${props.cdrId}&_a=${props.token}`}>
												{e.nomeFile}
											</a>
											<br />
										</span>
									);
								}
							})}
						</label>
						<br />
						<label>Quantità proposta materiale non conforme</label>
						<input type="number" className="form-control" value={props.qtaOriginale} readOnly />
						<label>Quantità approvata</label>
						<input type="number" className="form-control" value={props.qtaMateriale} readOnly />
						<label>Nota Corepla</label>
						<textarea className="form-control" value={props.testo} readOnly></textarea>
					</div>
				)}
			</ModalBody>
			<ModalFooter>
				<Button onClick={props.onExit}>{props.solaLettura ? "Chiudi" : "Annulla"}</Button>
				{props.solaLettura ? null : (
					<>
						<button className="btn btn-primary" onClick={props.approva}>
							Approva
						</button>
						<Button onClick={props.rifiuta}>Rifiuta</Button>
					</>
				)}
			</ModalFooter>
		</Modal>
	);
}
