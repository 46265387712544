import { COREPLA_PORTAL_API, PEPS_API, RECUPERO_API } from "../common/env.js";
import { getAccessTokenCookie } from "../common/cookie";
import axios from "axios";

// generale
export function userCurrent() {
	const url = `${COREPLA_PORTAL_API}/rs/app/init`;
	return new Promise((resolve, reject) => {
		return axios
			.get(url, { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } })
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getMenu() {
	const url = `${COREPLA_PORTAL_API}/rs/menu/get`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.get(url, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function userCdrInit() {
	const url = `${RECUPERO_API}/utente/init`;
	return new Promise((resolve, reject) => {
		return axios
			.get(url, { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } })
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

// dich

export function caricaListaCdr() {
	const url = `${RECUPERO_API}/organizzazione/lista_cdr`;
	return new Promise((resolve, reject) => {
		return axios
			.get(url, { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } })
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function caricaListaCentriDiRecupero(dichiarazione) {
	const url = `${RECUPERO_API}/cdr_cdr/lista/${dichiarazione.idCdr}/${dichiarazione.mese}/${dichiarazione.anno}`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.get(url, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => reject(error));
	});
}

export function caricaListaImpianti(dichiarazione) {
	const url = `${RECUPERO_API}/impianto/lista/${dichiarazione.idCdr}/${dichiarazione.mese}/${dichiarazione.anno}`;
	return new Promise((resolve, reject) => {
		return axios
			.get(url, { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } })
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function caricaDichiarazione(dichiarazione) {
	const url = `${RECUPERO_API}/dichiarazione/load/${dichiarazione.idCdr}/${dichiarazione.mese}/${dichiarazione.anno}`;
	return new Promise((resolve, reject) => {
		return axios
			.get(url, { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } })
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function caricaDichiarazionePrecedente(dichiarazione) {
	const url = `${RECUPERO_API}/dichiarazione/load/${dichiarazione.idCdr}/${dichiarazione.mese}/${dichiarazione.anno}?statoDichiarazione=APPROVATA`;
	return new Promise((resolve, reject) => {
		return axios
			.get(url, { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } })
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function salvaDichiarazione(dto) {
	const url = `${RECUPERO_API}/dichiarazione`;
	return new Promise((resolve, reject) => {
		return axios
			.put(url, dto, { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } })
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function salvaDichiarazioneNc(dto) {
	const url = `${RECUPERO_API}/dichiarazione/salva_nc`;
	return new Promise((resolve, reject) => {
		return axios
			.post(url, dto, { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } })
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function caricaValoriCebis(dichiarazione) {
	const url = `${RECUPERO_API}/dichiarazione/cebis_values/${dichiarazione.idCdr}/${dichiarazione.mese}`;
	return new Promise((resolve, reject) => {
		return axios
			.get(url, { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } })
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function riepilogoMesi(dichiarazione) {
	const url = `${RECUPERO_API}/dichiarazione/riepilogo_mesi/${dichiarazione.idCdr}/${dichiarazione.anno}`;
	return new Promise((resolve, reject) => {
		return axios
			.get(url, { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } })
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function caricaParametriDichiarazione(dichiarazione) {
	const url = `${RECUPERO_API}/dichiarazione_param/load_by_cdr/${dichiarazione.idCdr}/${dichiarazione.mese}/${dichiarazione.anno}`;
	return new Promise((resolve, reject) => {
		return axios
			.get(url, { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } })
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

// gestioneImp

export function caricaListaImpiantiPerTipologia(tipoImpianto) {
	const url = `${RECUPERO_API}/impianto/lista?tipoImpianto=${tipoImpianto}&attivo=true`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.get(url, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function caricaListaCdrPerImpianto(idImpianto) {
	const url = `${RECUPERO_API}/impiantoCdr/lista/${idImpianto}`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.get(url, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function caricaListaImpiantiPerCdr(idCdr) {
	const url = `${RECUPERO_API}/impiantoCdr/listaByCdr/${idCdr}`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.get(url, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function autoSuggerimentoRicercaComune(nomeParzialeComune) {
	const url = `${PEPS_API}produttore/comuni/${nomeParzialeComune}`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.get(url, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function salvaImpianto(dto) {
	const url = `${RECUPERO_API}/impianto/salva`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.post(url, dto, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function eliminaImpianto(idImpianto) {
	const url = `${RECUPERO_API}/impianto/delete/${idImpianto}`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.delete(url, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function salvaAssociazioneImpianto(dto) {
	const url = `${RECUPERO_API}/impiantoCdr/salva`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.post(url, dto, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function eliminaAssociazioneImpianto(idImpiantoCdr) {
	const url = `${RECUPERO_API}/impiantoCdr/delete/${idImpiantoCdr}`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.delete(url, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function caricaImpianto(id) {
	const url = `${RECUPERO_API}/impianto/load/${id}`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.get(url, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

// gestione parametri

export function caricaListaParametri(idCdr, tipoParametro) {
	const url = `${RECUPERO_API}/dichiarazione_param/list/${idCdr}/${tipoParametro}`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.get(url, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function salvaParametri(dto) {
	const url = `${RECUPERO_API}/dichiarazione_param`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.put(url, dto, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function caricaSingoloParametro(idDichiarazioneParametro) {
	const url = `${RECUPERO_API}/dichiarazione_param/load/${idDichiarazioneParametro}`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.get(url, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function eliminaSingoloParametro(idDichiarazioneParametro) {
	const url = `${RECUPERO_API}/dichiarazione_param/delete/${idDichiarazioneParametro}`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.delete(url, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

// gestione cdr

export function caricaListaCdrAssociati(idCdr) {
	const url = `${RECUPERO_API}/cdr_cdr/lista/${idCdr}`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.get(url, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => reject(error));
	});
}

export function caricaSingoloCdr(idCdr) {
	const url = `${RECUPERO_API}/cdr_cdr/load/${idCdr}`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.get(url, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => reject(error));
	});
}

export function salvaCdr(dto) {
	const url = `${RECUPERO_API}/cdr_cdr/salva`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.post(url, dto, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => reject(error));
	});
}

export function eliminaSingoloCdr(idAssociazioneCdr) {
	const url = `${RECUPERO_API}/cdr_cdr/delete/${idAssociazioneCdr}`;
	const config = { headers: { Authorization: `Bearer ${getAccessTokenCookie()}` } };
	return new Promise((resolve, reject) => {
		return axios
			.delete(url, config)
			.then((res) => {
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
}
