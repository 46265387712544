import { useState } from "react";
import { removeAccessTokenCookie } from "../../common/cookie.js";
import { PORTAL_URL } from "../../common/env.js";
import { setGlobalState, useGlobalStateContext } from "../../context/GlobalContext.js";
import { ArrowLeft, List } from "react-bootstrap-icons";
import Sidebar from "./Sidebar/Sidebar.js";

export default function Header() {
	const { menuLaterale, user } = useGlobalStateContext();
	const [idSelezionato, setIdSelezionato] = useState(null);

	const commutaMenu = () => {
		let m = { ...menuLaterale };
		m.isAperto = !menuLaterale.isAperto;
		setGlobalState({ menuLaterale: m });
		setIdSelezionato(null);
	};

	const faiLogout = () => {
		if (user != null) {
			removeAccessTokenCookie();
			setGlobalState({ user: null });
			document.location.href = `${PORTAL_URL}`;
		}
	};

	return (
		<header id="header" className="clearfix">
			<ul className="h-inner">
				<li className="hi-trigger">
					<div>
						{!menuLaterale.isAperto ? (
							<List
								id="idList"
								size={35}
								style={{
									padding: "5px 2px 0 0",
									margin: "5px 10px 0 10px",
									backgroundColor: "transparent",
									color: "white",
									cursor: "pointer",
								}}
								onMouseDown={() => commutaMenu()}
							/>
						) : (
							<>
								<ArrowLeft
									id="idArrowLeft"
									size={35}
									style={{
										padding: "5px 2px 0 0",
										margin: "5px 10px 0 10px",
										backgroundColor: "transparent",
										color: "white",
										cursor: "pointer",
									}}
									onMouseDown={() => commutaMenu()}
								/>
								<Sidebar open={menuLaterale.isAperto} items={menuLaterale.lista} roles={user} />
							</>
						)}
					</div>
				</li>
				<li className="hi-logo hidden-xs">
					<a href={PORTAL_URL}>
						<img src="img/logo-corepla.png" alt="logo-corepla" style={{ paddingRight: "30px" }} />
					</a>
				</li>
				<li className="pull-right">
					<ul className="hi-menu">
						<li>
							<a className="site-tagline" href={PORTAL_URL}>
								Corepla Portal{" "}
							</a>
						</li>
					</ul>
				</li>
			</ul>
		</header>
	);
}
