import { useEffect, useState } from "react";
import { Modal, ModalFooter, ModalHeader, ModalBody, Button, Progress } from "reactstrap";
import axios from "axios";
import { RECUPERO_API } from "../../common/env";
import { getAccessTokenCookie } from "../../common/cookie";
import Toast, { TOAST_DANGER, TOAST_SUCCESS } from "../comuni/Toast";
import moment from "moment";

export default function ModaleMaterialeNonConformeCdr(props) {
	const [file, setFile] = useState(null);
	const [isUploading, setIsUploading] = useState(false);
	const [caricamentoPercentuale, setCaricamentoPercentuale] = useState(0);
	const [messageToast, setMessageToast] = useState({
		text: "",
		isVisible: false,
		type: "",
	});

	useEffect(() => {
		setFile(null);
		setIsUploading(false);
		setCaricamentoPercentuale(0);
	}, [props.isOpen]);

	const titoloModale = (statoNc) => {
		if (statoNc == "NONE") {
			return (
				<h4>La non conformità è in attesa di essere visionata da parte del Recupero energetico</h4>
			);
		}
		if (statoNc == "NON_APPROVATA") {
			return (
				<>
					<h4>La non conformità è stata rifiutata dal recupero energetico.</h4>
					<br />
					<h5>Inserisci una nuova quantità con relativa nota</h5>
				</>
			);
		}
		if (statoNc == "APPROVATA") {
			return <h4>Dettagli materiale non conforme</h4>;
		}
	};

	const esegui = () => {
		if (props.testo === "" || props.testo === null) {
			setMessageToast({
				text: "La nota giustificativa è obbligatoria",
				isVisible: true,
				type: TOAST_DANGER,
			});
			return;
		} else {
			caricaPdf(file)
				.then((res) => {
					if (res.status === 200 && res.data.data.length > 0) {
						setMessageToast({
							text: "Caricamento avvenuto con successo!",
							isVisible: true,
							type: TOAST_SUCCESS,
						});
					}
				})
				.catch((error) => {
					if (error) {
						setMessageToast({
							text: "Inserire almeno un allegato!",
							isVisible: true,
							type: TOAST_DANGER,
						});
						return;
					}
				});
		}
	};

	const aggiornamentoFile = (e) => {
		setFile(e.target.files[0]);
	};

	function caricaPdf(file) {
		setIsUploading(true);
		const formData = new FormData();
		formData.append("file", file);
		const url = `${RECUPERO_API}/dichiarazione/allegato/upload`;
		const options = {
			onUploadProgress: (ProgressEvent) => {
				const { loaded, total } = ProgressEvent;
				let percentuale = Math.floor((loaded * 100) / total);
				if (percentuale < 100) {
					setCaricamentoPercentuale(percentuale);
				}
			},
		};
		return new Promise((resolve, reject) => {
			return axios
				.post(
					url,
					formData,
					{
						headers: { Authorization: `Bearer ${getAccessTokenCookie()}` },
						"Content-Type": "multipart/form-data",
					},
					options
				)
				.then((res) => {
					let nomeEasset = res.data.data.split(";");
					let nomeDellAsset = nomeEasset[0];
					let nomeDelFile = nomeEasset[1];
					let dettagliAllegato = {
						id: null,
						nomeFile: nomeDelFile,
						idAsset: nomeDellAsset,
						tipoAllegato: 2,
					};
					props.onAllegatoAdd(dettagliAllegato);
					setCaricamentoPercentuale(100);
					resolve(res);
				})
				.catch((error) => {
					setIsUploading(false);
					reject(error);
				});
		});
	}
	return (
		<>
			<Modal
				isOpen={props.isOpen}
				size="lg"
				role="dialog"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<ModalHeader>
					{props.dettagliNc == null ? (
						<h4>Stai inserendo del materiale non conforme</h4>
					) : (
						titoloModale(props.dettagliNc.statoNc)
					)}
				</ModalHeader>
				<ModalBody>
					{props.dettagliNc == null ? (
						<p>
							Allegare un documento riferito alla Non Conforrmità (es. una scansione dei formulari
							in ingresso dal CSS ed eventuali foto) e una nota per motivare la quantità inserita
						</p>
					) : null}
					{!props.solaLettura ? (
						<>
							<label>
								<b>Stato non conformità: {props.dettagliNc?.statoNc || "Nuova"}</b>
							</label>
							<br />
							<input
								type="file"
								id="file-import"
								name="file-import"
								accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .odt, .tiff"
								onChange={(e) => {
									aggiornamentoFile(e);
								}}
							/>
							{props.hasprogressbar ? (
								<>
									<Progress
										style={{ margin: "10px 0px" }}
										value={caricamentoPercentuale}
										animated
										color="success">
										{caricamentoPercentuale}%
									</Progress>
								</>
							) : null}
							<label>
								Allegati:
								<br />
								{props.storicoAllegati.map((e) => {
									if (e.tipoAllegato == 2) {
										return (
											<span>
												&nbsp;
												<a
													target="_blank"
													href={`${props.api}/dichiarazione/allegato/download/${e.idAsset}?namespace=data/dichiarazione/CDR-${props.cdrId}&_a=${props.token}`}>
													{e.nomeFile}
												</a>
												<br />
											</span>
										);
									}
								})}
							</label>
							<br />
							{props.dettagliNc?.statoNc == "NON_APPROVATA" ? (
								<>
									<label>Nota rifiuto Recupero energetico</label>
									<textarea className="form-control" readOnly value={props.testoRifiutoRec} />
								</>
							) : null}
							<br />
							<label>Quantità di materiale non conforme</label>
							<input
								type="number"
								className="form-control"
								min="0"
								readOnly={props.dettagliNc?.statoNc !== "NON_APPROVATA"}
								value={props.qtaMateriale}
								onChange={(e) => {
									props.modificaQtaMateriale(e.target.value);
								}}
							/>
							<label>Nota</label>
							<textarea
								className="form-control"
								readOnly={props.solaLettura}
								value={props.testo}
								onChange={(e) => props.scritturaNota(e.target.value)}></textarea>
						</>
					) : (
						<>
							{props.dettagliNc?.statoNc == "APPROVATA" ? (
								<>
									<label>
										<b>
											Data chiusura: {moment(props.dettagliNc.dataChiusura).format("DD-MM-YYYY")}
										</b>
										<br />
										<b>Stato non conformità: {props.dettagliNc.statoNc}</b>
									</label>
								</>
							) : null}
							<br />
							<label>
								Allegati:
								<br />
								{props.storicoAllegati.map((e) => {
									if (e.tipoAllegato == 2) {
										return (
											<span>
												&nbsp;
												<a
													target="_blank"
													href={`${props.api}/dichiarazione/allegato/download/${e.idAsset}?namespace=data/dichiarazione/CDR-${props.cdrId}&_a=${props.token}`}>
													{e.nomeFile}
												</a>
												<br />
											</span>
										);
									}
								})}
							</label>
							<br />
							<label>Quantità di materiale non conforme originale</label>
							<input
								type="number"
								className="form-control"
								readOnly
								value={props.dettagliNc.materialeNcOriginale}
							/>
							{props.dettagliNc?.statoNc == "APPROVATA" ? (
								<>
									<label>Quantità di materiale non conforme approvata</label>
									<input
										type="number"
										className="form-control"
										readOnly
										value={props.dettagliNc.materialeNcApprovato}
									/>
									<label>Nota Recupero energetico</label>
									<textarea
										className="form-control"
										readOnly
										value={props.dettagliNc.noteCorepla}
									/>
								</>
							) : null}
							<label>Nota CDR</label>
							<textarea className="form-control" readOnly value={props.dettagliNc.noteCdr} />
						</>
					)}
					<br />
				</ModalBody>
				<ModalFooter>
					{props.solaLettura ? null : (
						<button
							disabled={isUploading}
							className="btn btn-primary"
							onClick={() => {
								esegui();
							}}>
							Conferma
						</button>
					)}
					<Button onClick={props.onExit}>{props.solaLettura ? "Chiudi" : "Annulla"}</Button>
				</ModalFooter>
				<Toast
					isVisible={messageToast.isVisible}
					type={messageToast.type}
					text={messageToast.text}
					onClose={() => {
						setMessageToast({
							text: "",
							isVisible: false,
							type: "",
						});
					}}></Toast>
			</Modal>
		</>
	);
}
