import { Modal, ModalFooter, ModalHeader, Button } from "reactstrap";

export default function ModaleModificaInCorso(props) {
	return (
		<Modal
			isOpen={props.isOpen}
			size="lg"
			role="dialog"
			aria-labelledby="contained-modal-title-vcenter"
			centered>
			<ModalHeader>
				<h4>Ci sono modifiche in corso per il mese corrente, procedere comunque?</h4>
			</ModalHeader>
			<ModalFooter>
				<button className="btn btn-primary" onClick={props.dichiarazione}>
					Vai al mese selezionato
				</button>
				<Button onClick={props.onExit}>Annulla</Button>
			</ModalFooter>
		</Modal>
	);
}
