import { Modal, ModalFooter, ModalHeader, ModalBody, Button } from "reactstrap";

export default function ModaleInviaCdrDaRec(props) {
	return (
		<Modal
			isOpen={props.isOpen}
			size="lg"
			role="dialog"
			aria-labelledby="contained-modal-title-vcenter"
			centered>
			<ModalHeader>
				<h4>
					La dichiarazione andrà in revisione da parte del Centro di Recupero Corepla. Confermi
					l'operazione?
				</h4>
			</ModalHeader>
			<ModalBody>
				<h6>Nota opzionale</h6>
				<textarea
					className="form-control"
					value={props.testo}
					onChange={(e) => props.scritturaNota(e.target.value)}></textarea>
			</ModalBody>
			<ModalFooter>
				<button className="btn btn-primary" onClick={props.conferma}>
					Conferma
				</button>
				<Button onClick={props.onExit}>Annulla</Button>
			</ModalFooter>
		</Modal>
	);
}
