import React from "react";
import { Modal, ModalHeader, ModalFooter, Button } from "reactstrap";

const ModaleConfermaEliminazione = (props) => {
	return (
		<>
			<Modal
				isOpen={props.isOpen}
				size="lg"
				role="dialog"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				backdrop="static"
				keyboard={true}>
				<ModalHeader>
					<h4>Sicuro di voler eliminare l'elemento selezionato?</h4>
				</ModalHeader>
				<ModalFooter>
					<button
						className="btn btn-danger"
						onClick={() => {
							props.elimina();
						}}>
						Conferma
					</button>
					<Button
						onClick={() => {
							props.onExit();
						}}>
						Annulla
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default ModaleConfermaEliminazione;
