import React, { useState, useEffect } from "react";
import moment from "moment";
import { useGlobalStateContext } from "../context/GlobalContext";
import { userCdrInit, caricaListaCdr, caricaListaParametri, salvaParametri, caricaSingoloParametro, eliminaSingoloParametro} from "../actions/portalApi";
import { controlloSceltaParametri } from "../functions/gestioneParametriFunctions";
import { PARAMETRI } from "../common/constants";
import Toast, {TOAST_DANGER, TOAST_SUCCESS} from "../components/comuni/Toast";
import ModaleConfermaEliminazione from "../components/comuni/ModaleConfermaEliminazione";
import ModaleValoriCdr from "../components/gestioneParametri/ModaleValoriCdr";
import ModaleModificaValoriCdr from "../components/gestioneParametri/ModaleModificaValoriCdr";

export default function GestioneParametri() {
  const user = useGlobalStateContext();
  const [listaCdr, setListaCdr] = useState(null);
  const [listaParametri, setListaParametri] = useState(PARAMETRI);
  const [mostraModaleValoriCdr, setModaleValoriCdr] = useState(false);
  const [mostraModaleModificaValoriCdr, setMostraModaleModificaValoriCdr] = useState(false);
  const [mostraModaleConfermaEliminazione, setMostraModaleConfermaEliminazione] = useState(false);
  const [caricaBottonePremuto, setCaricaBottonePremuto] = useState(false);
  const [indice, setIndice] = useState(null);
  const [indiceCdr, setIndiceCdr] = useState(null);
  const [tipoParametro, setTipoParametro] = useState(null);
  const [centroDiRecupero, setCentroDiRecupero] = useState({
    id: null,
    idCdr: null,
    tipoParametro: null,
    valore: null,
    dataInizioValidita: null,
    dataFineValidita: null
  })
  const [cdrClone, setCdrClone] = useState(null);
  const [messageToast, setMessageToast ] = useState({
    text: "",
    isVisible: false,
    type: ""
  });

    useEffect(() => {
      window.onbeforeunload = null;
      window.history.replaceState({}, '', '/#/gestione-parametri');
      ruoloUtenteRec();
    }, []);

    const ruoloUtenteRec = () => {
      user.user.ruoli.map((e)=> {
        if (e.codiceRuolo != 'REC_CDR_SUPERVISOR' && e.codiceRuolo != 'REC_CDR_USER') {
          userCdrInit().then((res)=> {
            if (res != null) {
             caricaListaCdr().then((res)=> {
              if (res !== null){
                setListaCdr(res.data.data);
              }
            })
            }
          })
          }
      })
    }
    const aggiorna = (chiave, valore) => {
      let cdr = {...centroDiRecupero};
      cdr[chiave] = valore;
      setCentroDiRecupero(cdr);
    } 
    
    const renderListaCdr = () => {
      if (listaCdr != null && listaCdr.length > 0) {
        return (
          <select 
          className="form-control" 
          value={centroDiRecupero.idCdr } 
          onChange={(e)=> {
            aggiorna("idCdr", e.target.value)}}
          required
          disabled={caricaBottonePremuto}
          >
            <option key={ "" } value={ "" }>-- Seleziona CDR --</option>
            {listaCdr.map((cdr) => {
              return <option key={ cdr.organizationId } value={cdr.organizationId}>{ cdr.nome }</option>
            })}
          </select>
        )
      }
    }

    const renderTipologiaParametri = () => {
      return (
        <select
        className="form-control"
        value={centroDiRecupero.tipoParametro}
        onChange={(e) => {aggiorna("tipoParametro", e.target.value)}}
        required
        disabled={caricaBottonePremuto}
        >
        {PARAMETRI.map((params) => {
          return <option key={params.valore} value={params.valore}>{params.testo}</option>
        })}
        </select>
      )
    }

    const reset = () =>{
      setCaricaBottonePremuto(false);
      setCentroDiRecupero({
        idCdr: "",
        tipoParametro: "",
      });
    }

    const renderForm = () => {
      let cdr = {...centroDiRecupero};
      let controllo = controlloSceltaParametri(cdr);
      if (!controllo.verificato) {
        setMessageToast({
          text: controllo.descrizioneErrore,
          isVisible: true,
          type: TOAST_DANGER
          })
          return;
      }
      caricaListaParametri(cdr.idCdr, cdr.tipoParametro).then((res) => {
        if (res.status == 200 && res != null) {
          setListaParametri(res.data.data);
          setCaricaBottonePremuto(true);
          setIndiceCdr(cdr.idCdr);
          setTipoParametro(cdr.tipoParametro);
        }
      }).catch((error) => {
        if (error) {
          setMessageToast({
          text: "Caricamento gestione parametri fallita",
          isVisible: true,
          type: TOAST_DANGER
          })
          return;
        }
      })
    }

    const renderFormValori = () => {
      return (
        <>
        <div className="form-group row">
          <div className="form-group col-md-4">
            <div className="btn-panel">
              <button className="btn btn-primary" onClick={() => {
                setModaleValoriCdr(true);
              }}> Inserisci </button>
            </div>
          </div>
          </div>
          <div className="tabella-parametri-wrapper">
          <table className="tabella-parametri">
            <thead>
              <tr>
                <th>Valori visualizzati</th>
                <th>Inizio</th>
                <th>Fine</th>
                <th colSpan={2}>Azioni</th>
              </tr>
            </thead>
            <tbody>
              {renderValori()}
            </tbody>
          </table>
          </div>
        </>
      )
    }

    const inserisciValoriDichiarazione = (dati) => {
      let cdr = {...centroDiRecupero};
      cdr.dataInizioValidita = dati.dataInizio;
      cdr.dataFineValidita = dati.dataFine;
      cdr.valore = dati.risultati;
      salvaParametri(cdr).then((res) => {
        if (res) {
          setMessageToast({
          text: "Salvataggio parametro avvenuto con successo",
          isVisible: true,
          type: TOAST_SUCCESS
          })
          caricaListaParametri(cdr.idCdr, cdr.tipoParametro).then((res) => {
            if (res != null) {
              setListaParametri(res.data.data);
            }
          })
        }
      }).catch((error) => {
        if (error) {
          setMessageToast({
          text: error.response.data.error.errorMsg,
          isVisible: true,
          type: TOAST_DANGER
          })
        }
      })
      setModaleValoriCdr(false);
    }

    const modificaValoriDichiarazione = (dati) => {
      let cdr = {...centroDiRecupero};
      cdr.id = dati.id;
      cdr.dataInizioValidita = dati.dataInizio;
      cdr.dataFineValidita = dati.dataFine;
      cdr.valore = dati.risultati;
      salvaParametri(cdr).then((res) => {
        if (res) {
          setMessageToast({
          text: "Modifica parametro avvenuta con successo",
          isVisible: true,
          type: TOAST_SUCCESS
          })
          caricaListaParametri(cdr.idCdr, cdr.tipoParametro).then((res) => {
            if (res != null) {
              setListaParametri(res.data.data);
            }
          })
        }
      })
      setMostraModaleModificaValoriCdr(false);
    }

    const apriModifica = (indice, tipoParametro) => {
      setTipoParametro(tipoParametro);
      caricaSingoloParametro(indice).then(async(res) => {
        if (res != null) {
          let dati = await res.data.data;
          setCdrClone(dati);
          setMostraModaleModificaValoriCdr(true);
        }
      })
    }
    
    const apriElimina = (indice) => {
      setIndice(indice);
      setMostraModaleConfermaEliminazione(true);
    }

    const eliminaValoriDichiarazione = () => {
      let id = indice;
      let {idCdr, tipoParametro} = {...centroDiRecupero};
      eliminaSingoloParametro(id).then((res) => {
        if (res) {
          setMessageToast({
            text: "Eliminazione avvenuta con successo",
            isVisible: true,
            type: TOAST_SUCCESS
          })
          setMostraModaleConfermaEliminazione(false);
          caricaListaParametri(idCdr, tipoParametro).then((res) => {
            if (res != null) {
              setListaParametri(res.data.data);
            }
          })
        }
      })
    }

    const visualizzaLista = (elem, tipoParametro) => {
      if (tipoParametro == PARAMETRI[1].valore) {
        let listaValori = JSON.parse(elem.valore).valore;
        return listaValori.map((e) => {
          return (<>{e}<br/></>)
        })
      }
      if (tipoParametro == PARAMETRI[2].valore) {
        let percentuale = JSON.parse(elem.valore).valore;
        return (<>{percentuale}</>)
      }
    }

    const renderValori = () => {
      if (listaParametri != null && listaParametri.length > 0) {
      return listaParametri.map((elem, _idx) => {
        return (
        <tr key={_idx}>
          <td>{visualizzaLista(elem, elem.tipoParametro)}</td>
          <td>{moment(elem?.dataInizioValidita).format("DD-MM-YYYY")}</td>
          <td>{moment(elem?.dataFineValidita).format("DD-MM-YYYY")}</td>
          <td><button className="btn btn-primary" onClick={() => {apriModifica(elem.id, elem.tipoParametro)}}>Modifica</button></td>
          <td><button className="btn btn-primary" onClick={() => {apriElimina(elem.id)}}>Elimina</button></td>
        </tr>
        )
      })
    } else {
      return (
        <tr>
          <td colSpan={5}>Non sono presenti valori per il CDR selezionato</td>
        </tr>
      )
    }
    }

    return (
      <>
        <div key="gestioneParametri" className="container-fluid">
          <div className="cor-card">
            <div className="cor-card-header">
              <h4>Gestione parametri</h4>
            </div>
            <div className="cor-card-body">
              <div className="fixed-header">
                  <div className="row form-group gestione-impianti-filter">
                  <div className="form-group col-md-4">
                    <div className="selection-panel" style={{ display: "block"}}>
                      <strong>CDR</strong>: <div>{ renderListaCdr() }</div>
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <div className="selection-panel" style={{ display: "block"}}>
                      <strong>Parametri</strong>: <div>{ renderTipologiaParametri() }</div>
                    </div>
                  </div>
                  <div className="form-group col-md-3">
                    <div className="btn-panel">
                      <button className="btn btn-primary" onClick={() => {renderForm()}}> Carica </button>
                      <button className="btn btn-primary" onClick={() => {reset()}}> Reset </button>
                    </div>
                  </div>
                  </div>
                </div>
                {caricaBottonePremuto ? renderFormValori():null}
              </div>
            </div>
            <ModaleValoriCdr 
            isOpen={mostraModaleValoriCdr}
            tipoParam={tipoParametro}
            conferma={async(data) => {
              let d = await data;
              inserisciValoriDichiarazione(d);
            }}
            onExit={() => {setModaleValoriCdr(false)}}
            />
            <ModaleModificaValoriCdr 
            isOpen={mostraModaleModificaValoriCdr}
            tipoParam={tipoParametro}
            clone={cdrClone}
            conferma={async(data) => {
              let d = await data;
              modificaValoriDichiarazione(d);
            }}
            onExit={() => {setMostraModaleModificaValoriCdr(false)}}
            />
            <ModaleConfermaEliminazione 
            isOpen={mostraModaleConfermaEliminazione}
            elimina={()=>{eliminaValoriDichiarazione()}}
            onExit={() => {
              setIndice(null);
              setMostraModaleConfermaEliminazione(false);
            }}/>
            <Toast isVisible={ messageToast.isVisible } type={messageToast.type} text={ messageToast.text } onClose={ () => {
              setMessageToast({
                text: "",
                isVisible: false,
                type: ""
              });
              } }
            />
          </div>
      </>
    )
}