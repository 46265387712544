import React, {useState, useEffect} from 'react'
import { useGlobalStateContext } from "../context/GlobalContext";
import moment from "moment";
import { caricaListaImpiantiPerCdr, caricaListaImpiantiPerTipologia, caricaImpianto, salvaAssociazioneImpianto, eliminaAssociazioneImpianto, userCdrInit } from "../actions/portalApi";
import { caricaListaCdr } from "../actions/portalApi";
import ModaleAssociaImpianto from "../components/gestioneImpiantiCdr/ModaleAssociaImpianto";
import ModaleModificaAssociaImpianto from "../components/gestioneImpiantiCdr/ModaleModificaAssociaImpianto";
import ModaleConfermaEliminazione from "../components/comuni/ModaleConfermaEliminazione";
import Toast, {TOAST_DANGER, TOAST_SUCCESS} from "../components/comuni/Toast";

export default function GestioneImpiantiPerCdr() {
  const user = useGlobalStateContext();
  const [listaCdr, setListaCdr] = useState(null);
  const [listaImpianti, setListaImpianti] = useState(null);
  const [listaImpiantiPerCdr, setListaImpiantiPerCdr] = useState(null);
  const [mostra, setMostra] = useState(false);
  const [modaleModificaAssociaImpianto, setMostraModaleModificaAssociaImpianto] = useState(false);
  const [mostraModaleAssociaImpianto, setMostraModaleAssociaImpianto] = useState(false);
  const [mostraModaleConfermaEliminazione, setMostraModaleConfermaEliminazione] = useState(false);
  const [impiantoId, setImpiantoId] = useState(null);
  const [impianto, setImpianto] = useState(null);
  const [centroDiRecupero, setCentroDiRecupero] = useState({
    id: ""
  })
  const [messageToast, setMessageToast ] = useState({
    text: "",
    isVisible: false,
    type: ""
  });

  useEffect(() => {
      window.onbeforeunload = null;
      window.history.replaceState({}, '', '/#/gestione-impianti-cdr');
      ruoloUtenteRec();
    }, []);

  const ruoloUtenteRec = () => {
    user.user.ruoli.map((e)=> {
      if (e.codiceRuolo != 'REC_CDR_SUPERVISOR' && e.codiceRuolo != 'REC_CDR_USER') {
        userCdrInit().then((res)=> {
          if (res != null){
            caricaListaImpiantiPerTipologia("").then(async(response) => {
              if (response != null){
                let dati = await response;
                setListaImpianti(dati.data.data)
              }
            })
            caricaListaCdr().then(async(res)=> {
              if (res != null){
                let lista = await res.data.data;
                setListaCdr(lista);
              }
            })
          }
        })
      }
    })
  }

  const aggiorna = (chiave, valore) => {
    let cdr = {...centroDiRecupero}
    cdr[chiave] = valore;
    setCentroDiRecupero(cdr);
  }

  const caricaImpiantiPerCdr = () => {
    let cdr = {...centroDiRecupero};
    caricaListaImpiantiPerCdr(cdr.id).then((res) => {
      if (res != null) {
        setMostra(true);
        setListaImpiantiPerCdr(res.data.data);
      }
    })
  }

  const listaCentriDiRecupero = () => {
    if (listaCdr != null && listaCdr.length > 0){
      return (
        <select className="form-control" value={centroDiRecupero.id} onChange={ (e) => {
        aggiorna("id", e.target.value);} } required>
          <option key={ "" } value={ "" }>-- Seleziona CDR --</option>
          {listaCdr.map((cdr) => {
            return <option key={ cdr.organizationId } value={cdr.organizationId}>{ cdr.nome }</option>
          })}
        </select>
      )
    } else {
      return null
    }
  }

  const renderListaImpiantiFiltrata = () => {
    if (listaImpiantiPerCdr != null && listaImpiantiPerCdr.length > 0) {
      return listaImpiantiPerCdr.map((imp, _idx) => {
        return (
        <tr key={_idx}>
          <td>{imp.impianto.ragioneSociale}</td>
          <td>{imp.impianto.tipoImpianto}</td>
          <td>{imp.impianto.indirizzo} - {imp.impianto.cap}, {imp.impianto.comuneNome}, ({imp.impianto.provincia})</td>
          <td>{moment(imp.dataInizioValidita).format("DD-MM-YYYY")}</td>
          <td>{moment(imp.dataFineValidita).format("DD-MM-YYYY")}</td>
          <td>
            {imp.gestoreContratto != null && imp.gestoreContratto == 1
              ? "CDR"
              : imp.gestoreContratto != null && imp.gestoreContratto == 2
              ? "Impianto"
              : "-"}
          </td>
          <td><button className="btn btn-primary" onClick={() => {modificaAssociazioneImpianto(imp.impianto.id, imp.dataInizioValidita, imp.dataFineValidita, imp.gestoreContratto, imp.id);}}>Modifica</button></td>
          <td><button className="btn btn-primary" onClick={() => {apriEliminaAssociazioneImpianto(imp.id)}}>Elimina</button></td>
        </tr>
        )
      })
    } else {
      return (
        <tr>
          <td colSpan={8}>Non sono presenti impianti per il CDR selezionato</td>
        </tr>
      )
    }
  }

  const associaImpianto = (datiImpianto) => {
    let cdrId = {...centroDiRecupero}
    let arrayCdr = [...listaCdr];
    let cdr = arrayCdr.find((x) => cdrId.id == x.organizationId);
    let dto = {
      id: null,
      idCdr: cdr.organizationId,
      nomeCdr: cdr.nome,
      impianto: {
        id: datiImpianto.id,
        ragioneSociale: datiImpianto.ragioneSociale,
        tipoImpianto: datiImpianto.tipoImpianto
      },
      dataInizioValidita: datiImpianto.dataInizio,
      dataFineValidita: datiImpianto.dataFine,
      gestoreContratto: Number(datiImpianto.gestoreContratto), 
      autorizzato: true
    }
    salvaAssociazioneImpianto(dto).then((res)=> {
      if (res) {
        setMessageToast({
          text: "Impianto associato con successo",
          isVisible: true,
          type: TOAST_SUCCESS
        })
        let cdrID = {...centroDiRecupero}
        caricaListaImpiantiPerCdr(cdrID.id).then(async(res) => {
          if (res != null) {
            let risposta = await res;
            setListaImpiantiPerCdr(risposta.data.data);
          }
        })        
      }
    }).catch((err) => {
        setMessageToast({
          text: err.response.data.error.errorMsg,
          isVisible: true,
          type: TOAST_DANGER
        })
      })     
  }

  const confermaModificaAssociaImpianto = (datiImpianto) => {
    let cdrId = {...centroDiRecupero}
    let arrayCdr = [...listaCdr];
    let cdr = arrayCdr.find((x) => cdrId.id == x.organizationId);
    let dto = {
      id: datiImpianto.idAssociazione,
      idCdr: Number(cdr.organizationId),
      nomeCdr: cdr.nome,
      impianto: {
        id: datiImpianto.id,
        ragioneSociale: datiImpianto.ragioneSociale,
        tipoImpianto: datiImpianto.tipoImpianto
      },
      dataInizioValidita: datiImpianto.dataInizioValidita,
      dataFineValidita: datiImpianto.dataFineValidita,
      gestoreContratto: Number(datiImpianto.gestoreContratto), 
      autorizzato: true
    }
    salvaAssociazioneImpianto(dto).then((res)=> {
      if (res) {
        setMessageToast({
          text: "Modifica associazione impianto avvenuta con successo",
          isVisible: true,
          type: TOAST_SUCCESS
        })
        caricaListaImpiantiPerCdr(cdrId.id).then(async(res) => {
          if (res != null) {
            let risposta = await res;
            setListaImpiantiPerCdr(risposta.data.data);
          }
        })
      }
    }).catch((err) => {if (err) {
      setMessageToast({
          text: err.response.data.error.errorMsg,
          isVisible: true,
          type: TOAST_DANGER
        })
    }})
  }
  
  const modificaAssociazioneImpianto = (idImpianto, dataIni, dataFin, gContratto, idAssociazioneImpianto) => {
    caricaImpianto(idImpianto).then(async(res)=> {
      if (res != null) {
        let dati = await res.data.data;
        let {cap, cebisVendorId, cebisVendorSiteId, comuneCodIstat, comuneNome, email, telefono, website, provincia, nazione, indirizzo, ...impianto} = dati;
        impianto.dataInizio = dataIni;
        impianto.dataFine = dataFin;
        impianto.gestoreContratto = gContratto;
        impianto.idAssociazione = idAssociazioneImpianto;
        setImpianto(impianto);
        setMostraModaleModificaAssociaImpianto(true);
      } else {
        setMessageToast({
          text: "Errore nell'apertura della modifica impianto",
          isVisible: true,
          type: TOAST_DANGER
        })
      }
    })
  }

  const apriEliminaAssociazioneImpianto = (idImpianto) =>{
    setImpiantoId(idImpianto);
    setMostraModaleConfermaEliminazione(true);
  }

  const eliminaAssocImpianto = () => {
    let id = impiantoId;
    eliminaAssociazioneImpianto(id).then(async(res) => {
      const response = await res;
      if (response) {
        setMessageToast({
          text: "Associazione eliminata con successo",
          isVisible: true,
          type: TOAST_SUCCESS
        })
        let cdrId = {...centroDiRecupero};
        caricaListaImpiantiPerCdr(cdrId.id).then(async(res) => {
          setMostraModaleConfermaEliminazione(false);
          if (res != null) {
            let risposta = await res;
            setListaImpiantiPerCdr(risposta.data.data);
          }
        })
      }
    })
  }
  
  return (
  <>
    <div key="associazioneImpianti" className="container-fluid">
    <div className="cor-card">
      <div className="cor-card-header">
        <h4>Associazione impianti</h4>
      </div>
      <div className="cor-card-body">
        <div className="fixed-header">
          <div className="row form-group gestione-impianti-filter">
            <div className="col-md-4">
              <div className="selection-panel" style={{ display: "block"}}>
                <strong>CDR</strong>: <div>{ listaCentriDiRecupero() }</div>
              </div>
            </div>
            <div className="col-md-4" style={{ "textAlign": "right"}}>
              <div className="btn-panel">
                <button className="btn btn-primary" onClick={() => {caricaImpiantiPerCdr()}}> Carica </button>
                {mostra ? <button className="btn btn-primary" onClick={() => {setMostraModaleAssociaImpianto(true)}}> Associa nuovo impianto </button> : null}
              </div>
            </div>
          </div>
        </div>
        {mostra ? 
          <div className="tabella-impianti-wrapper">
            <table className="tabella-impianti">
              <thead>
                <tr>
                  <th>Ragione Sociale</th>
                  <th>Tipo impianto</th>
                  <th>Indirizzo</th>
                  <th>Data Inizio</th>
                  <th>Data Fine</th>
                  <th>Gestore contratto</th>
                  <th colSpan={2}>Azioni</th>
                </tr>
              </thead>
              <tbody>{renderListaImpiantiFiltrata()}</tbody>
              </table>
          </div>
        :
        null}
        </div>
      </div>
      <ModaleAssociaImpianto 
        isOpen={mostraModaleAssociaImpianto}
        listaTotaleImpianti={listaImpianti}
        conferma={async(dati) => {
          associaImpianto(dati);
          setMostraModaleAssociaImpianto(false)}}
        onExit={() => {
          setMostraModaleAssociaImpianto(false);
      }}/>
      <ModaleModificaAssociaImpianto
        isOpen={modaleModificaAssociaImpianto}
        listaTotaleImpianti={listaImpianti}
        oggettoImpianto={impianto}
        conferma={async(dati) => {
          confermaModificaAssociaImpianto(dati);
          setMostraModaleModificaAssociaImpianto(false)}}
        onExit={() => {
        setMostraModaleModificaAssociaImpianto(false);}} 
      />
      <ModaleConfermaEliminazione 
        isOpen={mostraModaleConfermaEliminazione}
        elimina={()=>{eliminaAssocImpianto()}}
        onExit={() => {
          setImpiantoId(null);
          setMostraModaleConfermaEliminazione(false);
        }}
      />
      <Toast isVisible={ messageToast.isVisible } type={messageToast.type} text={ messageToast.text } onClose={ () => {
        setMessageToast({
          text: "",
          isVisible: false,
          type: ""
        });
        } }
      />
    </div>
  </>
  )
}

