import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import moment from "moment";
import { verificaMeseAnno } from "../../functions/gestioneParametriFunctions";
import { PARAMETRI, MESI } from "../../common/constants";
import Toast, { TOAST_DANGER, TOAST_SUCCESS } from "../comuni/Toast";

const ModaleModificaValoriCdr = (props) => {
	const [parametri, setParametri] = useState({
		id: null,
		dataInizio: null,
		dataFine: null,
		meseInizio: null,
		annoInizio: null,
		meseFine: null,
		annoFine: null,
		risultati: null,
	});
	const [percentualePerditaProcesso, setPercentualePerditaProcesso] = useState(null);
	const [valoriCheck, setValoriCheck] = useState({
		"04_MATERIALE_NON_CONFORME": true,
		"05_CSS_CDR_PRODOTTO": true,
		"06_CSS_C_PRODOTTO": true,
		"07_METALLI_SEPARATI": true,
		"08_SCARTI_DI_PROCESSO": true,
		"09_PERDITE_DI_PROCESSO": true,
		"12_USCITE_METALLI_COREPLA": true,
		"15_INGRESSI_EXTRA_COREPLA": true,
		"16_USCITE_EXTRA_COREPLA": true,
		"17_AVVIO_A_RICICLO": true,
		"18_AUTO_RICICLO": true,
		"19_USCITE_AVVIO_A_RICICLO": true,
		"20_USCITE_AUTO_RICICLO": true,
	});
	const [messageToast, setMessageToast] = useState({
		text: "",
		isVisible: false,
		type: "",
	});

	useEffect(() => {
		if (props.clone != null && props.tipoParam != null) {
			// valori dichiarazione
			if (props.tipoParam == PARAMETRI[1].valore) {
				setParametri({
					id: props.clone.id,
					dataInizio: props.clone.dataInizioValidita,
					dataFine: props.clone.dataFineValidita,
					meseInizio: moment(props.clone.dataInizioValidita).format("M"),
					annoInizio: moment(props.clone.dataInizioValidita).format("YYYY"),
					meseFine: moment(props.clone.dataFineValidita).format("M"),
					annoFine: moment(props.clone.dataFineValidita).format("YYYY"),
					risultati: null,
				});
				let arrayValoriSpunte = JSON.parse(props.clone.valore).valore;
				let oggettoSpunteRisposte = {};
				let spunteDefault = Object.keys(valoriCheck);
				for (let i = 0; i < spunteDefault.length; i++) {
					for (let j = 0; j < arrayValoriSpunte.length; j++) {
						if (spunteDefault[i] == arrayValoriSpunte[j]) {
							oggettoSpunteRisposte[spunteDefault[i]] = true;
						}
						if (
							spunteDefault[i] != arrayValoriSpunte[i] &&
							oggettoSpunteRisposte[spunteDefault[i]] != true
						) {
							oggettoSpunteRisposte[spunteDefault[i]] = false;
						}
					}
				}
				setValoriCheck(oggettoSpunteRisposte);
			}
			// perdita di processo
			if (props.tipoParam == PARAMETRI[2].valore) {
				let percentuale = Number(JSON.parse(props.clone.valore).valore);
				setPercentualePerditaProcesso(percentuale);
				setParametri({
					id: props.clone.id,
					dataInizio: props.clone.dataInizioValidita,
					dataFine: props.clone.dataFineValidita,
					meseInizio: moment(props.clone.dataInizioValidita).format("M"),
					annoInizio: moment(props.clone.dataInizioValidita).format("YYYY"),
					meseFine: moment(props.clone.dataFineValidita).format("M"),
					annoFine: moment(props.clone.dataFineValidita).format("YYYY"),
					risultati: null,
				});
			}
		}
	}, [props.isOpen]);

	const inserisci = () => {
		let p = { ...parametri };
		let v = { ...valoriCheck };
		let res = {
			valore: [],
		};

		let controllo = verificaMeseAnno(p);
		if (!controllo.verificato) {
			setMessageToast({
				text: controllo.descrizioneErrore,
				isVisible: true,
				type: TOAST_DANGER,
			});
			return;
		}

		if (props.tipoParam == PARAMETRI[2].valore) {
			if (p.risultati == "") {
				setMessageToast({
					text: "Per favore inserire la perdita di processo",
					isVisible: true,
					type: TOAST_DANGER,
				});
				return;
			}
		}

		if (props.tipoParam == PARAMETRI[1].valore) {
			for (const chiave in v) {
				if (v[chiave] == true) {
					res.valore.push(chiave);
				}
			}
			p.risultati = JSON.stringify(res);
		}
		p.dataInizio = moment()
			.year(p.annoInizio)
			.month(p.meseInizio - 1)
			.format("YYYY-MM-01");
		p.dataFine =
			moment()
				.year(p.annoFine)
				.month(p.meseFine - 1)
				.format("YYYY-MM-") +
			moment()
				.month(p.meseFine - 1)
				.daysInMonth();
		let { meseInizio, meseFine, annoInizio, annoFine, ...dto } = p;
		props.conferma(dto);
	};

	const chiudiModale = () => {
		props.onExit();
	};

	const aggiornaSpunte = (chiave, valore) => {
		let v = { ...valoriCheck };
		if (v[chiave] == Boolean(valore)) {
			v[chiave] = Boolean(!valore);
		} else {
			v[chiave] = Boolean(valore);
		}
		setValoriCheck(v);
	};

	const aggiornaDate = (chiave, valore) => {
		let p = { ...parametri };
		if (chiave == "meseInizio") {
			p[chiave] = valore;
		}
		if (chiave == "meseFine") {
			p[chiave] = valore;
		}
		if (chiave == "annoInizio") {
			p[chiave] = valore;
		}
		if (chiave == "annoFine") {
			p[chiave] = valore;
		}
		setParametri(p);
	};

	const aggiornaPerditaProcesso = (valore) => {
		let p = { ...parametri };
		let res = {
			valore: "",
		};
		res.valore = valore;
		p.risultati = JSON.stringify(res);
		setParametri(p);
		setPercentualePerditaProcesso(valore);
	};

	const listaMesi = (chiave) => {
		return (
			<select
				className="form-control"
				defaultValue={parametri[chiave]}
				onChange={(e) => {
					aggiornaDate(chiave, e.target.value);
				}}
				required>
				<option key={"mesi-empty"} value={""}>
					-- Seleziona mese --
				</option>
				{MESI.map((m) => {
					return (
						<option key={m.valore} value={m.valore}>
							{m.testo}
						</option>
					);
				})}
			</select>
		);
	};

	const listaAnni = (chiave) => {
		let annoCorrente = new Date().getFullYear();
		let anni = [];
		for (let k = -3; k < 2; k++) {
			anni.push(annoCorrente + k);
			anni.sort((a, b) => b - a);
		}
		return (
			<select
				className="form-control"
				defaultValue={parametri[chiave]}
				onChange={(e) => {
					aggiornaDate(chiave, e.target.value);
				}}
				required>
				<option key={"anni-empty"} value={""}>
					-- Seleziona anno --
				</option>
				{anni.map((a) => {
					return (
						<option key={a} value={a}>
							{a}
						</option>
					);
				})}
			</select>
		);
	};

	const renderDate = (mese, anno) => {
		return (
			<>
				<div className="form-row">
					<div className="col-md-6">
						<div className="selection-panel" style={{ display: "block" }}>
							Mese:<div>{listaMesi(mese)}</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="selection-panel" style={{ display: "block" }}>
							Anno:<div>{listaAnni(anno)}</div>
						</div>
					</div>
				</div>
				<br />
			</>
		);
	};

	return (
		<>
			<Modal
				isOpen={props.isOpen}
				size="lg"
				role="dialog"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				backdrop="static"
				keyboard={true}>
				<ModalHeader>
					<h4>
						{props.tipoParam != null && props.tipoParam == PARAMETRI[1].valore
							? "Inserimento parametri CDR"
							: null}
					</h4>
					<h4>
						{props.tipoParam != null && props.tipoParam == PARAMETRI[2].valore
							? "Inserimento perdita di processo"
							: null}
					</h4>
				</ModalHeader>
				<ModalBody>
					<>
						{props.tipoParam == PARAMETRI[1].valore ? (
							<>
								<span>
									<p>
										<i>Seleziona gli elementi da visualizzare nella dichiarazione del CDR</i>
									</p>
								</span>
								<div className="form-row">
									<div className="form-group col-md-6">
										<label>
											<strong>Data inizio</strong>
										</label>
										{renderDate("meseInizio", "annoInizio")}
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												id="materialeNonConforme"
												checked={valoriCheck["04_MATERIALE_NON_CONFORME"]}
												onChange={(e) => {
													aggiornaSpunte("04_MATERIALE_NON_CONFORME", e.target.value);
												}}
											/>
											<label class="form-check-label" for="materialeNonConforme">
												Materiale non conforme (4)
											</label>
										</div>
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												id="cssCdrProdotto"
												checked={valoriCheck["05_CSS_CDR_PRODOTTO"]}
												onChange={(e) => {
													aggiornaSpunte("05_CSS_CDR_PRODOTTO", e.target.value);
												}}
											/>
											<label class="form-check-label" for="cssCdrProdotto">
												CSS/CDR prodotto (5)
											</label>
										</div>
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												id="cssCProdotto"
												checked={valoriCheck["06_CSS_C_PRODOTTO"]}
												onChange={(e) => {
													aggiornaSpunte("06_CSS_C_PRODOTTO", e.target.value);
												}}
											/>
											<label class="form-check-label" for="cssCProdotto">
												CSS-C prodotto (6)
											</label>
										</div>
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												id="metalliSeparati"
												checked={valoriCheck["07_METALLI_SEPARATI"]}
												onChange={(e) => {
													aggiornaSpunte("07_METALLI_SEPARATI", e.target.value);
												}}
											/>
											<label class="form-check-label" for="metalliSeparati">
												{" "}
												Metalli separati (7)
											</label>
										</div>
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												id="scartiDiProcesso"
												checked={valoriCheck["08_SCARTI_DI_PROCESSO"]}
												onChange={(e) => {
													aggiornaSpunte("08_SCARTI_DI_PROCESSO", e.target.value);
												}}
											/>
											<label class="form-check-label" for="scartiDiProcesso">
												Scarti di processo (8)
											</label>
										</div>
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												id="perditeDiProcesso"
												checked={valoriCheck["09_PERDITE_DI_PROCESSO"]}
												onChange={(e) => {
													aggiornaSpunte("09_PERDITE_DI_PROCESSO", e.target.value);
												}}
											/>
											<label class="form-check-label" for="perditeDiProcesso">
												Perdite di processo (9)
											</label>
										</div>
									</div>
									<div className="form-group col-md-6">
										<label>
											<strong>Data fine</strong>
										</label>
										{renderDate("meseFine", "annoFine")}
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												id="avvioRiciclo"
												checked={valoriCheck["17_AVVIO_A_RICICLO"]}
												onChange={(e) => {
													aggiornaSpunte("17_AVVIO_A_RICICLO", e.target.value);
												}}
											/>
											<label class="form-check-label" for="avvioRiciclo">
												Avvio a riciclo (17)
											</label>
										</div>
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												id="autoRiciclo"
												checked={valoriCheck["18_AUTO_RICICLO"]}
												onChange={(e) => {
													aggiornaSpunte("18_AUTO_RICICLO", e.target.value);
												}}
											/>
											<label class="form-check-label" for="autoRiciclo">
												Auto riciclo (18)
											</label>
										</div>
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												id="ingressiExtraCorepla"
												checked={valoriCheck["15_INGRESSI_EXTRA_COREPLA"]}
												onChange={(e) => {
													aggiornaSpunte("15_INGRESSI_EXTRA_COREPLA", e.target.value);
												}}
											/>
											<label class="form-check-label" for="ingressiExtraCorepla">
												Ingressi extra-Corepla, soli CER di pertinenza Corepla (15)
											</label>
										</div>
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												id="usciteMetalliCorepla"
												checked={valoriCheck["12_USCITE_METALLI_COREPLA"]}
												onChange={(e) => {
													aggiornaSpunte("12_USCITE_METALLI_COREPLA", e.target.value);
												}}
											/>
											<label class="form-check-label" for="usciteMetalliCorepla">
												Uscite di metalli da lavorazioni flussi Corepla (12)
											</label>
										</div>
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												id="usciteAutoRiciclo"
												checked={valoriCheck["20_USCITE_AUTO_RICICLO"]}
												onChange={(e) => {
													aggiornaSpunte("20_USCITE_AUTO_RICICLO", e.target.value);
												}}
											/>
											<label class="form-check-label" for="usciteAutoRiciclo">
												Uscite auto-riciclo (20)
											</label>
										</div>
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												id="usciteAvvioRiciclo"
												checked={valoriCheck["19_USCITE_AVVIO_A_RICICLO"]}
												onChange={(e) => {
													aggiornaSpunte("19_USCITE_AVVIO_A_RICICLO", e.target.value);
												}}
											/>
											<label class="form-check-label" for="usciteAvvioRiciclo">
												Uscite avvio a riciclo (19)
											</label>
										</div>
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												id="usciteExtraCorepla"
												checked={valoriCheck["16_USCITE_EXTRA_COREPLA"]}
												onChange={(e) => {
													aggiornaSpunte("16_USCITE_EXTRA_COREPLA", e.target.value);
												}}
											/>
											<label class="form-check-label" for="usciteExtraCorepla">
												Uscite extra Corepla, solo CER di pertinenza Corepla (16)
											</label>
										</div>
									</div>
								</div>
							</>
						) : null}
						{props.tipoParam == PARAMETRI[2].valore ? (
							<>
								<div className="form-row">
									<div className="form-group col-md-6">
										<label>
											<strong>Data inizio</strong>
										</label>
										{renderDate("meseInizio", "annoInizio")}
									</div>
									<div className="form-group col-md-6">
										<label>
											<strong>Data fine</strong>
										</label>
										{renderDate("meseFine", "annoFine")}
									</div>
								</div>
								<div className="form-row">
									<div className="form-group col-md-5 col-md-offset-4">
										<label>Percentuale Max scarto di processo (es. 0.05)</label>
										<input
											type="number"
											className="form-control"
											id="inputPerditaDiProcesso"
											placeholder="percentuale"
											min={0}
											value={percentualePerditaProcesso}
											onChange={(e) => aggiornaPerditaProcesso(e.target.value)}
										/>
									</div>
								</div>
							</>
						) : null}
					</>
				</ModalBody>
				<ModalFooter>
					<button
						className="btn btn-primary"
						onClick={() => {
							inserisci();
						}}>
						Salva
					</button>
					<Button onClick={() => chiudiModale()}>Annulla</Button>
				</ModalFooter>
				<Toast
					isVisible={messageToast.isVisible}
					type={messageToast.type}
					text={messageToast.text}
					onClose={() => {
						setMessageToast({
							text: "",
							isVisible: false,
							type: "",
						});
					}}
				/>
			</Modal>
		</>
	);
};

export default ModaleModificaValoriCdr;
