export const checkInputNull = (value, defaultValue = "") => {
	if (value == null) {
		return defaultValue;
	}
	return value;
};

export const nomeCdrInHeader = (tmpDich, listaCdr) => {
	if (listaCdr.length > 0) {
		if (tmpDich.cdrNome == null) {
			let posizioneCdrNome = listaCdr.findIndex((e) => e.organizationId == tmpDich.cdrId);
			let cdr = listaCdr[posizioneCdrNome];
			tmpDich.cdrNome = cdr.nome;
		}
	}
};

export const nomeTipologiaNote = (tipoNota) => {
	let nomeTipoNota = "";

	if (tipoNota == 0) {
		nomeTipoNota = "Generica";
	}
	if (tipoNota == 1) {
		nomeTipoNota = "Forzatura Validità";
	}
	if (tipoNota == 2) {
		nomeTipoNota = "Approvazione";
	}
	if (tipoNota == 3) {
		nomeTipoNota = "Rifiuto Approvazione";
	}
	if (tipoNota == 4) {
		nomeTipoNota = "Reinvio a CDR";
	}
	if (tipoNota == 5) {
		nomeTipoNota = "Riapertura da REC";
	}
	return nomeTipoNota;
};

export const infoHeaderDichiarazionePrecedente = (cdrId, mese, anno, meseSelezionato) => {
	let meseHeader = null;
	let annoHeader = null;
	if (meseSelezionato == null) {
		meseHeader = mese - 1;
		annoHeader = anno;
	} else {
		meseHeader = meseSelezionato - 1;
		annoHeader = anno;
	}
	if (mese == 1 || meseSelezionato == 1) {
		meseHeader = 12;
		annoHeader = anno - 1;
	}
	let infoDichiarazioneHeader = {
		idCdr: cdrId,
		mese: meseHeader,
		anno: annoHeader,
	};
	return infoDichiarazioneHeader;
};

export const nomeTipologiaImpianto = (
	idImpiantoDest,
	listaImpianti,
	nomeTipologiaImpianto = null
) => {
	let i = listaImpianti.find((imp) => {
		if (imp.id === Number(idImpiantoDest)) {
			return imp;
		}
		return null;
	});
	if (i != null) {
		if (nomeTipologiaImpianto.hasOwnProperty(i.tipoImpianto)) {
			i.tipoImpianto = nomeTipologiaImpianto[i.tipoImpianto];
		}
		return i.tipoImpianto;
	}
	if (i == null || i == undefined) {
		return "N.A.";
	}
	return;
};

export function isSolaLettura(isRec, statoDichiarazione, statoNonConformita = null) {
	let stato = statoDichiarazione;
	let statoNc = statoNonConformita;
	// CDR
	if (isRec == false) {
		if (
			stato == "VERIFICATA" ||
			stato == "APPROVATA" ||
			stato == "DA_VERIFICARE" ||
			stato == "CONFERMATA"
		) {
			return true;
		}
		if (statoNc == "APPROVATA" || statoNc == "NONE") {
			return true;
		}
		return false;
	}

	// REC
	if (isRec == true) {
		if (
			stato == "NUOVA" ||
			stato == "REVISIONE_CDR" ||
			stato == "APPROVATA" ||
			stato == null ||
			statoNc == "APPROVATA" ||
			statoNc == "NON_APPROVATA"
		) {
			return true;
		}
		return false;
	}
}

export const listaParametriAbilitati = (dati, listaParametriDefault, listaTipologiaParametri) => {
	let risultato = {
		parametriAbilitati: null,
		percentuale: null,
	};
	dati.map((e) => {
		if (e.tipoParametro == listaTipologiaParametri[1].valore) {
			let arrayParametriAbilitati = JSON.parse(e.valore).valore;
			if (arrayParametriAbilitati.length > 0) {
				risultato.parametriAbilitati = isParametroAbilitato(
					arrayParametriAbilitati,
					listaParametriDefault
				);
			}
		}
		if (e.tipoParametro == listaTipologiaParametri[2].valore) {
			risultato.percentuale = JSON.parse(e.valore).valore;
		}
	});
	return risultato;
};

const isParametroAbilitato = (listaParametri, listaParametriDefault) => {
	let risultato = {};
	let parametriDefault = Object.keys(listaParametriDefault);
	for (let i = 0; i < parametriDefault.length; i++) {
		for (let j = 0; j < listaParametri.length; j++) {
			if (parametriDefault[i] == listaParametri[j]) {
				risultato[parametriDefault[i]] = true;
			}
			if (parametriDefault[i] != listaParametri[i] && risultato[parametriDefault[i]] != true) {
				risultato[parametriDefault[i]] = false;
			}
		}
	}
	return risultato;
};

export const controlloIndiciPresenza = (dichiarazione) => {
	if (
		dichiarazione.trasferimentiMateriaPrima.length > 0 &&
		dichiarazione.trasferimentiMateriaPrima.find((e) => e.idCdrDestinazione == null) != undefined
	) {
		return false;
	}
	if (
		dichiarazione.usciteConDestino.length > 0 &&
		dichiarazione.usciteConDestino.find((e) => e.idImpiantoDest == null) != undefined
	) {
		return false;
	}
	return true;
};

export const controlliFinali = (dichiarazione, coloreProcessi = null) => {
	let risultato = {
		verificato: true,
		descrizioneErrore: "",
	};

	if (dichiarazione.dataDichiarazione == null) {
		risultato.verificato = false;
		risultato.descrizioneErrore = "La data della dichiarazione è obbligatoria";
		return risultato;
	}

	if (
		dichiarazione.trasferimentiMateriaPrima.length > 0 &&
		dichiarazione.trasferimentiMateriaPrima.find((e) => e.idCdrDestinazione == null) != undefined
	) {
		risultato.verificato = false;
		risultato.descrizioneErrore = "Selezionare il Centro di Recupero per il trasferimento";
		return risultato;
	}
	if (
		dichiarazione.usciteConDestino.length > 0 &&
		dichiarazione.usciteConDestino.find((e) => e.idImpiantoDest == null) != undefined
	) {
		risultato.verificato = false;
		risultato.descrizioneErrore = "Selezionare l'impianto per il trasferimento";
		return risultato;
	}

	if (coloreProcessi != null) {
		if (coloreProcessi.__stato_validita != "VALIDO") {
			risultato.verificato = false;
			risultato.descrizioneErrore =
				"La dichiarazione risulta ancora non valida, ricontrolla i valori";
			return risultato;
		}
	}

	if (
		dichiarazione.allegati === undefined ||
		dichiarazione.allegati == null ||
		dichiarazione.allegati == 0
	) {
		risultato.verificato = false;
		risultato.descrizioneErrore = "La dichiarazione necessita di almeno un allegato";
		return risultato;
	}

	return risultato;
};

export const buildEmptyDichiarazione = (idCdr, cdrNome, mese, anno) => {
	return {
		id: null,
		cdrId: idCdr,
		cdrNome: cdrNome,
		mese: mese,
		anno: anno,
		stato: "NUOVA",
		statoValidita: "NONE",
		dataDichiarazione: null,
		hasRecout: null,
		nc: null,
		idNota: null,
		testoNota: "",
		testoNotaGiustificativa: "",
		allegati: [],
		statoControlli: null,
		trasferimentiMateriaPrima: [],
		ingressiMateriaCss: null,
		ingressiMateriaCdr: null,
		ingressiMateriaCssCdr: null,
		ingressiMateriaCssC: null,
		scartiProcessoNoLav: null,
		matNonConforme: null,
		prodottoCssCdr: null,
		prodottoCssC: null,
		metalliSeparati: null,
		scartiProcesso: null,
		perditeProcesso: null,
		usciteConDestino: [],
		usciteNoDestMetalli: null,
		usciteNoDestScarti: null,
		ingressiExtraCorepla: null,
		usciteExtraCorepla: null,
		avvioRiciclo: null,
		autoRiciclo: null,
		usciteAvvioRiciclo: null,
		usciteAutoRiciclo: null,
	};
};

export const buildDichiarazioneObject = (dichiarazione, dichData) => {
	const estraiUltimaNota = () => {
		let noteSalvate = [];
		if (dichData.note !== undefined || dichData.note !== null) {
			noteSalvate = dichData.note.filter((e) => e.tipologiaNota == 0);
			return noteSalvate[noteSalvate.length - 1];
		}
	};
	let notaEstratta = estraiUltimaNota();
	if (notaEstratta === undefined) {
		notaEstratta = {
			id: null,
			testo: "",
		};
	}
	const materialeNc = () => {
		let res = null;
		if (dichData.nc !== null) {
			if (dichData.nc.statoNc == "NON_APPROVATA") {
				res = 0;
				return res;
			}
			if (dichData.nc.statoNc == "APPROVATA") {
				res = dichData.nc.materialeNcApprovato;
				return res;
			}
		}
		res = dichData.matNonConforme;
		return res;
	};
	let matNonConforme = materialeNc();

	if (dichData.stato == "VERIFICATA") {
		dichiarazione.ingressiMateriaCss = dichData.ingressiMateriaCss;
		dichiarazione.ingressiMateriaCdr = dichData.ingressiMateriaCdr;
		dichiarazione.ingressiMateriaCssCdr = dichData.ingressiMateriaCssCdr;
		dichiarazione.ingressiMateriaCssC = dichData.ingressiMateriaCssC;
		dichiarazione.scartiProcessoNoLav = dichData.scartiProcessoNoLav;
	}

	dichiarazione.id = dichData.id;
	dichiarazione.mese = dichData.mese;
	dichiarazione.stato = dichData.stato;
	dichiarazione.statoValidita = dichData.statoValidita;
	dichiarazione.dataDichiarazione = dichData.dataDichiarazione;
	dichiarazione.hasRecout = dichData.hasRecout;
	dichiarazione.idNota = notaEstratta.id;
	dichiarazione.testoNota = notaEstratta.testo;
	dichiarazione.testoNotaGiustificativa = "";
	dichiarazione.allegati = dichData.allegati;
	dichiarazione.nc = dichData.nc;
	dichiarazione.statoControlli = dichData.statoControlli;
	dichiarazione.trasferimentiMateriaPrima = dichData.trasf;
	dichiarazione.matNonConforme = matNonConforme;
	dichiarazione.prodottoCssCdr = dichData.prodottoCssCdr;
	dichiarazione.prodottoCssC = dichData.prodottoCssC;
	dichiarazione.metalliSeparati = dichData.metalliSeparati;
	dichiarazione.scartiProcesso = dichData.scartiProcesso;
	dichiarazione.perditeProcesso = dichData.perditeProcesso;
	dichiarazione.usciteConDestino = dichData.usciteDest;
	dichiarazione.usciteNoDestMetalli = dichData.usciteNoDestMetalli;
	dichiarazione.usciteExtraCorepla = dichData.usciteExtraCorepla;
	dichiarazione.ingressiExtraCorepla = dichData.ingressiExtraCorepla;
	dichiarazione.avvioRiciclo = dichData.avvioRiciclo;
	dichiarazione.autoRiciclo = dichData.autoRiciclo;
	dichiarazione.usciteAvvioRiciclo = dichData.usciteAvvioRiciclo;
	dichiarazione.usciteAutoRiciclo = dichData.usciteAutoRiciclo;
};

export const buildDichiarazioneServer = (dichiarazione) => {
	let oggettoNota = [];
	if (dichiarazione.testoNota !== "") {
		oggettoNota.push({
			id: dichiarazione.idNota,
			testo: dichiarazione.testoNota,
			tipologiaNota: 0,
		});
	}
	return {
		id: dichiarazione.id,
		idCdr: dichiarazione.cdrId,
		mese: dichiarazione.mese,
		anno: dichiarazione.anno,
		stato: dichiarazione.stato,
		statoValidita: dichiarazione.statoValidita,
		note: oggettoNota,
		allegati: dichiarazione.allegati,
		nc: dichiarazione.nc,
		statoControlli: dichiarazione.statoControlli,
		ingressiMateriaCss: dichiarazione.ingressiMateriaCss,
		ingressiMateriaCdr: dichiarazione.ingressiMateriaCdr,
		ingressiMateriaCssCdr: dichiarazione.ingressiMateriaCssCdr,
		ingressiMateriaCssC: dichiarazione.ingressiMateriaCssC,
		scartiProcessoNoLav: dichiarazione.scartiProcessoNoLav,
		ingressiExtraCorepla: dichiarazione.ingressiExtraCorepla,
		matNonConforme: dichiarazione.matNonConforme,
		prodottoCssCdr: dichiarazione.prodottoCssCdr,
		prodottoCssC: dichiarazione.prodottoCssC,
		metalliSeparati: dichiarazione.metalliSeparati,
		scartiProcesso: dichiarazione.scartiProcesso,
		perditeProcesso: dichiarazione.perditeProcesso,
		avvioRiciclo: dichiarazione.avvioRiciclo,
		autoRiciclo: dichiarazione.autoRiciclo,
		usciteNoDestMetalli: dichiarazione.usciteNoDestMetalli,
		usciteNoDestScarti: null,
		usciteAutoRiciclo: dichiarazione.usciteAutoRiciclo,
		usciteExtraCorepla: dichiarazione.usciteExtraCorepla,
		usciteAvvioRiciclo: dichiarazione.usciteAvvioRiciclo,
		dataDichiarazione: dichiarazione.dataDichiarazione,
		trasf: dichiarazione.trasferimentiMateriaPrima,
		usciteDest: dichiarazione.usciteConDestino,
	};
};

export const buildCebisValues = (dichiarazione, cebisValues) => {
	if (dichiarazione.stato != "VERIFICATA") {
		dichiarazione.ingressiMateriaCss = cebisValues.ingressi_da_css;
		dichiarazione.ingressiMateriaCdr = cebisValues.ingressi_da_altri_cdr;
		dichiarazione.ingressiMateriaCssCdr = cebisValues.css_cdr_da_cdr;
		dichiarazione.ingressiMateriaCssC = cebisValues.css_c_da_cdr;
		dichiarazione.scartiProcessoNoLav = cebisValues.scarti_da_cdr;
	}
};
