import React, { useState, useEffect } from "react";
import { Modal, ModalFooter, ModalHeader, ModalBody, Button } from "reactstrap";
import moment from "moment";

const ModaleListaCdr = (props) => {
	const [listaCdr, setListaCdr] = useState(props.lista);

	useEffect(() => {
		setListaCdr(props.lista);
	}, [props.isOpen]);

	const renderListaCdr = () => {
		if (listaCdr != null && listaCdr.length > 0) {
			return listaCdr.map((cdr, _idx) => {
				return (
					<tr key={_idx}>
						<td>{cdr.nomeCdr}</td>
						<td>{moment(cdr.dataInizioValidita).format("DD-MM-YYYY")}</td>
						<td>{moment(cdr.dataFineValidita).format("DD-MM-YYYY")}</td>
						<td>
							{cdr.gestoreContratto != null && cdr.gestoreContratto == 1
								? "CDR"
								: cdr.gestoreContratto != null && cdr.gestoreContratto == 2
								? "Impianto"
								: "-"}
						</td>
					</tr>
				);
			});
		} else {
			return (
				<tr>
					<td colSpan={4}>Non sono presenti CDR per l'impianto selezionato</td>
				</tr>
			);
		}
	};

	return (
		<>
			<Modal
				isOpen={props.isOpen}
				size="lg"
				role="dialog"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				backdrop="static"
				keyboard={true}>
				<ModalHeader>
					<h4>Lista CDR per l'impianto: selezionato</h4>
				</ModalHeader>
				<ModalBody>
					<div className="tabella-impianti-wrapper">
						<table className="tabella-impianti" style={{ backgroundColor: "inherit" }}>
							<thead>
								<tr>
									<th>Nome CDR</th>
									<th>Data Inizio</th>
									<th>Data fine</th>
									<th>Gestore contratto</th>
								</tr>
							</thead>
							<tbody>{renderListaCdr()}</tbody>
						</table>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button onClick={props.onExit}>Chiudi</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default ModaleListaCdr;
