export const controlliAssociazioneCdr = (cdr) => {
	let risultato = {
		verificato: true,
		descrizioneErrore: "",
	};

	if (cdr.idCdr == "" || cdr.idCdr == null || cdr.nomeCdr == "" || cdr.nomeCdr == null) {
		risultato.verificato = false;
		risultato.descrizioneErrore = "Per favore compilare tutti i campi";
	}

	return risultato;
};

export const controlliModificaAssociazioneCdr = (cdr) => {
	let risultato = {
		verificato: true,
		descrizioneErrore: "",
	};

	if (
		cdr.idCdrDest == "" ||
		cdr.idCdrDest == null ||
		cdr.nomeCdrDest == "" ||
		cdr.nomeCdrDest == null
	) {
		risultato.verificato = false;
		risultato.descrizioneErrore = "Per favore compilare tutti i campi";
	}

	return risultato;
};
