import "./App.css";
import { useEffect, useState } from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import { PORTAL_LOGIN_URL, DEV_MODE } from "./common/env";
import { getAccessTokenCookie, setAccessTokenCookie } from "./common/cookie";
import { useCreateGlobalStateContext, setGlobalState } from "./context/GlobalContext";
import Dichiarazione from "./pages/Dichiarazione";
import GestioneImpiantiPerCdr from "./pages/GestioneImpiantiPerCdr";
import GestioneImpianti from "./pages/GestioneImpianti";
import GestioneParametri from "./pages/GestioneParametri";
import GestioneCentroDiRecupero from "./pages/GestioneCentroDiRecupero";
import Header from "./components/comuni/Header";
import { userCurrent, getMenu } from "./actions/portalApi";

function App() {
	const [isReady, setIsReady] = useState(false);
	const GlobalStateContext = useCreateGlobalStateContext({
		user: null,
		menuLaterale: {
			lista: null,
			isAperto: false,
		},
	});

	useEffect(() => {
		let urlSearch = window.location.hash;
		if (urlSearch !== "") {
			urlSearch = urlSearch.substring(urlSearch.indexOf("?"));
		} else {
			urlSearch = window.location.search;
		}
		const accessToken = new URLSearchParams(urlSearch).get("_a");

		if (accessToken !== null) {
			setAccessTokenCookie(accessToken);
		}

		if (getAccessTokenCookie() != null) {
			userCurrent()
				.then((dati) => {
					setGlobalState({ user: dati.data });
					getMenu().then((m) => {
						if (m != null) {
							setGlobalState({ menuLaterale: { lista: m.data, isAperto: false } });
						}
					});
					setIsReady(true);
				})
				.catch((error) => {
					if (error.response.status === 401) {
						window.location.href = PORTAL_LOGIN_URL;
					}
				});
		} else {
			if (DEV_MODE === false) {
				window.location.href = PORTAL_LOGIN_URL;
			}
		}
	}, []);

	const Home = () => {
		return "I AM COMING HOME!";
	};

	return isReady ? (
		<GlobalStateContext>
			<Header />
			<div className="main">
				<HashRouter>
					<Switch>
						<Route exact path="/" component={Home} />
						<Route exact path="/dichiarazione" component={Dichiarazione} />
						<Route exact path="/gestione-impianti" component={GestioneImpianti} />
						<Route exact path="/gestione-impianti-cdr" component={GestioneImpiantiPerCdr} />
						<Route exact path="/gestione-parametri" component={GestioneParametri} />
						<Route exact path="/gestione-cdr" component={GestioneCentroDiRecupero} />
					</Switch>
				</HashRouter>
			</div>
		</GlobalStateContext>
	) : (
		""
	);
}

export default App;
