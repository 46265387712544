import Cookies from "universal-cookie";
import { COOKIE_ACCESS } from "./env";

var cookie = new Cookies();

export const getAccessTokenCookie = () => {
	return cookie.get(COOKIE_ACCESS);
};

export const setAccessTokenCookie = (token) => {
	cookie.set(COOKIE_ACCESS, token);
};

export const removeAccessTokenCookie = () => {
	cookie.remove(COOKIE_ACCESS);
};
