import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import moment from "moment";
import { controlliAssociazioneCdr } from "../../functions/gestioneCentroDiRecuperoFunctions";
import { verificaMeseAnno } from "../../functions/gestioneParametriFunctions";
import { MESI } from "../../common/constants";
import Toast, { TOAST_DANGER, TOAST_SUCCESS } from "../comuni/Toast";

export default function ModaleModificaAssociaCdr(props) {
	const [tmpCdr, setTmpCdr] = useState(props.oggettoCdr);
	const [messageToast, setMessageToast] = useState({
		text: "",
		isVisible: false,
		type: "",
	});

	useEffect(() => {
		if (props.oggettoCdr != null) {
			props.oggettoCdr.meseInizio = moment(props.oggettoCdr.dataInizioValidita).format("M");
			props.oggettoCdr.meseFine = moment(props.oggettoCdr.dataFineValidita).format("M");
			props.oggettoCdr.annoInizio = moment(props.oggettoCdr.dataInizioValidita).format("YYYY");
			props.oggettoCdr.annoFine = moment(props.oggettoCdr.dataFineValidita).format("YYYY");
		}
		setTmpCdr(props.oggettoCdr);
	}, [props.isOpen]);

	const inserisci = () => {
		let data = { ...tmpCdr };
		let controllo = controlliAssociazioneCdr(data);
		if (!controllo.verificato) {
			setMessageToast({
				text: controllo.descrizioneErrore,
				isVisible: true,
				type: TOAST_DANGER,
			});
			return;
		}
		let secondoControllo = verificaMeseAnno(data);
		if (!secondoControllo.verificato) {
			setMessageToast({
				text: secondoControllo.descrizioneErrore,
				isVisible: true,
				type: TOAST_DANGER,
			});
			return;
		}
		data.dataInizioValidita = moment()
			.year(data.annoInizio)
			.month(data.meseInizio - 1)
			.format("YYYY-MM-01");
		data.dataFineValidita =
			moment()
				.year(data.annoFine)
				.month(data.meseFine - 1)
				.format("YYYY-MM-") +
			moment()
				.month(data.meseFine - 1)
				.daysInMonth();
		let { meseInizio, meseFine, annoInizio, annoFine, ...dto } = data;
		props.conferma(dto);
	};

	const aggiorna = (chiave, valore) => {
		let cdr = { ...tmpCdr };
		cdr[chiave] = valore;
		if (chiave == "idCdrDest") {
			let centroRecupero = props.listaTotaleCdr.find((x) => x.organizationId == valore);
			cdr.nomeCdr = centroRecupero.nome;
		}
		setTmpCdr(cdr);
	};

	const aggiornaDate = (chiave, valore) => {
		let cdr = { ...tmpCdr };
		if (chiave == "meseInizio") {
			cdr[chiave] = valore;
		}
		if (chiave == "meseFine") {
			cdr[chiave] = valore;
		}
		if (chiave == "annoInizio") {
			cdr[chiave] = valore;
		}
		if (chiave == "annoFine") {
			cdr[chiave] = valore;
		}
		setTmpCdr(cdr);
	};

	const listaMesi = (chiave) => {
		return (
			<select
				className="form-control"
				defaultValue={tmpCdr[chiave]}
				onChange={(e) => {
					aggiornaDate(chiave, e.target.value);
				}}
				required>
				<option key={"mesi-empty"} value={""}>
					-- Seleziona mese --
				</option>
				{MESI.map((m) => {
					return (
						<option key={m.valore} value={m.valore}>
							{m.testo}
						</option>
					);
				})}
			</select>
		);
	};

	const listaAnni = (chiave) => {
		let annoCorrente = new Date().getFullYear();
		let anni = [];
		for (let k = -3; k < 2; k++) {
			anni.push(annoCorrente + k);
			anni.sort((a, b) => b - a);
		}
		return (
			<select
				className="form-control"
				defaultValue={tmpCdr[chiave]}
				onChange={(e) => {
					aggiornaDate(chiave, e.target.value);
				}}
				required>
				<option key={"anni-empty"} value={""}>
					-- Seleziona anno --
				</option>
				{anni.map((a) => {
					return (
						<option key={a} value={a}>
							{a}
						</option>
					);
				})}
			</select>
		);
	};

	const renderDate = (mese, anno) => {
		return (
			<>
				<div className="form-row">
					<div className="col-md-6">
						<div className="selection-panel" style={{ display: "block" }}>
							Mese:<div>{listaMesi(mese)}</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="selection-panel" style={{ display: "block" }}>
							Anno:<div>{listaAnni(anno)}</div>
						</div>
					</div>
				</div>
				<br />
			</>
		);
	};

	const renderListaCdr = () => {
		if (props.listaTotaleCdr != null && props.listaTotaleCdr.length > 0) {
			{
				return (
					<select
						className="form-control"
						value={tmpCdr.idCdrDest}
						onChange={(e) => {
							aggiorna("idCdrDest", e.target.value);
						}}
						required>
						{props.listaTotaleCdr.map((cdr, _idx) => {
							return (
								<option key={_idx} value={cdr.organizationId}>
									{cdr.nome}
								</option>
							);
						})}
					</select>
				);
			}
		}
	};

	return (
		<>
			{tmpCdr != null ? (
				<>
					<Modal
						isOpen={props.isOpen}
						size="lg"
						role="dialog"
						aria-labelledby="contained-modal-title-vcenter"
						centered
						backdrop="static"
						keyboard={true}>
						<ModalHeader>
							<h4>Modifica Associazione Cdr</h4>
						</ModalHeader>
						<ModalBody>
							<>
								<div className="cor-card">
									<div className="cor-card-header" style={{ backgroundColor: "#d5d5d5a2" }}>
										<h4>Modifica</h4>
									</div>
									<div style={{ padding: "15px" }}>
										<form>
											<div className="form-row">
												<div className="col-md-6">
													<label for="inputNome">CDR di destinazione</label>
													{renderListaCdr()}
												</div>
											</div>
											<div className="form-row">
												<div className="col-md-12">
													<div style={{ padding: "10px 0px" }}>
														<div className="form-row">
															<div className="col-md-6">
																<label>
																	<strong>Data inizio</strong>
																</label>
																{renderDate("meseInizio", "annoInizio")}
															</div>
															<div className="col-md-6">
																<label>
																	<strong>Data fine</strong>
																</label>
																{renderDate("meseFine", "annoFine")}
															</div>
														</div>
													</div>
												</div>
											</div>
										</form>
										<div className="cor-card-footer">
											<div className="btn-panel">
												<button
													className="btn btn-primary"
													onClick={() => {
														inserisci();
													}}>
													Modifica
												</button>
												<button
													className="btn btn-secondary"
													onClick={() => {
														props.onExit();
													}}>
													Annulla
												</button>
											</div>
										</div>
									</div>
									<div className="riga-interruzione" />
								</div>
							</>
						</ModalBody>
						<Toast
							isVisible={messageToast.isVisible}
							type={messageToast.type}
							text={messageToast.text}
							onClose={() => {
								setMessageToast({
									text: "",
									isVisible: false,
									type: "",
								});
							}}
						/>
					</Modal>
				</>
			) : null}
		</>
	);
}
