import { CHECK_NONE, CHECK_NOK, CHECK_OK } from "../common/constants";
import { CircleFill, Check } from "react-bootstrap-icons";

export const resetControlli = () => {
	return {
		colore_PerditaDiProcesso: CHECK_NONE,
		colore_ScartiDiProcesso: CHECK_NONE,
		colore_GiacenzaRicezione: CHECK_NONE,
		colore_GiacenzaMatNonConforme: CHECK_NONE,
		colore_GiacenzaCssCdr: CHECK_NONE,
		colore_GiacenzaCssC: CHECK_NONE,
		colore_GiacenzaMetalliSeparati: CHECK_NONE,
		colore_GiacenzaScartiDiProcesso: CHECK_NONE,
		colore_GiacenzaAvvioRiciclo: CHECK_NONE,
		colore_GiacenzaAutoRiciclo: CHECK_NONE,
		__stato_validita: "NON_APPLICABILE",
	};
};

export const resetParametri = () => {
	return {
		"04_MATERIALE_NON_CONFORME": true,
		"05_CSS_CDR_PRODOTTO": true,
		"06_CSS_C_PRODOTTO": true,
		"07_METALLI_SEPARATI": true,
		"08_SCARTI_DI_PROCESSO": true,
		"09_PERDITE_DI_PROCESSO": true,
		"12_USCITE_METALLI_COREPLA": true,
		"15_INGRESSI_EXTRA_COREPLA": true,
		"16_USCITE_EXTRA_COREPLA": true,
		"17_AVVIO_A_RICICLO": true,
		"18_AUTO_RICICLO": true,
		"19_USCITE_AVVIO_A_RICICLO": true,
		"20_USCITE_AUTO_RICICLO": true,
	};
};

export function nullToZero(dichiarazione) {
	if (dichiarazione.perditeProcesso == null) dichiarazione.perditeProcesso = 0;
	if (dichiarazione.prodottoCssCdr == null) dichiarazione.prodottoCssCdr = 0;
	if (dichiarazione.prodottoCssC == null) dichiarazione.prodottoCssC = 0;
	if (dichiarazione.metalliSeparati == null) dichiarazione.metalliSeparati = 0;
	if (dichiarazione.scartiProcesso == null) dichiarazione.scartiProcesso = 0;
	if (dichiarazione.matNonConforme == null) dichiarazione.matNonConforme = 0;
	if (dichiarazione.avvioRiciclo == null) dichiarazione.avvioRiciclo = 0;
	if (dichiarazione.autoRiciclo == null) dichiarazione.autoRiciclo = 0;
	if (dichiarazione.usciteAutoRiciclo == null) dichiarazione.usciteAutoRiciclo = 0;
	if (dichiarazione.usciteAvvioRiciclo == null) dichiarazione.usciteAvvioRiciclo = 0;
	if (dichiarazione.usciteNoDestMetalli == null) dichiarazione.usciteNoDestMetalli = 0;
	if (dichiarazione.usciteExtraCorepla == null) dichiarazione.usciteExtraCorepla = 0;
	if (dichiarazione.ingressiExtraCorepla == null) dichiarazione.ingressiExtraCorepla = 0;
}

function isNull(parametro) {
	if (parametro == null) {
		return true;
	}
	return false;
}

export function valuesToNumber(dichiarazione) {
	if (!isNull(dichiarazione.perditeProcesso))
		dichiarazione.perditeProcesso = Number(dichiarazione.perditeProcesso);
	if (!isNull(dichiarazione.prodottoCssCdr))
		dichiarazione.prodottoCssCdr = Number(dichiarazione.prodottoCssCdr);
	if (!isNull(dichiarazione.prodottoCssC))
		dichiarazione.prodottoCssC = Number(dichiarazione.prodottoCssC);
	if (!isNull(dichiarazione.metalliSeparati))
		dichiarazione.metalliSeparati = Number(dichiarazione.metalliSeparati);
	if (!isNull(dichiarazione.scartiProcesso))
		dichiarazione.scartiProcesso = Number(dichiarazione.scartiProcesso);
	if (!isNull(dichiarazione.matNonConforme))
		dichiarazione.matNonConforme = Number(dichiarazione.matNonConforme);
	if (!isNull(dichiarazione.avvioRiciclo))
		dichiarazione.avvioRiciclo = Number(dichiarazione.avvioRiciclo);
	if (!isNull(dichiarazione.autoRiciclo))
		dichiarazione.autoRiciclo = Number(dichiarazione.autoRiciclo);
}

export function calcolaStatoValidita(colore) {
	let ret = "";
	let arr = Object.entries(colore);
	let almenoUnVerde = arr.some((a) => a[1] == CHECK_OK);
	for (var i = 0; i < arr.length; i++) {
		if (!arr[i][0].startsWith("__")) {
			if (arr[i][1] === CHECK_NOK) {
				ret = "NON_VALIDO";
				colore["__stato_validita"] = ret;
				return;
			}
			if ((ret === "" || ret === "NON_APPLICABILE") && arr[i][1] === CHECK_NONE) {
				ret = "NON_APPLICABILE";
			}
			if (almenoUnVerde) {
				ret = "VALIDO";
			}
		}
	}
	colore["__stato_validita"] = ret;
	return;
}

export function convertiColoreInCodice(colore) {
	if (colore == CHECK_NONE) {
		return "NONE";
	}
	if (colore == CHECK_NOK) {
		return "NOK";
	}
	if (colore == CHECK_OK) {
		return "OK";
	}
}

export function convertiCodiceInColore(codice) {
	if (codice == "NONE") {
		return CHECK_NONE;
	}
	if (codice == "NOK") {
		return CHECK_NOK;
	}
	if (codice == "OK") {
		return CHECK_OK;
	}
}

export function renderColorePallino(
	statoDichiarazione,
	statoValidita,
	codiceColoreProcesso,
	coloreProcesso,
	indice
) {
	let arrayColoriProcessi = [];
	if (codiceColoreProcesso != null) {
		let stringaCodiceColoreProcesso = JSON.parse(codiceColoreProcesso);
		arrayColoriProcessi = Object.values(stringaCodiceColoreProcesso);
	}

	if (statoDichiarazione !== "VERIFICATA" && statoDichiarazione !== "APPROVATA") {
		return (
			<>
				<td>
					<CircleFill style={{ "color": coloreProcesso }} />
				</td>
				{restituisciSpuntaVerde(statoValidita, coloreProcesso)}
			</>
		);
	} else {
		return (
			<>
				<td>
					<CircleFill
						style={{
							"color": convertiCodiceInColore(arrayColoriProcessi[indice]),
						}}
					/>
				</td>
				{restituisciSpuntaVerde(statoValidita, convertiCodiceInColore(arrayColoriProcessi[indice]))}
			</>
		);
	}
}

export function restituisciSpuntaVerde(statoValidita, colore) {
	if (statoValidita == "VALIDO_FORZATAMENTE" && colore == CHECK_NOK) {
		return (
			<td>
				<Check style={{ color: "green", margin: "2px 0 0 2px" }} />
			</td>
		);
	} else {
		return (
			<td>
				<Check style={{ color: "transparent", margin: "2px 0 0 2px" }} />
			</td>
		);
	}
}

export function perditaDiProcesso(
	perditeProcesso,
	prodottoCssCdr,
	prodottoCssC,
	metalliSeparati,
	scartiProcesso
) {
	if (
		perditeProcesso !== null ||
		prodottoCssCdr !== null ||
		prodottoCssC !== null ||
		metalliSeparati !== null ||
		scartiProcesso !== null
	) {
		if (isNull(perditeProcesso)) perditeProcesso = 0;
		if (isNull(prodottoCssCdr)) prodottoCssCdr = 0;
		if (isNull(prodottoCssC)) prodottoCssC = 0;
		if (isNull(metalliSeparati)) metalliSeparati = 0;
		if (isNull(scartiProcesso)) scartiProcesso = 0;
		if (
			perditeProcesso <=
			0.05 * (prodottoCssCdr + prodottoCssC + metalliSeparati + scartiProcesso)
		) {
			return CHECK_OK;
		} else {
			return CHECK_NOK;
		}
	}
	return CHECK_NONE;
}

export function scartiDiProcesso(
	percentuale,
	scartiProcesso,
	prodottoCssCdr,
	prodottoCssC,
	metalliSeparati
) {
	if (
		scartiProcesso !== null ||
		prodottoCssCdr !== null ||
		prodottoCssC !== null ||
		metalliSeparati !== null
	) {
		if (isNull(scartiProcesso)) scartiProcesso = 0;
		if (isNull(prodottoCssCdr)) prodottoCssCdr = 0;
		if (isNull(prodottoCssC)) prodottoCssC = 0;
		if (isNull(metalliSeparati)) metalliSeparati = 0;
		if (
			scartiProcesso <=
			percentuale * (prodottoCssCdr + prodottoCssC + metalliSeparati + scartiProcesso)
		) {
			return CHECK_OK;
		} else {
			return CHECK_NOK;
		}
	}
	return CHECK_NONE;
}

export function giacenzaRicezione(
	giacenzaRicezioneA,
	materialeNonConforme,
	prodottoCssCdr,
	prodottoCssC,
	metalliSeparati,
	scartiDiProcesso,
	perditeProcesso,
	avvioRiciclo,
	autoRiciclo,
	trasferimentoAdAltroCdr
) {
	if (
		materialeNonConforme !== null ||
		prodottoCssCdr !== null ||
		prodottoCssC !== null ||
		metalliSeparati !== null ||
		scartiDiProcesso !== null ||
		perditeProcesso !== null ||
		avvioRiciclo !== null ||
		autoRiciclo !== null ||
		trasferimentoAdAltroCdr.length > 0
	) {
		if (isNull(materialeNonConforme)) materialeNonConforme = 0;
		if (isNull(prodottoCssCdr)) prodottoCssCdr = 0;
		if (isNull(prodottoCssC)) prodottoCssC = 0;
		if (isNull(metalliSeparati)) metalliSeparati = 0;
		if (isNull(scartiDiProcesso)) scartiDiProcesso = 0;
		if (isNull(perditeProcesso)) perditeProcesso = 0;
		if (isNull(avvioRiciclo)) avvioRiciclo = 0;
		if (isNull(autoRiciclo)) autoRiciclo = 0;

		let matAvviatoCdr = null;
		if (trasferimentoAdAltroCdr.length > 0) {
			trasferimentoAdAltroCdr.forEach((item, i) => {
				if (item.matAvviatoCdr != null) {
					matAvviatoCdr += Number(item.matAvviatoCdr);
				}
			});
		}

		if (
			matAvviatoCdr != null ||
			materialeNonConforme > 0 ||
			prodottoCssCdr > 0 ||
			prodottoCssC > 0 ||
			metalliSeparati > 0 ||
			scartiDiProcesso > 0 ||
			perditeProcesso > 0 ||
			avvioRiciclo > 0 ||
			autoRiciclo > 0
		) {
			if (
				matAvviatoCdr +
					materialeNonConforme +
					prodottoCssCdr +
					prodottoCssC +
					metalliSeparati +
					scartiDiProcesso +
					perditeProcesso +
					avvioRiciclo +
					autoRiciclo <=
				giacenzaRicezioneA
			) {
				return CHECK_OK;
			} else {
				return CHECK_NOK;
			}
		}
	}
	return CHECK_NONE;
}

export function giacenzaMatNonConforme(
	giacenzaRicezioneOutput,
	materialeNonConforme,
	usciteConDestinazione,
	trasferimentoAdAltroCdr
) {
	if (
		materialeNonConforme !== null ||
		usciteConDestinazione.length > 0 ||
		trasferimentoAdAltroCdr.length > 0
	) {
		if (isNull(materialeNonConforme)) materialeNonConforme = 0;
		let totaleKgUsciteAltriCdr = null;
		if (trasferimentoAdAltroCdr.length > 0) {
			trasferimentoAdAltroCdr.forEach((item, i) => {
				if (item.usciteNc != null) {
					totaleKgUsciteAltriCdr += Number(item.usciteNc);
				}
			});
		}

		let totaleKgUsciteConDestinazione = null;
		if (usciteConDestinazione.length > 0) {
			usciteConDestinazione.forEach((item, i) => {
				if (item.usciteNc != null) {
					totaleKgUsciteConDestinazione += Number(item.usciteNc);
				}
			});
		}
		let totaleKg = null;
		if (
			(totaleKgUsciteAltriCdr != null && totaleKgUsciteConDestinazione != null) ||
			(totaleKgUsciteAltriCdr != null && totaleKgUsciteConDestinazione == null) ||
			(totaleKgUsciteAltriCdr == null && totaleKgUsciteConDestinazione != null)
		) {
			totaleKg = totaleKgUsciteAltriCdr + totaleKgUsciteConDestinazione;
		}
		if (totaleKg != null || materialeNonConforme > 0) {
			if (totaleKg <= giacenzaRicezioneOutput + materialeNonConforme) {
				return CHECK_OK;
			} else {
				return CHECK_NOK;
			}
		}
	}
	return CHECK_NONE;
}

export function giacenzaCssCdr(
	giacenzaMagazzinoCssCdr,
	prodottoCssCdr,
	usciteConDestinazione,
	trasferimentoAdAltroCdr
) {
	if (
		prodottoCssCdr !== null ||
		usciteConDestinazione.length > 0 ||
		trasferimentoAdAltroCdr.length > 0
	) {
		if (isNull(prodottoCssCdr)) prodottoCssCdr = 0;
		let totaleKgUsciteAltriCdr = null;
		if (trasferimentoAdAltroCdr.length > 0) {
			trasferimentoAdAltroCdr.forEach((item, i) => {
				if (item.usciteCssCdr != null) {
					totaleKgUsciteAltriCdr += Number(item.usciteCssCdr);
				}
			});
		}

		let totaleKgUsciteConDestinazione = null;
		if (usciteConDestinazione.length > 0) {
			usciteConDestinazione.forEach((item, i) => {
				if (item.usciteCssCdr != null) {
					totaleKgUsciteConDestinazione += Number(item.usciteCssCdr);
				}
			});
		}
		let totaleKg = null;
		if (
			(totaleKgUsciteAltriCdr != null && totaleKgUsciteConDestinazione != null) ||
			(totaleKgUsciteAltriCdr != null && totaleKgUsciteConDestinazione == null) ||
			(totaleKgUsciteAltriCdr == null && totaleKgUsciteConDestinazione != null)
		) {
			totaleKg = totaleKgUsciteAltriCdr + totaleKgUsciteConDestinazione;
		}
		if (totaleKg != null || prodottoCssCdr > 0) {
			if (totaleKg <= giacenzaMagazzinoCssCdr + prodottoCssCdr) {
				return CHECK_OK;
			} else {
				return CHECK_NOK;
			}
		}
	}
	return CHECK_NONE;
}

export function giacenzaCssC(
	giacenzaMagazzinoCssC,
	prodottoCssC,
	usciteConDestinazione,
	trasferimentoAdAltroCdr
) {
	if (
		prodottoCssC !== null ||
		usciteConDestinazione.length > 0 ||
		trasferimentoAdAltroCdr.length > 0
	) {
		if (isNull(prodottoCssC)) prodottoCssC = 0;
		let totaleKgUsciteAltriCdr = null;
		if (trasferimentoAdAltroCdr.length > 0) {
			trasferimentoAdAltroCdr.forEach((item, i) => {
				if (item.usciteCssC != null) {
					totaleKgUsciteAltriCdr += Number(item.usciteCssC);
				}
			});
		}

		let totaleKgUsciteConDestinazione = null;
		if (usciteConDestinazione.length > 0) {
			usciteConDestinazione.forEach((item, i) => {
				if (item.usciteCssC != null) {
					totaleKgUsciteConDestinazione += Number(item.usciteCssC);
				}
			});
		}
		let totaleKg = null;
		if (
			(totaleKgUsciteAltriCdr != null && totaleKgUsciteConDestinazione != null) ||
			(totaleKgUsciteAltriCdr != null && totaleKgUsciteConDestinazione == null) ||
			(totaleKgUsciteAltriCdr == null && totaleKgUsciteConDestinazione != null)
		) {
			totaleKg = totaleKgUsciteAltriCdr + totaleKgUsciteConDestinazione;
		}
		if (totaleKg != null || prodottoCssC > 0) {
			if (totaleKg <= giacenzaMagazzinoCssC + prodottoCssC) {
				return CHECK_OK;
			} else {
				return CHECK_NOK;
			}
		}
	}
	return CHECK_NONE;
}

export function giacenzaMetalliSeparati(
	giacenzaMagazzinoMetalliSeparati,
	metalliSeparati,
	usciteNoDestMetalli
) {
	if (metalliSeparati !== null || usciteNoDestMetalli !== null) {
		if (isNull(metalliSeparati)) metalliSeparati = 0;
		if (isNull(usciteNoDestMetalli)) usciteNoDestMetalli = 0;
		if (usciteNoDestMetalli <= giacenzaMagazzinoMetalliSeparati + metalliSeparati) {
			return CHECK_OK;
		} else {
			return CHECK_NOK;
		}
	}
	return CHECK_NONE;
}

export function giacenzaScartiDiProcesso(
	giacenzaMagazzinoScartiDiProcesso,
	scartiProcesso,
	usciteConDestinazione,
	trasferimentoAdAltroCdr
) {
	if (
		scartiProcesso !== null ||
		usciteConDestinazione.length > 0 ||
		trasferimentoAdAltroCdr.length > 0
	) {
		if (isNull(scartiProcesso)) scartiProcesso = 0;
		let totaleKgUsciteAltriCdr = null;
		if (trasferimentoAdAltroCdr != null && trasferimentoAdAltroCdr.length > 0) {
			trasferimentoAdAltroCdr.forEach((item, i) => {
				if (item.usciteScarti != null) {
					totaleKgUsciteAltriCdr += Number(item.usciteScarti);
				}
			});
		}

		let totaleKgUsciteConDestinazione = null;
		if (usciteConDestinazione != null && usciteConDestinazione.length > 0) {
			usciteConDestinazione.forEach((item, i) => {
				if (item.usciteScarti != null) {
					totaleKgUsciteConDestinazione += Number(item.usciteScarti);
				}
			});
		}
		let totaleKg = null;
		if (
			(totaleKgUsciteAltriCdr != null && totaleKgUsciteConDestinazione != null) ||
			(totaleKgUsciteAltriCdr != null && totaleKgUsciteConDestinazione == null) ||
			(totaleKgUsciteAltriCdr == null && totaleKgUsciteConDestinazione != null)
		) {
			totaleKg = totaleKgUsciteAltriCdr + totaleKgUsciteConDestinazione;
		}
		if (totaleKg != null || scartiProcesso > 0) {
			if (totaleKg <= giacenzaMagazzinoScartiDiProcesso + scartiProcesso) {
				return CHECK_OK;
			} else {
				return CHECK_NOK;
			}
		}
	}
	return CHECK_NONE;
}

export function giacenzaAvvioRiciclo(
	giacenzaMagazzinoAvvioRiciclo,
	avvioRiciclo,
	usciteAvvioRiciclo
) {
	if (avvioRiciclo !== null || usciteAvvioRiciclo !== null) {
		if (isNull(avvioRiciclo)) avvioRiciclo = 0;
		if (isNull(usciteAvvioRiciclo)) usciteAvvioRiciclo = 0;
		if (usciteAvvioRiciclo <= giacenzaMagazzinoAvvioRiciclo + avvioRiciclo) {
			return CHECK_OK;
		} else {
			return CHECK_NOK;
		}
	}
	return CHECK_NONE;
}

export function giacenzaAutoRiciclo(giacenzaMagazzinoAutoRiciclo, autoRiciclo, usciteAutoRiciclo) {
	if (autoRiciclo !== null || usciteAutoRiciclo !== null) {
		if (isNull(autoRiciclo)) autoRiciclo = 0;
		if (isNull(usciteAutoRiciclo)) usciteAutoRiciclo = 0;
		if (usciteAutoRiciclo <= giacenzaMagazzinoAutoRiciclo + autoRiciclo) {
			return CHECK_OK;
		} else {
			return CHECK_NOK;
		}
	}
	return CHECK_NONE;
}
